import React, {useCallback, useState} from "react";
import {Page, Layout} from '@shopify/polaris';
import {useClient} from "../api/useClient";
import { PricingList } from "../components/PricingList";

export const AdminBillingComponent = React.memo(() => {
    const [modalActive, setModalActive] = useState<boolean>(false);
    const closeModal = useCallback(()=>{
        setModalActive(false);
    }, []);
    const client = useClient();
    const handleSubmit = useCallback((pricing) => {
        console.log('Save pricing', pricing);
        client.mutateAdminUpdatePricing({
            input: pricing
        }).then((result) => {
            console.log('Pricing updated', result);
            closeModal();
        }).catch((err) => {
            console.error(err);
            closeModal();
        });
    }, []);

    return (
        <Page title={"Pricing rates"} primaryAction={{content: "Add", url: '/billing/pricing/add'}}>            
            <Layout>
                <Layout.Section>
                    <PricingList/>
                </Layout.Section>
            </Layout>                              
        </Page>
    );
});
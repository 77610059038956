import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AuthStateInterface} from "./types";
import {useHistory} from "react-router";

export const AdminAuthContext = createContext<AuthStateInterface>(undefined as any);

const LOCAL_STORAGE_TOKEN_KEY = 'shopapp.admintoken';
const LOCAL_STORAGE_EXPIRE_AT = 'shopapp.expire_at';

export const AdminAuthLoader = React.memo<PropsWithChildren<{ }>>(({children})=>{
    const [token, setToken] = useState<string | null>(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY));
    const [expireAt, setExpireAt] = useState<number | null>(parseInt(localStorage.getItem(LOCAL_STORAGE_EXPIRE_AT) || '') || null);
    const history = useHistory<{from?: Location}>();

    const auth = useCallback((token: string, expireAt: number, redirect?: boolean) => {
        setToken(token);
        setExpireAt(expireAt);
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        localStorage.setItem(LOCAL_STORAGE_EXPIRE_AT, String(expireAt));
        if (redirect) {
            const state = history.location.state;
            console.log('AdminAuth: Navigate after auth', state?.from || { pathname: '/dashboard' });
            history.replace(state?.from || { pathname: '/dashboard' });
        }
    }, [token, location]);

    const unAuth = useCallback(() => {
        setToken(null);
        setExpireAt(null);
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_EXPIRE_AT);
        history.push({ pathname: '/login' });
    }, [location]);

    const context = useMemo<AuthStateInterface>(() => {
        let isAuthed: boolean = false;
        if (typeof token === "string" && !!token.trim()) {
            if (expireAt && expireAt > Date.now()) {
                isAuthed = true;
            } else {
                console.log('Token has been expired', expireAt, Date.now());
            }
        } else {
            console.log('Token empty');
        }
        return {
            isAuthed,
            expireAt: expireAt || undefined,
            token: token || undefined,
            auth,
            unAuth
        }
    }, [token, auth, unAuth, expireAt]);

    return (<AdminAuthContext.Provider value={context} children={children} />);
});

export function useAdminAuthContext(): AuthStateInterface {
    return useContext(AdminAuthContext);
}
import { Card, Layout, Page, TextField } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useHistory } from "react-router";
import { useClient } from "../../api/useClient";
import { PartnerEditForm } from "../../components/partners/PartnerEditForm";

type PartnerForm = {
    title: string,
    slug: string,
    payoutPercent: number,
    email: string,
    notes: string,
};

export const AdminPartnerNew = React.memo(() => {
    const client = useClient();
    const history = useHistory();
    const [formState, setFormState] = useState<PartnerForm>({
        title: '', slug: '', payoutPercent: 0.0, email: '', notes: ''
    })

    const onAdd = useCallback(
        () => {
            console.log('Add', formState);
            client.mutateAdminUpdatePartner({ input: formState })
                .then((result) => {
                    console.log('Partner saved', result);
                    history.push({ pathname: `/partner/${result.adminUpdatePartner.id}/edit` });
                })
                .catch(console.warn);            
            // client.refetchPartners();
        },
        [formState],
    );



    return (
        <Page title={'New partner'} breadcrumbs={[{ content: "Partners", url: "/partners" }]}>
            <Layout>
                <Layout.Section>
                    <Card primaryFooterAction={{
                        content: 'Add',
                        onAction: onAdd
                    }}>
                        <Card.Section>
                            <PartnerEditForm initValue={formState} onChange={setFormState} />
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    )
})
import { Avatar, Badge, Caption, Card, Checkbox, Heading, Pagination, ResourceItem, ResourceList, Select, Stack, TextField, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useLocation, useRouteMatch } from "react-router";
import { debounce } from "ts-debounce";
import { ShopClient } from "../../api/spacex";
import { AccountStatus, AdminPartnerStoresVariables, AdminPartnerStores_adminPartnerStores, AdminPartnerStores_adminPartnerStores_edges, AdminPartnerStores_adminPartnerStores_edges_node } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { getAccountPricingStatusBadge } from "../../utils/helpers";
import { CleanSpaceX } from "../types";

type AdminAccountsListResult = CleanSpaceX<AdminPartnerStores_adminPartnerStores>;
type AdminAccount = CleanSpaceX<AdminPartnerStores_adminPartnerStores_edges_node>;
type AccountFilters = {
    isTest?: boolean,
    status?: AccountStatus,
    query?: string,
    smartBanner?: boolean,
    pageSize: number
};

export const PartnerStores = React.memo((
    { partner }: { partner: string }
) => {
    const client = useClient();
    const location = useLocation();
    const match = useRouteMatch();

    const [results, setResults] = useState<AdminAccountsListResult>();
    const [smartBannerResults, setSmartBannersResults] = useState<AdminPartnerStores_adminPartnerStores_edges[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const [accountFilters, setAccountFilters] = useState<AccountFilters>({
        pageSize: 10,
        isTest: undefined,
        status: undefined,
        smartBanner: undefined
    });


    const loadNextPage = useCallback(() => {
        const queryData: AdminPartnerStoresVariables = {
            id: partner,
            first: accountFilters.pageSize,
            after: results?.pageInfo.endCursor,
            isTest: accountFilters.isTest,
            status: accountFilters.status,
            query: accountFilters.query
        };
        console.log('Load next page', queryData)
        setLoading(true);
        client.queryAdminPartnerStores(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminPartnerStores);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [results, accountFilters, partner]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            console.log('No previous page', results);
            throw new Error("No previous page");
        }
        const queryData: AdminPartnerStoresVariables = {
            id: partner,
            last: accountFilters.pageSize,
            before: results.pageInfo.startCursor,
            isTest: accountFilters.isTest,
            status: accountFilters.status,
            query: accountFilters.query
        };
        console.log('Load next page', queryData);
        setLoading(true);
        client.queryAdminPartnerStores(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminPartnerStores);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [results, accountFilters, partner]);

    const handleFilterChange = useCallback((field: keyof AccountFilters, newValue: any) => {
        setResults(undefined);
        setAccountFilters((prevState) => {
            const newState = { ...prevState, ...{ [field]: newValue } };
            console.log('New state', newState);
            return newState;
        });
    }, [setAccountFilters]);

    const reloadPage = useCallback(
        debounce((lp: () => void) => {
            console.log('reloadPage');
            return lp();
        }, 300)
        , []);

    useEffect(() => {
        reloadPage(loadNextPage);
    }, [accountFilters, reloadPage]);

    useEffect(() => {
        async function getSmartBannerAccs(client: ShopClient, res?: AdminAccountsListResult): Promise<undefined | void> {
            if (!res) {
                return undefined;
            }
            setLoading(true);

            let filtered: AdminPartnerStores_adminPartnerStores_edges[] = [];
            for (const edge of res.edges) {
                try {
                    const app = await client.queryAdminAccountApp({ accountId: edge.node.id });

                    if (app.adminAccountApp.settings?.banner?.scriptTagId) {
                        filtered.push(edge);
                    }
                } catch (error) {
                    console.log('App error', error);

                }
            }
            setSmartBannersResults(filtered);
            setLoading(false);
        }
        if (accountFilters.smartBanner) {
            getSmartBannerAccs(client, results);
        } else {
            setSmartBannersResults(undefined);
        }
    }, [results, accountFilters, client]);

    return (
        <Card
            title={(
                <Stack distribution={'leading'} alignment={'center'}>
                    <Stack.Item fill>
                        <Heading>Partner's sotres</Heading>
                    </Stack.Item>
                    {/* <Stack.Item>
                        <Stack vertical distribution={"leading"} alignment={"center"} spacing={"extraTight"}>
                            <Stack.Item>
                                <Caption>
                                    <TextStyle variation={"subdued"}>{results?.pageInfo.}</TextStyle>
                                </Caption>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item> */}
                </Stack>
            )}
        >
            <Card.Section>
                <ResourceList
                    items={
                        smartBannerResults && smartBannerResults.length > 0
                            ? smartBannerResults
                            : results?.edges || []
                    }
                    loading={loading}
                    filterControl={(
                        <Stack distribution={"leading"}>
                            <Stack.Item>
                                <TextField
                                    label={"Query"}
                                    labelHidden
                                    placeholder={"Query"}
                                    value={accountFilters.query}
                                    onChange={(value) => {
                                        return handleFilterChange('query', value);
                                    }}
                                    autoComplete={'off'}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Select
                                    label={"Status"}
                                    labelInline
                                    value={accountFilters.status}
                                    options={[
                                        { label: 'All', value: 'all' },
                                        { label: 'Active', value: AccountStatus.ACTIVE },
                                        { label: 'Inactive', value: AccountStatus.INACTIVE },
                                        { label: 'Deleted', value: AccountStatus.DELETED },
                                        { label: 'Created', value: AccountStatus.CREATED },
                                        { label: 'Blocked', value: AccountStatus.BLOCKED },
                                        { label: 'On review', value: AccountStatus.ON_REVIEW },
                                    ]}
                                    onChange={(value) => {
                                        return handleFilterChange('status', value === 'all' ? undefined : value);
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Select
                                    label={"Is test"}
                                    labelInline
                                    value={typeof accountFilters.isTest === 'undefined' ? 'all' : (accountFilters.isTest ? 'test' : 'real')}
                                    options={[
                                        { label: "All", value: 'all' },
                                        { label: "Real", value: 'real' },
                                        { label: "Test", value: 'test' }
                                    ]}
                                    onChange={(value) => {
                                        return handleFilterChange('isTest', value === 'all' ? undefined : (value === 'real' ? false : true))
                                    }}

                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Select
                                    label={"Page size"}
                                    labelInline
                                    value={String(accountFilters.pageSize)}
                                    options={[
                                        { label: '5', value: '5' },
                                        { label: '10', value: '10' },
                                        { label: '30', value: '30' }
                                    ]}
                                    onChange={(value) => {
                                        return handleFilterChange('pageSize', parseInt(value));
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Checkbox
                                    label="Smart banner turned on"
                                    checked={accountFilters.smartBanner}
                                    onChange={
                                        (value) => {
                                            return handleFilterChange('smartBanner', value);
                                        }
                                    }
                                />
                            </Stack.Item>
                        </Stack>
                    )}
                    renderItem={(item) => {
                        const node = item.node;

                        let pricingBadge = getAccountPricingStatusBadge(node, false);

                        return (<ResourceItem
                            id={node.id}
                            media={(<Avatar customer size={"medium"} name={node.name} />)}
                            url={`${match.url}/${node.id}/edit`}
                        // shortcutActions={[
                        //     { content: 'Log in', onAction: () => handleLoginAsUser(node) }
                        // ]}
                        >
                            <h3>
                                <TextStyle variation={"strong"}>{node.name}</TextStyle>
                            </h3>
                            <View>
                                <Stack>
                                    <Stack.Item>
                                        {pricingBadge}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Badge
                                            status={"info"}>{"Register:" + moment(parseInt(node.createdAt)).format('ll')}</Badge>
                                    </Stack.Item>
                                    {/* <Stack.Item>
                                        {monitoringBadge}
                                    </Stack.Item> */}
                                </Stack>
                            </View>
                        </ResourceItem>);
                    }}
                />
            </Card.Section>
            {
                (results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) &&
                <Card.Section>
                    <Stack distribution={"center"}>
                        <Pagination
                            hasPrevious={results?.pageInfo.hasPreviousPage}
                            onPrevious={() => {
                                loadPrevPage()
                            }}
                            hasNext={results?.pageInfo.hasNextPage}
                            onNext={() => {
                                loadNextPage()
                            }}
                        />
                    </Stack>
                </Card.Section>
            }
        </Card>
    );
});
import { Card, Link, Stack } from "@shopify/polaris";
import React, { useEffect } from "react";
import { useClient } from "../api/useClient";

export const AccountsStatistics = React.memo(() => {
    const client = useClient();
    const stats = client.useAdminAccountsStatistics().adminAccountsStatistics;
    useEffect(() => {
        const intervalId = setInterval(() => {
            client.refetchAdminAccountsStatistics();
        }, 1000 * 60 * 15);
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    return (
        <Card title={'General statistics'}>
            <Card.Section>
                <Stack vertical>
                    <a href="https://sasi.unionworks.co.uk/app/cartmate">https://sasi.unionworks.co.uk/app/cartmate</a>
                    <Stack>
                        <Stack vertical>
                            <Stack>
                                <Link url={'/accounts?status=ACTIVE'}>
                                    <Stack.Item>
                                        {'Active Accounts:'}
                                    </Stack.Item>
                                </Link>
                                <Stack.Item>
                                    {stats.accounts}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Link url={'/accounts?smartBanner=true&status=ACTIVE'}>
                                    <Stack.Item>
                                        {'Accounts with banner:'}
                                    </Stack.Item>
                                </Link>
                                <Stack.Item>
                                    {stats.accountsWithBanner}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <Stack vertical>
                            <Stack>
                                <Stack.Item>
                                    {'Banner clicks:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.bannerClicks}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    {'Banner impressions:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.bannerImpressions}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    {'Banner mobile impressions:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.bannerMobileImpressions}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    {'Clickthrough rate:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {`${Number((stats.clickThroughRate || 0)).toFixed(2)}%`}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <Stack vertical>
                            <Stack>
                                <Stack.Item>
                                    {'Orders:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.ordersCount}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    {'Orders sum:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.ordersSum.map((i) => {
                                        var formatter = new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: i.currencyCode,
                                        });
                                        return `${formatter.format(i.sum)}`
                                    }).join(', ')}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <Stack vertical>
                            <Stack>
                                <Stack.Item>
                                    {'Installs:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.installs}
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    {'Visits:'}
                                </Stack.Item>
                                <Stack.Item>
                                    {stats.visits}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Card.Section>
        </Card>
    );
});
import React from "react";
import {isTopLevel} from "./auth/utils";
import {AdminAuthLoader} from "./auth/AdminAuthLoader";
import {Redirect, Switch} from "react-router";
import {Route} from "./routing/routing.web";
import {AdminLoginComponent} from "./routes/AdminLoginComponent";
import {AdminLogoutComponent} from "./routes/AdminLogoutComponent";
import {AdminProtectedRoute} from "./auth/AdminProtectedRoute";
import {View} from "react-native";
import {AdminRegisterComponent} from "./routes/AdminRegisterComponent";
import {AdminAppLoader} from "./components/AdminAppLoader";
import { Suspense } from "./components/Suspense";


export const AdminScreen = React.memo(()=>{
    if (!isTopLevel) {
        throw new Error("Admin cannot be run in iframe");
    }


    return (
        <AdminAuthLoader>
            <View style={{width: "100vw", height: "100vh", overflow: "scroll"}}>
                <Suspense>
                    <Switch>
                        <Route path={"/register"} component={AdminRegisterComponent}/>
                        <Route path={"/login"} component={AdminLoginComponent}/>
                        <Route path={"/logout"} component={AdminLogoutComponent}/>
                        <AdminProtectedRoute key={"/dashboard"} component={AdminAppLoader} />
                        <Redirect from={'/'} to={'/dashboard'} />
                    </Switch>
                </Suspense>
            </View>
        </AdminAuthLoader>
    );
});
import { Card, ChoiceList, FormLayout, TextField } from "@shopify/polaris";
import React, { useCallback } from "react";
import { AccountCapability, CapabilitiesList, CapabilityType } from "./types";
import { useClient } from "../../api/useClient";

export const PricingCapabilities = React.memo(({
    capabilities,
    selected,
    setSelected
}: {
    capabilities: CapabilitiesList,
    selected: AccountCapability,
    setSelected: (value: AccountCapability) => void
}) => {
    const client = useClient();
    const handleCapabilitySelect = useCallback((capabilityKey: string, value: CapabilityType[]) => {
        console.log('Old value', selected);
        let newVal = Object.assign({}, selected);
        newVal[capabilityKey].capability = value;
        console.log('New value', newVal);
        setSelected(newVal);
    }, [selected]);

    const handleLimitSelect = useCallback((capabilityKey: string, limitKey: string, value: number) => {
        console.log('Old value', selected);
        let newVal = Object.assign({}, selected);
        if (newVal[capabilityKey].limits) {
            newVal[capabilityKey].limits![limitKey] = value;
            console.log('New value', newVal);
            setSelected(newVal);
        }
    }, [selected]);

    return (
        <Card title={"Capabilities"}>
            {Object.entries(capabilities).map(([key, value]) => {
                const choices = Object.entries(value.capabilities)
                    .map(([cKey, cValue]) => {
                        return { label: cValue, value: cKey };
                    });
                return (
                    <Card.Section title={value.title} key={`cap-${key}`}>
                        <Card.Section title={"Capabilities"}>
                            <FormLayout>
                                <ChoiceList
                                    allowMultiple
                                    title={"Capabilities"}
                                    titleHidden
                                    choices={choices}
                                    selected={selected[key]?.capability || []}
                                    onChange={(value: CapabilityType[]) => {
                                        handleCapabilitySelect(key, value);
                                    }}
                                />
                            </FormLayout>
                        </Card.Section>
                        {value.limits && (
                            <Card.Section title={'Limits'}>
                                {Object.entries(value.limits).map(([lKey, lValue]) => {
                                    const v: string = '';
                                    return (
                                        <TextField
                                            key={`cap-${key}-${lKey}`}
                                            label={lValue.title}
                                            value={String(selected[key]?.limits![lKey] || '')}
                                            type={"number"}

                                            onChange={(value) => {
                                                let parsedValue: number = parseInt(value);
                                                if (!isNaN(parsedValue)) {
                                                    handleLimitSelect(key, lKey, parsedValue);
                                                }
                                            }}
                                        />
                                    );
                                })}
                            </Card.Section>
                        )}
                    </Card.Section>
                );
            })}
        </Card>
    );
})
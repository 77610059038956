import { Card, Labelled, Layout, Link, Modal, Page, Stack, TextField, TextStyle } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useHistory, useRouteMatch } from "react-router";
import { useClient } from "../../api/useClient";
import { PartnerEditForm } from "../../components/partners/PartnerEditForm";
import { PartnerStores } from "../../components/partners/PartnerStores";

type PartnerForm = {
    title: string,
    slug: string,
    payoutPercent: number,
    email: string,
    notes: string,
};

export const AdminPartnerEdit = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ partnerId: string }>();
    const { partnerId } = match.params;
    const history = useHistory();
    const partner = client.useAdminGetPartner({ id: partnerId }).adminPartner;
    const [formState, setFormState] = useState<PartnerForm>({
        title: partner.title,
        slug: partner.slug,
        payoutPercent: partner.payoutPercent,
        email: partner.email,
        notes: partner.notes || ''
    });
    const [editModalOpened, setEditModalOpened] = useState(false);

    console.log('P', formState)

    const onEdit = useCallback(
        () => {
            setEditModalOpened(true);
        },
        [setEditModalOpened],
    );

    const onSave = useCallback(
        () => {
            console.log('Add', formState);
            client.mutateAdminUpdatePartner({ input: formState, id: partnerId })
                .then((result) => {
                    setFormState({
                        title: result.adminUpdatePartner.title,
                        slug: result.adminUpdatePartner.slug,
                        payoutPercent: result.adminUpdatePartner.payoutPercent,
                        email: result.adminUpdatePartner.email,
                        notes: result.adminUpdatePartner.notes || ''
                    });
                    setEditModalOpened(false);
                })
                .catch(console.warn);
        },
        [formState],
    );

    const onClose = useCallback(
        () => {
            setFormState({
                title: partner.title,
                slug: partner.slug,
                payoutPercent: partner.payoutPercent,
                email: partner.email,
                notes: partner.notes || ''
            })
            setEditModalOpened(false);
        },
        [partner],
    );

    return (
        <Page title={`Partner ${partner.title}`} breadcrumbs={[{ content: "Partners", url: "/partners" }]}>
            <Layout>
                <Layout.Section>
                    <Card primaryFooterAction={{
                        content: 'Edit',
                        onAction: onEdit
                    }}>
                        <Card.Section>

                            <Stack vertical>
                                <Labelled label={'Partner url'} id={'partner-url'}>
                                    <TextStyle variation="strong">
                                        {`https://cartmate.com/share/${partner.slug}`}
                                    </TextStyle>
                                </Labelled>
                                <PartnerEditForm
                                    initValue={formState}
                                    onChange={setFormState}
                                    disabled
                                />
                            </Stack>
                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <PartnerStores partner={partnerId} />
                </Layout.Section>
            </Layout>
            <Modal
                open={editModalOpened}
                onClose={onClose}
                title={'Edit partners account'}
                primaryAction={{
                    content: 'Save',
                    onAction: onSave
                }}
            >
                <Modal.Section>
                    <PartnerEditForm
                        initValue={formState}
                        onChange={setFormState}
                        disableSlug
                    />
                </Modal.Section>
            </Modal>
        </Page>
    )
})
var styles = {
  "Title": "Polaris-Header-Title",
  "SubTitle": "Polaris-Header-Title__SubTitle",
  "SubtitleCompact": "Polaris-Header-Title__SubtitleCompact",
  "hasThumbnail": "Polaris-Header-Title--hasThumbnail",
  "TitleAndSubtitleWrapper": "Polaris-Header-Title__TitleAndSubtitleWrapper",
  "TitleWithMetadataWrapper": "Polaris-Header-Title__TitleWithMetadataWrapper",
  "TitleMetadata": "Polaris-Header-Title__TitleMetadata"
};

export { styles as default };

var styles = {
  "ChoiceList": "Polaris-ChoiceList",
  "titleHidden": "Polaris-ChoiceList--titleHidden",
  "Title": "Polaris-ChoiceList__Title",
  "Choices": "Polaris-ChoiceList__Choices",
  "ChoiceChildren": "Polaris-ChoiceList__ChoiceChildren",
  "ChoiceError": "Polaris-ChoiceList__ChoiceError"
};

export { styles as default };

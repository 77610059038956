var styles = {
  "Page": "Polaris-SkeletonPage__Page",
  "fullWidth": "Polaris-SkeletonPage--fullWidth",
  "narrowWidth": "Polaris-SkeletonPage--narrowWidth",
  "Content": "Polaris-SkeletonPage__Content",
  "Header": "Polaris-SkeletonPage__Header",
  "BreadcrumbAction": "Polaris-SkeletonPage__BreadcrumbAction",
  "TitleAndPrimaryAction": "Polaris-SkeletonPage__TitleAndPrimaryAction",
  "TitleWrapper": "Polaris-SkeletonPage__TitleWrapper",
  "Title": "Polaris-SkeletonPage__Title",
  "SkeletonTitle": "Polaris-SkeletonPage__SkeletonTitle",
  "PrimaryAction": "Polaris-SkeletonPage__PrimaryAction",
  "Actions": "Polaris-SkeletonPage__Actions",
  "Action": "Polaris-SkeletonPage__Action"
};

export { styles as default };

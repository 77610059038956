var styles = {
  "Group": "Polaris-BulkActions__Group",
  "Group-measuring": "Polaris-BulkActions__Group--measuring",
  "Group-entering": "Polaris-BulkActions__Group--entering",
  "Group-exiting": "Polaris-BulkActions__Group--exiting",
  "Group-entered": "Polaris-BulkActions__Group--entered",
  "Group-exited": "Polaris-BulkActions__Group--exited",
  "Group-smallScreen": "Polaris-BulkActions__Group--smallScreen",
  "Group-largeScreen": "Polaris-BulkActions__Group--largeScreen",
  "ButtonGroupWrapper": "Polaris-BulkActions__ButtonGroupWrapper",
  "BulkActionButton": "Polaris-BulkActions__BulkActionButton",
  "CheckableContainer": "Polaris-BulkActions__CheckableContainer",
  "disabled": "Polaris-BulkActions--disabled",
  "PaginatedSelectAll": "Polaris-BulkActions__PaginatedSelectAll",
  "Slide": "Polaris-BulkActions__Slide",
  "Slide-appear": "Polaris-BulkActions__Slide--appear",
  "Slide-enter": "Polaris-BulkActions__Slide--enter",
  "Slide-exit": "Polaris-BulkActions__Slide--exit",
  "Slide-appearing": "Polaris-BulkActions__Slide--appearing",
  "Slide-entering": "Polaris-BulkActions__Slide--entering"
};

export { styles as default };

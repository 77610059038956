import { Badge, Banner, BannerStatus, Card, Heading, IndexTable, Stack, TextStyle, Toast } from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";
import { SyncStatus } from "../api/spacex.types";
import { useClient } from "../api/useClient";

export const AccountCatalog = React.memo((
    { id }: { id: string }
) => {

    const client = useClient();
    const account = client.useAdminGetAccount({ accountId: id }).adminAccount;
    const [syncToastShown, setToastShown] = useState(false);
    const syncToast = syncToastShown ? (
        <Toast content="Synchronization queued" onDismiss={() => setToastShown(false)} />
    ) : null;

    const handleForceSync = useCallback(() => {
        client.mutateAdminForceSync({ accountId: id })
            .then((result) => {
                setToastShown(true);
                client.refetchAdminGetAccount({ accountId: id });
            })
            .catch((e) => {
                console.warn(e);
            });
    }, []);
    const syncState = client.useAdminAccountSyncState({ accountId: id }).adminAccountSyncState;
    let syncStatus = '', bannerStatus: BannerStatus = 'info', syncBadge: JSX.Element = (<Badge status={'attention'}>Unknown</Badge>);
    switch (syncState?.status) {
        case SyncStatus.IN_PROGRES:
            syncBadge = (<Badge status="info">In progress</Badge>);            
            break;
        case SyncStatus.PENDING:
            syncBadge = (<Badge status="warning">Queued</Badge>);                        
            break;
        case SyncStatus.READY:
            syncBadge = (<Badge status="success">Synchronized</Badge>);                        
            break;
        default:            
            break;
    }

    useEffect(() => {
        const updateSyncStateId = setInterval(() => {
            client.refetchAdminAccountSyncState({ accountId: id });
        }, 500);
        return () => {
            clearInterval(updateSyncStateId);
        };
    }, []);

    return (
        <>
            <Card
                title={(<Heading>Catalog status {syncBadge}</Heading>)}
                actions={[
                    { content: 'Force sync', onAction: handleForceSync }
                ]}
            >
           
                <Card.Section>
                    <IndexTable
                        resourceName={{ plural: "Catalog statuses", singular: "Catalog status" }}
                        selectedItemsCount={0}
                        itemCount={3}
                        headings={[
                            { title: 'Source' },
                            { title: 'Products' },
                            { title: 'Products\'s price' },
                            { title: 'Variants count' },
                            { title: 'Collections' },
                        ]}
                        // onSelectionChange={(selectionType, toggleType, selection) => { }}
                        selectable={false}
                    >
                        <IndexTable.Row
                            id={'Database'}
                            key={'db-mon'}
                            position={0}
                            selected={false}                            
                        >
                            <IndexTable.Cell>Database</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.database.products}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.database.productsPrice}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.database.variants}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.database.collections}</IndexTable.Cell>
                        </IndexTable.Row>
                        <IndexTable.Row
                            id={'ElasticSearch'}
                            key={'elastic-mon'}
                            position={1}
                            selected={false}
                        >
                            <IndexTable.Cell>ElasticSearch</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.index.products}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.index.productsPrice}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.index.variants}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.index.collections}</IndexTable.Cell>
                        </IndexTable.Row>
                        <IndexTable.Row
                            id={'Shopify'}
                            key={'shopify-mon'}
                            position={2}
                            selected={false}
                        >
                            <IndexTable.Cell>Shopify</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.shopify.products}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.shopify.productsPrice}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.shopify.variants}</IndexTable.Cell>
                            <IndexTable.Cell>{account.monitoringStatus?.data.catalogCounters.shopify.collections}</IndexTable.Cell>
                        </IndexTable.Row>
                    </IndexTable>

                </Card.Section>
            </Card>
            {syncToast}
        </>
    );
});
import { useClient } from "../api/useClient";
import { AccountCapability, AccountCapabilityFeature, AccountCapabilityLimit, CapabilitiesList } from "../routes/billing/types";

export function usePricingCapabilities(): [CapabilitiesList, AccountCapability] {
    const client = useClient();
    const queryResult = client.usePricingCapabilities().adminPricingCapabilities;    
    const result: CapabilitiesList = JSON.parse(queryResult) as CapabilitiesList;
    const initSelectedCaps: AccountCapability = Object.fromEntries(
        Object.entries(result)
            .map(([key, value]) => {
                return [
                    key,
                    {
                        capability: [],
                        limits: value.limits ?
                            Object.fromEntries(
                                Object.entries(value.limits)
                                    .map(([key, value]) => { return [key, 0] })
                            ) : undefined
                    }
                ];
            })
    );    
    return [result, initSelectedCaps];
}
import React, {useCallback, useState} from "react";
import {
    Page,
    Layout,
    Card,
    ResourceList,
    ResourceItem,
    TextStyle,
    Modal,
    Toast,
    Stack,
    Badge
} from '@shopify/polaris';
import {useClient} from "../api/useClient";
import {CleanSpaceX} from "../components/types";
import {
    AdminSurveyIndustryList_surveyIndustryList
} from "../api/spacex.types";
import {useHistory, useRouteMatch} from "react-router";
import { View } from "react-native";

type AdminSurveyIndustry = CleanSpaceX<AdminSurveyIndustryList_surveyIndustryList>;

export const AdminSurveyIndustryList = React.memo(() => {
    const client = useClient();    
    const [results, setResults] = useState<AdminSurveyIndustry[]>(client.useAdminSurveyIndustryList().surveyIndustryList);    
    const history = useHistory<{ from?: Location }>();
    const match = useRouteMatch();        

    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
    const handleRemoveModalClose = useCallback(() => setRemoveModalOpen(!removeModalOpen), [removeModalOpen]);
    const [itemToRemove, setItemToRemove] = useState<string | undefined>(undefined);

    const [removeToastActive, setRemoveToastActive] = useState<boolean>(false);
    const toggleRemoveToastActive = useCallback(() => setRemoveToastActive(!removeToastActive), [removeToastActive]);
    const handleConfirmRemove = useCallback(() => {
        if (!itemToRemove) {
            return;        
        }
        client.mutateAdminDeleteSurveyIndustry({ id: itemToRemove })
            .then((result) => {
                console.log('Delete result', result);
                setItemToRemove(undefined);
                
                client.refetchAdminSurveyIndustryList()
                    .then((result) => {
                        toggleRemoveToastActive();
                        setResults(result.surveyIndustryList);
                    })
                    .catch((e) => console.warn(e))
                    .finally(() => {
                        handleRemoveModalClose();
                    })
            })
            .catch((e) => {
                console.warn(e);
                setItemToRemove(undefined);
                handleRemoveModalClose();
            });
    }, [itemToRemove]);    
    
    const removeToast = removeToastActive ? (
        <Toast content={"Industry removed"} onDismiss={toggleRemoveToastActive} />
    ) : null;

    return (
    <Page 
        title={"Survey industries"} 
        primaryAction={{
            content: 'Add industry', 
            onAction: () => {history.push({pathname: `${match.url}/new`})}
        }}        
    >
        <Layout>
            <Layout.Section>
                <Card>
                    <Card.Section>
                        <ResourceList
                            items={results || []}                                                        
                            renderItem={(item) => {
                                const node = item;
                                return (<ResourceItem
                                    id={node.id}                                
                                    url={`${match.url}/${node.id}/edit`}
                                    shortcutActions={[
                                        {content: 'Remove', onAction: () => { setItemToRemove(node.id); handleRemoveModalClose();  }}
                                    ]}
                                >
                                    <h3>
                                        <TextStyle variation={"strong"}>{node.title}</TextStyle>
                                    </h3>                 
                                    <View>
                                        <Stack>                                            
                                            <Stack.Item>
                                                <Badge
                                                    status={"info"}>{"Sort:" + node.sort}</Badge>
                                            </Stack.Item>                                            
                                        </Stack>
                                    </View>                   
                                </ResourceItem>);
                            }}
                        />
                    </Card.Section>                
                </Card>
            </Layout.Section>
        </Layout>
        <Modal
            open={removeModalOpen}
            onClose={handleRemoveModalClose}
            title={"Confirmation"}
            primaryAction={{
                content: 'Confirm',
                onAction: handleConfirmRemove
            }}
            secondaryActions={[
                { content: "Close", onAction: handleRemoveModalClose }
            ]}
        >
            <Modal.Section>
                Do you want to removeo item?
            </Modal.Section>
        </Modal>
        {removeToast}
    </Page>
    );
});
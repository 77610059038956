import React, {useEffect, useState} from "react";
import {useAdminAuthContext} from "../auth/AdminAuthLoader";
import {CartmateClientException} from "../exeptions/CartmateException";
import {ShopClient} from "../api/spacex";
import {createClient} from "../api/createClient";
import { ClientContext } from '../api/useClient';
import { TokenContext } from '../api/useApiToken';


import {View} from "react-native";
import AdminAppComponent from "../routes/AdminAppComponent";
import { Suspense } from "./Suspense";
import { Loader } from "./Loader";

export const AdminAppLoader = React.memo(() => {
    const authProvider = useAdminAuthContext();
    if (!authProvider.isAuthed || !authProvider.token) {
        throw new CartmateClientException();
    }

    const [client, setClient] = useState<ShopClient|null>(null);

    useEffect(() => {
        console.log('Token changed', authProvider.token);
        if (authProvider.token) {
            setClient(createClient(authProvider.token));
        }
        return () => {client?.close();};
    }, [authProvider.token]);

    if (client === null) {
        return (<Loader />);
    }

    return (
        <ClientContext.Provider value={client}>
            <TokenContext.Provider value={authProvider.token}>
                <Suspense>
                    <View style={{width: '100vw', height: '100vh'}}>
                        <AdminAppComponent />
                    </View>
                </Suspense>
            </TokenContext.Provider>
        </ClientContext.Provider>
    );
});
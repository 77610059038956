import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import {
    Frame,
    Layout,
    Page,
    Pagination,
    Card,
    ResourceList,
    ResourceItem,
    TextStyle,
    Stack,
    TextContainer,
    Thumbnail,
    Toast,
    FormLayout,
    TextField,
    Select,
    Badge
} from '@shopify/polaris';
import { useClient } from "../api/useClient";
import { CleanSpaceX } from "../components/types";
import { AdminAppCard_adminListAppCard, AppCardStatus } from "../api/spacex.types";
import { useRouteMatch } from "react-router";

type AdminAppCardResult = CleanSpaceX<AdminAppCard_adminListAppCard>;

export const AdminAppCardEditComponent = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ cardId: string }>();
    const [revoked, setRevoked] = useState(false);
    const { cardId } = match.params;
    const [loading, setLoading] = useState(false);
    const card = client.useAdminGetAppCard({ appCardId: cardId }).adminAppCard;
    console.log('App card', card);

    const onRevoke = useCallback(
        () => {
            client.mutateAdminRevokeAppCard({ appCardId: cardId }).then((result) => {
                if (result && !result.adminDeactivatePublischedAppCard.publishedCard) {
                    setRevoked(true);
                }
            });
        },
        [client, cardId],
    );

    const shareToast = revoked ? (
        <Toast content="App Card Revoked!" onDismiss={() => setRevoked(false)} />
    ) : null;

    const handlePublishCard = useCallback(() => {
        setLoading(true);
        client.mutateAdminPublishAppCard({ appCardId: card.id })
            .then((result) => {
                console.log('Card published');
                setLoading(false);
                client.refetchAdminGetAppCard({ appCardId: cardId })
            })
            .catch((e) => {
                console.log('handlePublishCard', e);
            }).finally(() => {
                setLoading(false);
            });
    }, [client, card, cardId]);
    let statusBadge;
    if (card.status === AppCardStatus.ACTIVE) {
        statusBadge = (<Badge status={"success"}>Active</Badge>);
    } else if (card.status === AppCardStatus.DRAFT) {
        statusBadge = (<Badge status={"info"}>Draft</Badge>);
    } else if (card.status === AppCardStatus.ON_REVIEW) {
        statusBadge = (<Badge status={"attention"}>On review</Badge>);
    } else {
        statusBadge = (<Badge status={"critical"}>Inactive</Badge>);
    }

    return (
        <Page
            title={"App card: " + card.title}
            breadcrumbs={[{ content: "App cards", url: "/cards" }]}
        >
            <Layout>
                <Layout.Section>
                    <Card
                        secondaryFooterActions={[
                            {
                                content: 'Revoke app card',
                                onAction: onRevoke,
                                loading
                            }
                        ]}
                        primaryFooterAction={
                            { 
                                content: 'Publish', 
                                onAction: handlePublishCard,
                                loading: loading
                            }
                        }
                    >
                        <Card.Section>
                            {statusBadge}
                        </Card.Section>
                        <Card.Section>
                            <FormLayout>
                                <TextField
                                    label="Title"
                                    value={card.title}
                                    disabled
                                    autoComplete={'off'}
                                />
                                <TextField
                                    label="Description"
                                    value={card.description}
                                    disabled
                                    multiline={4}
                                    autoComplete={'off'}
                                />
                                {card.icon?.src && (<Thumbnail
                                    source={card.icon.src}
                                    size="large"
                                    alt="Card icon"
                                />)}
                                {card.background?.src && (<Thumbnail
                                    source={card.background.src}
                                    size="large"
                                    alt="Card background"
                                />)}
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
                {shareToast}
            </Layout>
        </Page>);
});
import Bugsnag from "@bugsnag/browser";
import { Button, ButtonGroup, Card, DatePicker, Form, FormLayout, Heading, Modal, Stack, TextField, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { AccountPricingStatus, AdminGetAccount_adminAccount } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { getAccountPricingStatusBadge } from "../utils/helpers";
import { PricingList } from "./PricingList";
import { SelectablePricingList } from "./SelectablePricingList";
import { CleanSpaceX } from "./types";

type Account = CleanSpaceX<AdminGetAccount_adminAccount>;

export const AccountSubscription = React.memo((
    { id }: { id: string }
) => {
    const client = useClient();

    const [account, setAccount] = useState<Account>(client.useAdminGetAccount({ accountId: id }).adminAccount);

    let pricingBadge = getAccountPricingStatusBadge(account, false);


    const now = new Date();
    const [selectedDate, setSelectedDate] = useState<Date>(now);
    const [trialDate, setTrialDate] = useState({ month: now.getMonth(), year: now.getFullYear() });
    const [trialModalOpen, setTrialModalOpen] = useState<boolean>(false);
    const [trialHours, setTrialHours] = useState<string>('23');
    const [trialMinutes, setTrialMinutes] = useState<string>('59');

    const handleMakeTrial = useCallback(() => {
        setTrialModalOpen(true);
    }, [account]);

    const handleSubmitTrialForm = useCallback((_event) => {
        console.log('Submit event', _event);
        console.log('Submit trial form', selectedDate.getTime(), trialHours, trialMinutes);
        client.mutateAdminMakeAccountTrial({
            accountId: account.id,
            input: {
                timestamp: String(selectedDate.getTime()),
                hours: trialHours,
                minutes: trialMinutes
            }
        }).then((result) => {
            console.log('Account updated!');
            setAccount(result.adminMakeAccountTrial);
            setTrialModalOpen(false);
        }).catch((e) => {
            console.log('Account error', e);
        });
    }, [selectedDate, trialHours, trialMinutes]);

    const handleMonthChange = useCallback((month, year) => {
        setTrialDate({ month: month, year: year });
    }, []);

    const [changePricingModalOpen, setChangePricingModalOpen] = useState<boolean>(false);

    const handleSubscribeToPricing = useCallback(
        (pricingId: string) => {
            console.log('[AccountSubscription] handleSubscribeToPricing', pricingId);
            client.mutateAdminAccountSetPricing({ account: id, pricingId: pricingId, active: true })
                .then((result) => {
                    console.log(result);
                }).catch((error: any) => {
                    console.log('[AccountSubscription] handleSubscribeToPricing', error);
                    Bugsnag.notify(error);
                }).finally(() => {
                    setChangePricingModalOpen(false);
                });
        },
        [client, id, setChangePricingModalOpen],
    );

    return (
        <>
            <Card
                title={<Heading>Subscription {pricingBadge}</Heading>}
                actions={[
                    { content: "Change pricing", onAction: () => { setChangePricingModalOpen(true); } }
                ]}
            >
                <Card.Section>
                    <Stack vertical alignment={"leading"} distribution={"center"} spacing={"tight"}
                        wrap={false}>
                        <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                            <Stack.Item>
                                <TextStyle variation={"strong"}>Pricing: </TextStyle>
                            </Stack.Item>
                            <Stack.Item fill>
                                <p>{account.pricing.pricing?.title}</p>
                            </Stack.Item>
                        </Stack>
                        <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                            <Stack.Item>
                                <TextStyle variation={"strong"}>Created at: </TextStyle>
                            </Stack.Item>
                            <Stack.Item fill>
                                <p>{moment(parseInt(account.pricing.createdAt)).format('lll')}</p>
                            </Stack.Item>
                        </Stack>
                        <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                            <Stack.Item>
                                <TextStyle variation={"strong"}>Updated at: </TextStyle>
                            </Stack.Item>
                            <Stack.Item fill>
                                <p>{moment(parseInt(account.pricing.updatedAt)).format('lll')}</p>
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Card.Section>
                <Card.Section actions={[
                    // { content: '', onAction: }
                ]}>
                    <ButtonGroup segmented>
                        {account.pricing.status === AccountPricingStatus.ACTIVE &&
                            <Button destructive>Cancel subscription</Button>
                        }
                        {account.pricing.status !== AccountPricingStatus.ACTIVE &&
                            <Button primary>Activate</Button>
                        }
                        {account.pricing.status === AccountPricingStatus.TRIAL &&
                            <Button onClick={handleMakeTrial}>Change trial status</Button>
                        }
                        {account.pricing.status !== AccountPricingStatus.TRIAL &&
                            <Button primary onClick={handleMakeTrial}>Make trial</Button>
                        }

                    </ButtonGroup>
                </Card.Section>
            </Card>
            <Modal
                open={trialModalOpen}
                title={"Change trial status"}
                onClose={() => {
                    setTrialModalOpen(false);
                }}
            >
                <Form onSubmit={handleSubmitTrialForm} preventDefault>
                    <Modal.Section>
                        <FormLayout>
                            <DatePicker
                                month={trialDate.month}
                                year={trialDate.year}
                                selected={selectedDate}
                                onChange={(val) => { console.log('Date chnage', val); setSelectedDate(val.start) }}
                                onMonthChange={handleMonthChange}
                            />
                        </FormLayout>
                        <FormLayout>
                            <FormLayout.Group>
                                <TextField
                                    type={"number"}
                                    label={"Hours"}
                                    value={trialHours}
                                    autoComplete={'off'}
                                    onChange={(val) => { setTrialHours(val); }}
                                    max={23}
                                    min={0}
                                />
                                <TextField
                                    type={"number"}
                                    label={"Minutes"}
                                    value={trialMinutes}
                                    autoComplete={'off'}
                                    onChange={(val) => { setTrialMinutes(val); }}
                                    max={59}
                                    min={0}
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </Modal.Section>
                    <Modal.Section>
                        <FormLayout>
                            <Button submit primary>Submit</Button>
                        </FormLayout>
                    </Modal.Section>
                </Form>
            </Modal>
            <Modal
                open={changePricingModalOpen}
                title={"Change account pricing"}
                onClose={() => {
                    setChangePricingModalOpen(false);
                }}
            >
                <SelectablePricingList
                    onSelect={(pricing) => {
                        console.log('Selected pricing', pricing);
                        handleSubscribeToPricing(pricing.id);
                    }}
                />
            </Modal>
        </>
    );
});
import React, { useState, useCallback } from "react";
import {
    Layout,
    Page,
    Card,
    Form,
    FormLayout,
    TextField,
    Button} from '@shopify/polaris';
import { useClient } from "../api/useClient";
import { useHistory, useRouteMatch } from "react-router";

export const AdminEditSurveyIndustry = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ industryId?: string }>();    
    const { industryId } = match.params; 
    const history = useHistory();
    let industry = undefined;
    if (industryId) {
        industry = client.useAdminGetSurveyIndustry({ id: industryId}).surveyIndustry
    }
    const [title, setTitle] = useState<string>(!industry ? '' : industry.title);
    const [sort, setSort] = useState<number>(!industry ? 0 : industry.sort);
    
    const handleSubmit = useCallback((_event) => {
        client.mutateAdminUpdateSurveyIndustry({input: {title, sort}, industryId})
            .then((result) => {
                console.log(result);                
                client.refetchAdminSurveyIndustryList().then(() => {
                    history.push({pathname: '/survey/industry'})    
                });
                
            })
            .catch((e) => console.warn)
    }, [title, sort]);

    const handleTitleChange = useCallback((value) => setTitle(value), []);     
    const handleSortChange = useCallback((value) => setSort(parseInt(value) ? parseInt(value) : 0), []);

    return (<Page
    title={"Survey industry"}
    breadcrumbs={[
        {content: 'Industries', url: '/survey/industry'}
    ]}
    >
        <Layout>
            <Layout.Section>
                <Card secondaryFooterActions={[]}>
                    <Card.Section>
                        <Form onSubmit={handleSubmit}>
                            <FormLayout>
                                <TextField 
                                    value={title}
                                    onChange={handleTitleChange}
                                    label={"Title"}
                                    type={"text"}                                    
                                    autoComplete={'off'}
                                />
                                <TextField
                                    value={String(sort)}
                                    onChange={handleSortChange}
                                    label={"Sort"}
                                    type={"number"}
                                    autoComplete={'off'}
                                />
                                <Button submit primary>Save</Button>
                            </FormLayout>
                        </Form>                                            
                    </Card.Section>
                </Card>
            </Layout.Section>            
        </Layout>
    </Page>);
});
import { Avatar, Card, Layout, Page, Pagination, ResourceItem, ResourceList, Select, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { AdminPartnersList_adminListPartner } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { CleanSpaceX } from "../../components/types";

type PartnersListResult = CleanSpaceX<AdminPartnersList_adminListPartner>;

export const AdminPartners = React.memo(() => {
    const client = useClient();
    const [pageSize, setPageSize] = useState<string>('10');
    const [results, setResults] = useState<PartnersListResult>();
    const [loading, setLoading] = useState<boolean>(false);

    let nextQueryData = {
        first: parseInt(pageSize),
        after: results?.pageInfo.endCursor,
    };

    const loadNextPage = useCallback(() => {
        console.log('Load next page', nextQueryData);
        setLoading(true);
        client.queryAdminPartnersList(nextQueryData)
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.adminListPartner);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, nextQueryData]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        const queryData = {
            last: parseInt(pageSize),
            before: results.pageInfo.startCursor,
        };
        console.log('Load prev page', queryData);
        setLoading(true);

        client.queryAdminPartnersList(queryData)
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.adminListPartner);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, results]);

    useEffect(() => {
        loadNextPage();
    }, [pageSize]);

    return (
        <Page title={"Partners"} primaryAction={{
            content: 'Add new partner',
            url: '/partner/new'
        }}>
            <Layout>
                <Layout.Section>
                    <Card title={'Partners list'}>
                        <Card.Section>
                            <ResourceList
                                items={results?.edges || []}
                                loading={loading}
                                filterControl={(
                                    <Stack distribution={"leading"}>
                                        <Stack.Item>
                                            <Select
                                                label={"Page size"}
                                                labelInline
                                                value={pageSize}
                                                options={[
                                                    { label: '5', value: '5' },
                                                    { label: '10', value: '10' },
                                                    { label: '30', value: '30' }
                                                ]}
                                                onChange={(value) => {
                                                    setResults(undefined);
                                                    setPageSize(value);
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                )}
                                renderItem={(item) => {
                                    const node = item.node;
                                    return (
                                        <ResourceItem
                                            id={node.id}
                                            // media={<Avatar size={"medium"} name={node.title} source={node.icon?.src || undefined} />}
                                            url={`/partner/${node.id}/edit`}
                                        >
                                            <h3>
                                                <TextStyle>{node.title}</TextStyle>
                                            </h3>
                                        </ResourceItem>
                                    );
                                }} />
                        </Card.Section>
                        {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                            <Stack distribution={"center"}>
                                <Pagination
                                    hasPrevious={results?.pageInfo.hasPreviousPage}
                                    onPrevious={() => {
                                        loadPrevPage()
                                    }}
                                    hasNext={results?.pageInfo.hasNextPage}
                                    onNext={() => {
                                        loadNextPage()
                                    }}
                                />
                            </Stack>
                        </Card.Section>}
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
});
import { Card, Labelled, Layout, Page, Pagination, ResourceList, Stack, TextStyle } from "@shopify/polaris"
import React from "react"
import { useRouteMatch } from "react-router";
import { useClient } from "../../api/useClient";
import { PartnerPayout } from "./PartnerPayout";

export const AdminPartner = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ id: string }>();
    const { id } = match.params;
    // const partner = client.usePartner({id: id}).partner;

    return (
        <Page title={'Partner'}>
            <Layout>
                <Layout.Section>
                    <Card>
                        <Card.Section>
                            <Labelled label={'Name'} id={"name"}>
                                <TextStyle variation="strong">
                                    {
                                        // `${partner.name}`
                                    }
                                </TextStyle>
                            </Labelled>
                            <Labelled label={'Link'} id={"link"}>
                                <TextStyle>
                                    {
                                        // `cartmate.com/share/${partner.slug}`
                                    }
                                </TextStyle>
                            </Labelled>
                            <Labelled label={'Percentage'} id={"percent"}>
                                <TextStyle>
                                    {
                                        // `${partner.percentage}`
                                    }
                                </TextStyle>
                            </Labelled>
                            <Labelled label={'Notes'} id={"notes"}>
                                <TextStyle>
                                    {
                                        // `${partner.notes}`
                                    }
                                </TextStyle>
                            </Labelled>
                        </Card.Section>
                        <Card.Section title={'Next payout'}>
                            <PartnerPayout id={`partner.nextPayout.id`} />
                        </Card.Section>
                        {/* <Card.Section title={'Next payout'}>
                            <ResourceList
                            items={results?.edges || []}
                                loading={loading}
                                renderItem={(item) => {
                                    const node = item.node;
                                    return (
                                        <PartnerPayout id={node.id}/>
                                    );
                                }} />
                        </Card.Section>
                        {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                            <Stack distribution={"center"}>
                                <Pagination
                                    hasPrevious={results?.pageInfo.hasPreviousPage}
                                    onPrevious={() => {
                                        loadPrevPage()
                                    }}
                                    hasNext={results?.pageInfo.hasNextPage}
                                    onNext={() => {
                                        // loadNextPage()
                                    }}
                                />
                            </Stack>
                        </Card.Section>} */}
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    )
})
// Init
import './src/Init';

// Boot UI
import React from 'react';
import { Router } from './src/routing/routing';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { View } from 'react-native';
import { Link } from './src/routing/routing';
import { AdminScreen } from "./src/AdminScreen";

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export default () => {
    return (
        <View style={{ height: '100vh' }}>
            <AppProvider
                features={{
                    newDesignLanguage: false
                }}                
                i18n={enTranslations}
                linkComponent={(props) => <Link to={props.url} {...(props as any)}>{props.children}</Link>}
            >
                <Router>
                    <AdminScreen/>
                </Router>
            </AppProvider>
        </View>
    )
}

import { Card, Collapsible, Heading, IndexTable, Modal, Scrollable, Select, SelectOption, useIndexResourceState } from "@shopify/polaris";
import React, { useState, useCallback, useMemo } from "react";
import { useClient } from "../api/useClient";

export const AccountWebhook = React.memo((
    { id }: { id: string }
) => {
    const client = useClient();

    const [tableOpen, setTableOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const handleToggleModal = useCallback(() => setModalOpen(!modalOpen), [modalOpen]);
    const allTopics = client.useAdminShopifyWebhookTopics().adminShopifyWebhookTopics.sort();
    const webhooks = client.useAdminAccountWebhooks({ accountId: id })
        .adminAccountWebhooks
        .map(i => { return { id: i.id, topic: i.topic } })
        .sort((a, b) => {
            if (a.topic < b.topic) {
                return -1
            }
            if (a.topic > b.topic) {
                return 1;
            }
            return 0;
        });
    const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(webhooks);
    const [loading, setLoading] = useState<boolean>(false);
    const options: string[] = useMemo((): string[] => {
        const existing = webhooks.map((i) => i.topic);
        const result = allTopics.filter((topic) => {
            return !existing.includes(topic);
        });        
        return result;
    }, [webhooks, allTopics]);
    const [selectedWebhook, setSelectedWebhook] = useState<string | undefined>(options[0]);

    const webhooksRows = webhooks.map((item, index) => {
        return (
            <IndexTable.Row
                id={item.id}
                key={item.id}
                selected={selectedResources.includes(item.id)}
                position={index}
            >
                <IndexTable.Cell>{item.topic}</IndexTable.Cell>
            </IndexTable.Row>
        );
    });

    const handleDeleteWebhooks = useCallback(() => {
        setLoading(true);
        console.log('Delte webhooks', selectedResources);
        client.mutateAdminAccountWebhookDelete({ accountId: id, topic: selectedResources })
            .then((result) => {
                setLoading(false);
                console.log('Webhook deleted', result);
                client.refetchAdminAccountWebhooks({ accountId: id });
            })
            .catch((e) => {
                setLoading(false);
                console.warn(e);
            })
    }, [selectedResources]);

    const handleCreateWebhooks = useCallback(() => {
        console.log('create WH', selectedWebhook);
        if (!selectedWebhook) return;
        client.mutateAdminAccountWebhookCreate({accountId: id, topic: [selectedWebhook]})
            .then((result) => {
                console.log('WH create', result); 
                client.refetchAdminAccountWebhooks({ accountId: id }); 
                handleToggleModal();
            })
            .catch(console.warn);        
    }, [selectedWebhook]);

    return (
        <>
            <Card
                title={<div onClick={() => { setTableOpen(!tableOpen) }} style={{ cursor: 'pointer' }}><Heading>Webhooks</Heading></div>}
                actions={[{
                    content: 'Create',
                    onAction: () => {
                        console.log('Create WH');
                        handleToggleModal();
                    }
                }]}
            >
                <Card.Section>
                    <Collapsible open={tableOpen} id={'AcccountWebhooksCollapsible'}>
                        <Scrollable style={{ height: '300px' }}>
                            <IndexTable
                                resourceName={{ singular: "webhook", plural: "webhooks" }}
                                itemCount={webhooks.length}
                                selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                    { title: "Topic" }
                                ]}
                                promotedBulkActions={[{ content: 'Delete', onAction: handleDeleteWebhooks }]}
                                bulkActions={[]}
                            >


                                {webhooksRows}
                            </IndexTable>
                        </Scrollable>

                    </Collapsible>
                </Card.Section>
            </Card>
            <Modal
                open={modalOpen}
                title={'Create webhook'}
                onClose={handleToggleModal}
                primaryAction={{
                    content: 'Create',
                    onAction: handleCreateWebhooks,
                    disabled: !selectedWebhook
                }}
            >
                <Modal.Section>
                    <Select
                        label={'Webhook topic'}
                        options={options}
                        value={selectedWebhook}
                        onChange={(v) => setSelectedWebhook(v)}                        
                    />
                </Modal.Section>
            </Modal>
        </>
    );
});
// Load Bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
Bugsnag.start({
    apiKey: 'e33e6ce7dc810c76dfec8ebbebb92c78',
    plugins: [new BugsnagPluginReact()]
});

// Load styles
import './polaris.css';
import '@shopify/polaris/build/esm/styles.css';
import './app.css';

// Load config
import Constants from 'expo-constants';
console.log('Loading app with manifest: ' + JSON.stringify(Constants.manifest));

// Track endpoint
import { getEndpoint } from './api/getEndpoint';
const endpoint = getEndpoint();
console.log('Endpoint: ' + endpoint);
var styles = {
  "Badge": "Polaris-Badge",
  "sizeSmall": "Polaris-Badge--sizeSmall",
  "statusSuccess": "Polaris-Badge--statusSuccess",
  "statusInfo": "Polaris-Badge--statusInfo",
  "statusAttention": "Polaris-Badge--statusAttention",
  "statusWarning": "Polaris-Badge--statusWarning",
  "statusCritical": "Polaris-Badge--statusCritical",
  "statusNew": "Polaris-Badge--statusNew",
  "Pip": "Polaris-Badge__Pip",
  "progressIncomplete": "Polaris-Badge--progressIncomplete",
  "progressPartiallyComplete": "Polaris-Badge--progressPartiallyComplete",
  "progressComplete": "Polaris-Badge--progressComplete",
  "withinFilter": "Polaris-Badge--withinFilter"
};

export { styles as default };

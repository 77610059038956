import * as React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';
import { colors } from '../assets/colors';
import { THEME_LOCALSTORAGE_KEY } from '../routes/AdminAppComponent';
import { useBoolLocalStorage } from '../utils/useBoolLocalStorage';
import { Loader } from './Loader';

const style = StyleSheet.create({
    layout: {
        position: 'relative',
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: '100%',
        marginVertical: 16,
    }
});

type Props = {
    style?: StyleProp<ViewStyle>
    debug?: boolean
}

export const Suspense = React.memo<React.PropsWithChildren<Props>>(props => {
    if (props.debug) {
        return <SuspenseLoader {...props} />
    }
    return (
        <React.Suspense fallback={<SuspenseLoader {...props} />}>
            {props.children}
        </React.Suspense>
    )
});
export const EmulatorSuspense = React.memo<React.PropsWithChildren<Props>>(props => {
    if (props.debug) {
        return <EmulatorSuspenseLoader {...props} />
    }
    return (
        <React.Suspense fallback={<SuspenseLoader {...props} />}>
            {props.children}
        </React.Suspense>
    )
});

export const SuspenseLoader = React.memo<Props>(({ style: inputStyle }) => {    
    const isDark = (localStorage.getItem(THEME_LOCALSTORAGE_KEY) || 'ligth') === 'dark';
    console.log('isDark', isDark);
    return (
        <View style={[
            { backgroundColor: isDark ? colors.darkBackground : colors.lightBackground, },
            style.layout,
            inputStyle
        ]}>
            <Loader />
        </View>
    );
});
const EmulatorSuspenseLoader = React.memo<Props>(({ style: inputStyle }) => {
    return (
        <View style={[style.layout, inputStyle, { backgroundColor: '#fff' }]}>
            <Loader />
        </View>
    );
});
var styles = {
  "CheckboxWrapper": "Polaris-ResourceItem__CheckboxWrapper",
  "ResourceItem": "Polaris-ResourceItem",
  "persistActions": "Polaris-ResourceItem--persistActions",
  "Actions": "Polaris-ResourceItem__Actions",
  "ItemWrapper": "Polaris-ResourceItem__ItemWrapper",
  "focusedInner": "Polaris-ResourceItem--focusedInner",
  "focused": "Polaris-ResourceItem--focused",
  "selected": "Polaris-ResourceItem--selected",
  "Link": "Polaris-ResourceItem__Link",
  "Button": "Polaris-ResourceItem__Button",
  "Container": "Polaris-ResourceItem__Container",
  "alignmentLeading": "Polaris-ResourceItem--alignmentLeading",
  "alignmentTrailing": "Polaris-ResourceItem--alignmentTrailing",
  "alignmentCenter": "Polaris-ResourceItem--alignmentCenter",
  "alignmentFill": "Polaris-ResourceItem--alignmentFill",
  "alignmentBaseline": "Polaris-ResourceItem--alignmentBaseline",
  "Owned": "Polaris-ResourceItem__Owned",
  "OwnedNoMedia": "Polaris-ResourceItem__OwnedNoMedia",
  "Handle": "Polaris-ResourceItem__Handle",
  "selectMode": "Polaris-ResourceItem--selectMode",
  "selectable": "Polaris-ResourceItem--selectable",
  "Media": "Polaris-ResourceItem__Media",
  "Content": "Polaris-ResourceItem__Content",
  "Disclosure": "Polaris-ResourceItem__Disclosure",
  "ListItem": "Polaris-ResourceItem__ListItem"
};

export { styles as default };

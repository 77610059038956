import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import {
    Frame,
    Layout,
    Page,
    Pagination,
    Card,
    ResourceList,
    ResourceItem,
    TextStyle,
    Stack, Select, Badge, Avatar
} from '@shopify/polaris';
import { useClient } from "../api/useClient";
import { CleanSpaceX } from "../components/types";
import { AdminAppCard_adminListAppCard, AppCardFragment, AppCardStatus } from "../api/spacex.types";
import { useRouteMatch } from "react-router";

type AdminAppCardResult = CleanSpaceX<AdminAppCard_adminListAppCard>;

export const AdminAppCardComponent = React.memo(() => {
    const client = useClient();
    const [pageSize, setPageSize] = useState<string>('10');
    const [cardStatus, setCardStatus] = useState<AppCardStatus>();
    const [results, setResults] = useState<AdminAppCardResult>();
    const [loading, setLoading] = useState<boolean>(false);
    const match = useRouteMatch();

    let nextQueryData = {
        first: parseInt(pageSize),
        after: results?.pageInfo.endCursor,
        status: cardStatus || undefined
    };

    const loadNextPage = useCallback(() => {
        console.log('Load next page', nextQueryData);
        setLoading(true);
        client.queryAdminAppCard(nextQueryData)
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.adminListAppCard);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, nextQueryData]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        const queryData = {
            last: parseInt(pageSize),
            before: results.pageInfo.startCursor,
            status: cardStatus || undefined
        };
        console.log('Load prev page', queryData);
        setLoading(true);

        client.queryAdminAppCard(queryData)
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.adminListAppCard);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, cardStatus, results]);

    const handlePublishCard = useCallback((card: AppCardFragment) => {
        setLoading(true);
        client.mutateAdminPublishAppCard({ appCardId: card.id })
            .then((result) => {
                console.log('Card published');
                setLoading(false);
                client.refetchAdminAppCard({ ...nextQueryData, after: undefined })
                    .then((result) => {
                        setResults(result.adminListAppCard);
                    });
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, nextQueryData]);

    useEffect(() => {
        loadNextPage();
    }, [pageSize, cardStatus]);

    console.log('Result', results);
    return (<Page title={"App cards"}>
        <Layout>
            <Layout.Section>
                <Card>
                    <Card.Section>
                        <ResourceList
                            items={results?.edges || []}
                            loading={loading}
                            filterControl={(
                                <Stack distribution={"leading"}>
                                    <Stack.Item fill>
                                        <Stack distribution={"leading"}>
                                            <Stack.Item>
                                                <Select
                                                    label={"Status"}
                                                    value={cardStatus}
                                                    labelInline
                                                    options={[
                                                        { label: 'All', value: '' },
                                                        { label: 'Active', value: AppCardStatus.ACTIVE },
                                                        { label: 'On review', value: AppCardStatus.ON_REVIEW },
                                                        { label: 'Draft', value: AppCardStatus.DRAFT },
                                                        { label: 'Inactive', value: AppCardStatus.INACTIVE },
                                                        { label: 'Blocked', value: AppCardStatus.BLOCKED },
                                                    ]}
                                                    onChange={(value) => {
                                                        setResults(undefined);
                                                        setCardStatus(value as AppCardStatus || undefined);
                                                    }}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Select
                                            label={"Page size"}
                                            labelInline
                                            value={pageSize}
                                            options={[
                                                { label: '5', value: '5' },
                                                { label: '10', value: '10' },
                                                { label: '30', value: '30' }
                                            ]}
                                            onChange={(value) => {
                                                setResults(undefined);
                                                setPageSize(value);
                                            }}
                                        />
                                    </Stack.Item>
                                </Stack>
                            )}
                            renderItem={(item) => {
                                const node = item.node;
                                let statusBadge;
                                if (node.status === AppCardStatus.ACTIVE) {
                                    statusBadge = (<Badge status={"success"}>Active</Badge>);
                                } else if (node.status === AppCardStatus.DRAFT) {
                                    statusBadge = (<Badge status={"info"}>Draft</Badge>);
                                } else if (node.status === AppCardStatus.ON_REVIEW) {
                                    statusBadge = (<Badge status={"attention"}>On review</Badge>);
                                } else {
                                    statusBadge = (<Badge status={"critical"}>Inactive</Badge>);
                                }
                                return (
                                    <ResourceItem
                                        id={node.id}
                                        media={<Avatar size={"medium"} name={node.title} source={node.icon?.src || undefined} />}
                                        onClick={(it) => {
                                            console.log('item clicked', it);
                                        }}
                                        url={`${match.url}/${node.id}/edit`}
                                        shortcutActions={[
                                            { content: 'Publish', onAction: () => { handlePublishCard(node) } }
                                        ]}
                                    >
                                        <h3>
                                            <TextStyle>{node.title}</TextStyle>
                                        </h3>
                                        <View>
                                            <Stack>
                                                <Stack.Item>{statusBadge}</Stack.Item>
                                            </Stack>
                                        </View>
                                    </ResourceItem>
                                );
                            }} />
                    </Card.Section>
                    {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                        <Stack distribution={"center"}>
                            <Pagination
                                hasPrevious={results?.pageInfo.hasPreviousPage}
                                onPrevious={() => {
                                    loadPrevPage()
                                }}
                                hasNext={results?.pageInfo.hasNextPage}
                                onNext={() => {
                                    loadNextPage()
                                }}
                            />
                        </Stack>
                    </Card.Section>}
                </Card>
            </Layout.Section>
        </Layout>
    </Page>);
});
import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
    Card,
    Heading,
    Layout,
    Link,
    Page,
    Stack,
    TextStyle
} from '@shopify/polaris';
import { useClient } from "../api/useClient";
import { useRouteMatch } from "react-router";
import {
    AdminGetAccount_adminAccount
} from "../api/spacex.types";
import moment from "moment";
import { getAccountStatusBadge } from "../utils/helpers";
import { CleanSpaceX } from "../components/types";
import { AccountMonitoringEvents } from "../components/AccountMonitoringEvents";
import { AccountSubscription } from "../components/AccountSubscription";
import { AppStats } from "../components/AppStats";
import { AccountCatalog } from "../components/AccountCatalog";
import { AccountWebhook } from "../components/AccountWebhook";
import QRCode from "qrcode.react";
import { PartnerEditForm } from "../components/partners/PartnerEditForm";
import { View } from "react-native";

type Account = CleanSpaceX<AdminGetAccount_adminAccount>;

export const AccountEditComponent = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ accountId: string }>();
    const { accountId } = match.params;
    console.log('Account id', accountId);
    const [account, setAccount] = useState<Account>(client.useAdminGetAccount({ accountId }).adminAccount);
    console.log('Account', account);
    let accountStatusBadge = getAccountStatusBadge(account);

    const app = client.useAdminAccountApp({ accountId: accountId }).adminAccountApp;
    const shareLink = client.useGenerateSharLink({ appId: app.id }).generateShareLink;

    const now = new Date();

    const handleCancelSubscription = useCallback(() => {
    }, [account]);

    const handleActivateSubscription = useCallback(() => {
    }, [account]);

    const handleChangeTrialStatus = useCallback(() => {
    }, [account]);

    useEffect(() => {
        const updateAccountInterval = setInterval(() => {
            client.refetchAdminGetAccount({ accountId });
            console.log('Acc refetched');
        }, 1000);
        return () => {
            clearInterval(updateAccountInterval);
        }
    }, []);

    console.log(account.survey);

    let survey: {
        industries: string[];
        sellingStatus: string | null;
        currentMonthlySales: string | null;
        teamSize?: string | null;
        forClient: boolean;
        referrer?: string | null;
    } = useMemo(() => {
        return JSON.parse(account.survey || '{}');
    }, [account.survey]);

    let sellingStatus = useMemo(() => {
        switch (survey.sellingStatus) {
            case '1':
                return 'Online only';
            case '2':
                return 'Offline & Online';
            default:
                return 'Skipped';
        }
    }, [survey]);

    let currentMonthlySales = useMemo(() => {
        switch (survey.currentMonthlySales) {
            case '1':
                return '🚀 US $0 (I\'m just getting started)';
            case '2':
                return 'Up to US $1000';
            case '3':
                return 'US $1,000 to US $10,000';
            case '4':
                return 'US $10,000 to US $100,000';
            case '5':
                return 'US $1,000,000+';
            default:
                return 'Skipped';
        }
    }, [survey]);

    let teamSize = useMemo(() => {
        switch (survey.teamSize) {
            case '1':
                return 'Team: 1-5 people';
            case '2':
                return 'Organization: 5-10 people';
            case '3':
                return 'Enterprise: over 10 people';
            default:
                return 'Skipped';
        }
    }, [survey]);
    
    let referrer = useMemo(() => {
    //     { value: 'fb', label: 'Facebook' },
    // { value: 'shp', label: 'Shopify' },
    // { value: 'insta', label: 'Instagram' },
    // { value: 'tw', label: 'Twitter' },
    // { value: 'rd', label: 'Reddit' },
    // { value: 'ggl', label: 'Google' },
    // { value: 'friends', label: 'Friends' },
    // { value: 'colleagues', label: 'Colleagues' },
    // { value: 'other', label: 'Other' },
        switch (survey.referrer) {
            case 'fb':
                return 'Facebook';
            case 'shp':
                return 'Shopify';
            case 'insta':
                return 'Instagram';
            case 'tw':
                return 'Twitter';
            case 'rd':
                return 'Reddit';
            case 'ggl':
                return 'Google';
            case 'friends':
                return 'Friends';
            case 'colleagues':
                return 'Colleagues';
            case 'other':
                return 'Other';
            default:
                return 'Skipped';
        }
    }, [survey]);


    return (
        <Page
            title={"Account: " + account.name}
            breadcrumbs={[{ content: "Accounts", url: '/accounts' }]}
        >
            <Layout>
                <Layout.Section>
                    <Card
                        title={<Heading>Info {accountStatusBadge}</Heading>}
                    >
                        <Card.Section>
                            <Stack>
                                <Stack.Item fill>
                                    <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                        <Stack.Item>
                                            <TextStyle variation={"strong"}>Domain: </TextStyle>
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <Link
                                                external
                                                url={`${account.domain}`}
                                                onClick={() => {
                                                    window.open(`https://${account.domain}`);
                                                }}
                                            >
                                                {account.domain}
                                            </Link>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                        <Stack.Item>
                                            <TextStyle variation={"strong"}>Share link: </TextStyle>
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <Link
                                                onClick={() => {
                                                    window.open(`https://${shareLink}`);
                                                }}
                                                external
                                                url={`${shareLink}`}
                                            >
                                                {shareLink}
                                            </Link>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <View style={{
                                    height: 170,
                                    width: 170,
                                    padding: 20,
                                    backgroundColor: 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <QRCode
                                        value={shareLink}
                                        size={150}
                                        bgColor={'#f6f7f8'}
                                        fgColor={'#000'}
                                    />
                                </View>
                            </Stack>
                        </Card.Section>
                        <Card.Section title={"Dates"}>
                            <Stack alignment={"leading"} distribution={"leading"} spacing={"tight"}
                                wrap={false}>
                                <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                    <Stack.Item>
                                        <TextStyle variation={"strong"}>Created at</TextStyle>
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <p>{moment(parseInt(account.createdAt)).format('LL')}</p>
                                    </Stack.Item>
                                </Stack>
                                <Stack alignment={"center"} distribution={"leading"} spacing={"tight"} wrap={false}>
                                    <Stack.Item>
                                        <TextStyle variation={"strong"}>Updated at</TextStyle>
                                    </Stack.Item>
                                    <Stack.Item fill>
                                        <p>{moment(parseInt(account.updatedAt)).format('LL')}</p>
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </Card.Section>
                        <Card.Section>
                            <Stack vertical>
                                <Stack>
                                    <TextStyle>
                                        {/* {`Industries: ${survey.industries.join(', ')}`} */}
                                    </TextStyle>
                                    <TextStyle>
                                        {`Selling status: ${sellingStatus}`}
                                    </TextStyle>
                                    <TextStyle>
                                        {`Monthly Sales: ${currentMonthlySales}`}
                                    </TextStyle>
                                </Stack>
                                <Stack>
                                    <TextStyle>
                                        {`Team size: ${teamSize}`}
                                    </TextStyle>
                                    <TextStyle>
                                        {`For a client: ${survey.forClient}`}
                                    </TextStyle>
                                    <TextStyle>
                                        {`Referrer: ${referrer}`}
                                    </TextStyle>
                                </Stack>
                            </Stack>
                        </Card.Section>
                        <Card.Section title={"Stats"}>
                            <AppStats accountId={accountId} />
                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <AccountCatalog id={accountId} />
                </Layout.Section>
                <Layout.Section>
                    <AccountSubscription id={accountId} />
                </Layout.Section>
                {account.partner && <Layout.Section>
                    <Card>
                        <Card.Section>
                            <PartnerEditForm
                                initValue={{
                                    title: account.partner.title,
                                    slug: account.partner.slug,
                                    payoutPercent: account.partner.payoutPercent,
                                    email: account.partner.email,
                                    notes: account.partner.notes || ''
                                }}
                                onChange={() => { }}
                                disabled
                            />
                        </Card.Section>
                    </Card>
                </Layout.Section>}
                <Layout.Section>
                    <AccountWebhook id={accountId} />
                </Layout.Section>
                <Layout.Section>
                    <AccountMonitoringEvents id={accountId} />
                </Layout.Section>
            </Layout>

        </Page>
    );
});
var styles = {
  "ActionList": "Polaris-ActionList",
  "Section-withoutTitle": "Polaris-ActionList__Section--withoutTitle",
  "Actions": "Polaris-ActionList__Actions",
  "Section": "Polaris-ActionList__Section",
  "Title": "Polaris-ActionList__Title",
  "firstSectionWithTitle": "Polaris-ActionList--firstSectionWithTitle",
  "Item": "Polaris-ActionList__Item",
  "active": "Polaris-ActionList--active",
  "destructive": "Polaris-ActionList--destructive",
  "disabled": "Polaris-ActionList--disabled",
  "Prefix": "Polaris-ActionList__Prefix",
  "Suffix": "Polaris-ActionList__Suffix",
  "Content": "Polaris-ActionList__Content",
  "ContentBlock": "Polaris-ActionList__ContentBlock",
  "ContentBlockInner": "Polaris-ActionList__ContentBlockInner",
  "Text": "Polaris-ActionList__Text"
};

export { styles as default };

var styles = {
  "Stack": "Polaris-Stack",
  "Item": "Polaris-Stack__Item",
  "noWrap": "Polaris-Stack--noWrap",
  "spacingNone": "Polaris-Stack--spacingNone",
  "spacingExtraTight": "Polaris-Stack--spacingExtraTight",
  "spacingTight": "Polaris-Stack--spacingTight",
  "spacingBaseTight": "Polaris-Stack--spacingBaseTight",
  "spacingLoose": "Polaris-Stack--spacingLoose",
  "spacingExtraLoose": "Polaris-Stack--spacingExtraLoose",
  "distributionLeading": "Polaris-Stack--distributionLeading",
  "distributionTrailing": "Polaris-Stack--distributionTrailing",
  "distributionCenter": "Polaris-Stack--distributionCenter",
  "distributionEqualSpacing": "Polaris-Stack--distributionEqualSpacing",
  "distributionFill": "Polaris-Stack--distributionFill",
  "distributionFillEvenly": "Polaris-Stack--distributionFillEvenly",
  "alignmentLeading": "Polaris-Stack--alignmentLeading",
  "alignmentTrailing": "Polaris-Stack--alignmentTrailing",
  "alignmentCenter": "Polaris-Stack--alignmentCenter",
  "alignmentFill": "Polaris-Stack--alignmentFill",
  "alignmentBaseline": "Polaris-Stack--alignmentBaseline",
  "vertical": "Polaris-Stack--vertical",
  "Item-fill": "Polaris-Stack__Item--fill"
};

export { styles as default };

import { Badge, IndexTable, Stack, TextStyle } from "@shopify/polaris";
import React from "react"
import { View } from "react-native"
import { useClient } from "../api/useClient";

export const AppStats = React.memo(({ accountId }: { accountId: string }) => {
    const client = useClient();
    const app = client.useAdminAccountApp({ accountId: accountId }).adminAccountApp;
    const appStat = client.useAdminAppStat({ appId: app.id }).adminAppStat;

    return (
        <Stack vertical>
            <Stack vertical>
                <Stack>
                    <TextStyle variation={'strong'}>
                        {'Smart banner'}
                    </TextStyle>
                    <Badge status={app.settings?.banner?.scriptTagId ? "success" : 'warning'}>
                        {app.settings?.banner?.scriptTagId ? 'enabled' : 'disabled'}
                    </Badge>
                </Stack>
                <IndexTable
                    selectable={false}
                    headings={[
                        { title: 'Smart banner impressions' },
                        { title: 'Smart banner mobile impressions' },
                        { title: 'Smart banner clicks' },
                        { title: 'Clickthrough rate' }
                    ]}
                    itemCount={1}
                >
                    <IndexTable.Row
                        id={`app-${app.id}-stat`}
                        key={`app-${app.id}-stat`}
                        position={0}
                    >
                        <IndexTable.Cell>{app.bannerImpressions}</IndexTable.Cell>
                        <IndexTable.Cell>{app.bannerMobileImpressions}</IndexTable.Cell>
                        <IndexTable.Cell>{app.bannerClicks}</IndexTable.Cell>
                        <IndexTable.Cell>{`${Number(((app.bannerClicks / app.bannerImpressions) * 100) || 0).toFixed(1)}%`}</IndexTable.Cell>
                    </IndexTable.Row>
                </IndexTable>
            </Stack>
            <IndexTable
                selectable={false}
                headings={[
                    { title: 'Store visits' },
                    { title: 'Installs' },
                    { title: 'Orders count' },
                    { title: 'Orders sum' },
                ]}
                itemCount={1}
            >
                <IndexTable.Row
                    id={`app-${app.id}-stat`}
                    key={`app-${app.id}-stat`}
                    position={0}
                >
                    <IndexTable.Cell>{appStat.visits}</IndexTable.Cell>
                    <IndexTable.Cell>{appStat.installs}</IndexTable.Cell>
                    <IndexTable.Cell>{appStat.orders}</IndexTable.Cell>
                    <IndexTable.Cell>$ {appStat.ordersSum}</IndexTable.Cell>
                </IndexTable.Row>
            </IndexTable>
        </Stack>
    );
});
import { Form, FormLayout, Link, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useClient } from "../../api/useClient";

export type PartnerForm = {
    title: string,
    slug: string,
    payoutPercent: number,
    email: string,
    notes: string,
};

export const PartnerEditForm = React.memo(({
    initValue,
    disabled,
    onChange,
    disableSlug
}: {
    initValue?: PartnerForm,
    disabled?: boolean,
    onChange: (value: PartnerForm) => void,
    disableSlug?: boolean,
}) => {
    const [formState, setFormState] = useState<PartnerForm>(initValue || {
        title: '', slug: '', payoutPercent: 0.0, email: '', notes: ''
    });

    useEffect(() => {
        if (initValue) {
            setFormState(initValue);
        }
    }, [initValue]);

    const onFormFieldChange = useCallback((field: keyof PartnerForm, value: any) => {
        setFormState((prevValue) => {
            const newValue = { ...prevValue, ...{ [field]: value } }
            onChange(newValue);
            return newValue;
        });
    }, [setFormState, onChange]);

    const handleFormSubmit = useCallback((event) => {

    }, []);

    return (
        <Form onSubmit={handleFormSubmit}>
            <FormLayout>
                <TextField
                    label={'Name'}
                    placeholder={'Name'}
                    value={formState.title}
                    onChange={(value) => onFormFieldChange('title', value)}
                    autoComplete={'off'}
                    disabled={disabled}
                />
                <TextField
                    label={'Slug'}
                    placeholder={'Slug'}
                    value={formState.slug}
                    onChange={(value) => onFormFieldChange('slug', value)}
                    autoComplete={'off'}
                    disabled={disabled || disableSlug}
                />
                <TextField
                    label={'Email'}
                    value={formState.email}
                    onChange={(value) => onFormFieldChange('email', value)}
                    placeholder={'partner@partner-email.com'}
                    type={'email'}
                    inputMode={'email'}
                    autoComplete={'email'}
                    disabled={disabled}
                />
                <TextField
                    label={'Payout percentage'}
                    value={String(formState.payoutPercent)}
                    onChange={(value) => onFormFieldChange('payoutPercent', parseFloat(value))}
                    type={'number'}
                    step={0.1}
                    autoComplete={'off'}
                    suffix={'%'}
                    disabled={disabled}
                />
                <TextField
                    label={'Notes'}
                    value={formState.notes}
                    onChange={(value) => onFormFieldChange('notes', value)}
                    placeholder={'Usefull notes, like other contacts etc.'}
                    multiline={4}
                    autoComplete={'off'}
                    disabled={disabled}
                />
            </FormLayout>
        </Form>
    );
});
import { useContext } from 'react';
import { MediaQueryContext } from './context.js';

function useMediaQuery() {
  const mediaQuery = useContext(MediaQueryContext);

  if (!mediaQuery) {
    throw new Error('No mediaQuery was provided. Your application must be wrapped in an <AppProvider> component. See https://polaris.shopify.com/components/structure/app-provider for implementation instructions.');
  }

  return mediaQuery;
}

export { useMediaQuery };

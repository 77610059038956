import React from 'react';
import {Route} from "../routing/routing";
import { Redirect, RouteProps } from 'react-router';
import {useAdminAuthContext} from "./AdminAuthLoader";

export const AdminProtectedRoute = React.memo<RouteProps>(({component, ...props}) => {
    const {isAuthed} = useAdminAuthContext();

    return <Route {...props} render={props => {
        if (isAuthed) {
            return <Route {...props} component={component}/>
        } else {
            return <Redirect to={{pathname: '/login', search: props.location.search, state: {from: props.location}}}/>
        }
    }} />
})

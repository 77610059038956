import React, { useCallback, useState } from "react";
import { Form, TextField, Card } from '@shopify/polaris';
import { View } from "react-native";
import { useAdminAuthContext } from "../auth/AdminAuthLoader";
import { getEndpoint } from "../api/getEndpoint";
import { useHistory } from "react-router";


export const AdminLoginComponent = React.memo(() => {
    const [login, setLogin] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const auth = useAdminAuthContext();
    const history = useHistory();
    const loginRequest = useCallback(() => {
        fetch(getEndpoint() + '/shopify/auth/admin', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ login, password })
        })
            .then(res => res.json())
            .then((answer) => {
                if (answer.token) {
                    auth.auth(answer.token, answer.expireAt, true);
                }
                return answer;
            });
    }, [login, password]);
    return (<View style={{
        flex: 1,
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Card
            primaryFooterAction={{
                content: "Login",
                onAction: loginRequest
            }}
            secondaryFooterActions={[
                { content: 'Register', onAction: () => { history.push({ pathname: '/register' }) } }
            ]}
        >
            <Card.Section title={"Login form"}>
                <Form onSubmit={() => { loginRequest() }} preventDefault>
                    <TextField type={"email"} value={login}
                        onChange={(value) => setLogin(value)}
                        label={"Email"} autoComplete={'on'} />
                    <TextField type={"password"} value={password}
                        onChange={(value) => setPassword(value)}
                        label={"Password"} autoComplete={'off'} />
                </Form>
            </Card.Section>
        </Card>
    </View>);
});
import { Card, Labelled, ResourceItem, TextStyle } from "@shopify/polaris"
import React from "react"

export const PartnerPayout = React.memo(({
    id,
    // status,
    // total,
}: {
    id: string,
    // status: PartnerPayoutStatus
    // total: number
}) => {

    return (
        <ResourceItem 
        id={id}
        url={`/partner_payouts/${id}`}
        >
            <TextStyle>
                {id}
            </TextStyle>
        </ResourceItem>
    );
});
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountWrapper
// ====================================================

export interface AccountWrapper_account_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AccountWrapper_account_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountWrapper_account_pricing_pricing | null;
}

export interface AccountWrapper_account_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AccountWrapper_account {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AccountWrapper_account_pricing;
  domain: string | null;
  partner: AccountWrapper_account_partner | null;
}

export interface AccountWrapper {
  account: AccountWrapper_account;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAccount
// ====================================================

export interface CreateAccount_accountCreate_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface CreateAccount_accountCreate_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: CreateAccount_accountCreate_pricing_pricing | null;
}

export interface CreateAccount_accountCreate_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface CreateAccount_accountCreate {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: CreateAccount_accountCreate_pricing;
  domain: string | null;
  partner: CreateAccount_accountCreate_partner | null;
}

export interface CreateAccount {
  accountCreate: CreateAccount_accountCreate;
}

export interface CreateAccountVariables {
  id: string;
  inputs: AccountCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AccountReset
// ====================================================

export interface AccountReset_accountReset_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AccountReset_accountReset_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountReset_accountReset_pricing_pricing | null;
}

export interface AccountReset_accountReset_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AccountReset_accountReset {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AccountReset_accountReset_pricing;
  domain: string | null;
  partner: AccountReset_accountReset_partner | null;
}

export interface AccountReset {
  accountReset: AccountReset_accountReset;
}

export interface AccountResetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetupAccount
// ====================================================

export interface SetupAccount_setupAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface SetupAccount_setupAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: SetupAccount_setupAccount_pricing_pricing | null;
}

export interface SetupAccount_setupAccount_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface SetupAccount_setupAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: SetupAccount_setupAccount_pricing;
  domain: string | null;
  partner: SetupAccount_setupAccount_partner | null;
}

export interface SetupAccount {
  setupAccount: SetupAccount_setupAccount;
}

export interface SetupAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminListPricing
// ====================================================

export interface AdminListPricing_adminListPricing_edges_node {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

export interface AdminListPricing_adminListPricing_edges {
  __typename: "PricingEdge";
  node: AdminListPricing_adminListPricing_edges_node;
  cursor: string;
}

export interface AdminListPricing_adminListPricing_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface AdminListPricing_adminListPricing {
  __typename: "PricingConnection";
  edges: AdminListPricing_adminListPricing_edges[];
  pageInfo: AdminListPricing_adminListPricing_pageInfo;
}

export interface AdminListPricing {
  adminListPricing: AdminListPricing_adminListPricing;
}

export interface AdminListPricingVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAppCard
// ====================================================

export interface AdminAppCard_adminListAppCard_edges_node_icon {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_splash {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_background {
  __typename: "Image";
  src: string;
}

export interface AdminAppCard_adminListAppCard_edges_node_app {
  __typename: "App";
  id: string;
}

export interface AdminAppCard_adminListAppCard_edges_node {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AdminAppCard_adminListAppCard_edges_node_icon | null;
  splash: AdminAppCard_adminListAppCard_edges_node_splash | null;
  background: AdminAppCard_adminListAppCard_edges_node_background | null;
  createdAt: string;
  app: AdminAppCard_adminListAppCard_edges_node_app;
}

export interface AdminAppCard_adminListAppCard_edges {
  __typename: "AppCardEdge";
  node: AdminAppCard_adminListAppCard_edges_node;
  cursor: string;
}

export interface AdminAppCard_adminListAppCard_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAppCard_adminListAppCard {
  __typename: "AppCardConnection";
  edges: AdminAppCard_adminListAppCard_edges[];
  pageInfo: AdminAppCard_adminListAppCard_pageInfo;
}

export interface AdminAppCard {
  adminListAppCard: AdminAppCard_adminListAppCard;
}

export interface AdminAppCardVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: AppCardStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountMonitoringList
// ====================================================

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_shopify;
  database: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_database;
  index: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters_index;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data_catalogCounters;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node_data;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_edges {
  __typename: "AdminAccountMonitoringEventEdge";
  node: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges_node;
  cursor: string;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAccountMonitoringList_adminAccountMonitoringEvents {
  __typename: "AdminAccountMonitoringEventConnection";
  edges: AdminAccountMonitoringList_adminAccountMonitoringEvents_edges[];
  pageInfo: AdminAccountMonitoringList_adminAccountMonitoringEvents_pageInfo;
}

export interface AdminAccountMonitoringList {
  adminAccountMonitoringEvents: AdminAccountMonitoringList_adminAccountMonitoringEvents;
}

export interface AdminAccountMonitoringListVariables {
  accountId: string;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  status?: AccountMonitoringStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUser
// ====================================================

export interface AdminUser_adminUser {
  __typename: "AdminUser";
  id: string;
  email: string;
  role: AdminUserRole;
}

export interface AdminUser {
  adminUser: AdminUser_adminUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdatePricing
// ====================================================

export interface AdminUpdatePricing_adminUpdatePricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

export interface AdminUpdatePricing {
  adminUpdatePricing: AdminUpdatePricing_adminUpdatePricing;
}

export interface AdminUpdatePricingVariables {
  input: PricingInput;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountsList
// ====================================================

export interface AdminAccountsList_adminListAccount_edges_node_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminAccountsList_adminListAccount_edges_node_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminAccountsList_adminListAccount_edges_node_pricing_pricing | null;
}

export interface AdminAccountsList_adminListAccount_edges_node_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_shopify;
  database: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_database;
  index: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters_index;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data_catalogCounters;
}

export interface AdminAccountsList_adminListAccount_edges_node_monitoringStatus {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccountsList_adminListAccount_edges_node_monitoringStatus_data;
}

export interface AdminAccountsList_adminListAccount_edges_node {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminAccountsList_adminListAccount_edges_node_pricing;
  domain: string | null;
  partner: AdminAccountsList_adminListAccount_edges_node_partner | null;
  monitoringStatus: AdminAccountsList_adminListAccount_edges_node_monitoringStatus | null;
}

export interface AdminAccountsList_adminListAccount_edges {
  __typename: "AccountEdge";
  node: AdminAccountsList_adminListAccount_edges_node;
  cursor: string;
}

export interface AdminAccountsList_adminListAccount_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminAccountsList_adminListAccount {
  __typename: "AccountConnection";
  edges: AdminAccountsList_adminListAccount_edges[];
  pageInfo: AdminAccountsList_adminListAccount_pageInfo;
}

export interface AdminAccountsList {
  adminListAccount: AdminAccountsList_adminListAccount;
}

export interface AdminAccountsListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  isTest?: boolean | null;
  status?: AccountStatus | null;
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminGenerateAccountToken
// ====================================================

export interface AdminGenerateAccountToken {
  adminGenerateAccountToken: string;
}

export interface AdminGenerateAccountTokenVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetAccount
// ====================================================

export interface AdminGetAccount_adminAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminGetAccount_adminAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminGetAccount_adminAccount_pricing_pricing | null;
}

export interface AdminGetAccount_adminAccount_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_shopify;
  database: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_database;
  index: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters_index;
}

export interface AdminGetAccount_adminAccount_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminGetAccount_adminAccount_monitoringStatus_data_catalogCounters;
}

export interface AdminGetAccount_adminAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminGetAccount_adminAccount_monitoringStatus_data;
}

export interface AdminGetAccount_adminAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminGetAccount_adminAccount_pricing;
  domain: string | null;
  partner: AdminGetAccount_adminAccount_partner | null;
  monitoringStatus: AdminGetAccount_adminAccount_monitoringStatus | null;
}

export interface AdminGetAccount {
  adminAccount: AdminGetAccount_adminAccount;
}

export interface AdminGetAccountVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetAppCard
// ====================================================

export interface AdminGetAppCard_adminAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_background {
  __typename: "Image";
  src: string;
}

export interface AdminGetAppCard_adminAppCard_app {
  __typename: "App";
  id: string;
}

export interface AdminGetAppCard_adminAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AdminGetAppCard_adminAppCard_icon | null;
  splash: AdminGetAppCard_adminAppCard_splash | null;
  background: AdminGetAppCard_adminAppCard_background | null;
  createdAt: string;
  app: AdminGetAppCard_adminAppCard_app;
}

export interface AdminGetAppCard {
  adminAppCard: AdminGetAppCard_adminAppCard;
}

export interface AdminGetAppCardVariables {
  appCardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminPublishAppCard
// ====================================================

export interface AdminPublishAppCard_adminPublishAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_background {
  __typename: "Image";
  src: string;
}

export interface AdminPublishAppCard_adminPublishAppCard_app {
  __typename: "App";
  id: string;
}

export interface AdminPublishAppCard_adminPublishAppCard {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: AdminPublishAppCard_adminPublishAppCard_icon | null;
  splash: AdminPublishAppCard_adminPublishAppCard_splash | null;
  background: AdminPublishAppCard_adminPublishAppCard_background | null;
  createdAt: string;
  app: AdminPublishAppCard_adminPublishAppCard_app;
}

export interface AdminPublishAppCard {
  adminPublishAppCard: AdminPublishAppCard_adminPublishAppCard;
}

export interface AdminPublishAppCardVariables {
  appCardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminRevokeAppCard
// ====================================================

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors_light;
  dark: AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors_dark;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design {
  __typename: "AppDesign";
  colors: AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design_colors;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig_design;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings_banner_params | null;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings {
  __typename: "AppSettings";
  banner: AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings_banner | null;
  logo: string | null;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface AdminRevokeAppCard_adminDeactivatePublischedAppCard {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: AdminRevokeAppCard_adminDeactivatePublischedAppCard_draftConfig;
  settings: AdminRevokeAppCard_adminDeactivatePublischedAppCard_settings | null;
  freeTrafficActive: boolean;
  card: AdminRevokeAppCard_adminDeactivatePublischedAppCard_card;
  publishedCard: AdminRevokeAppCard_adminDeactivatePublischedAppCard_publishedCard | null;
}

export interface AdminRevokeAppCard {
  adminDeactivatePublischedAppCard: AdminRevokeAppCard_adminDeactivatePublischedAppCard;
}

export interface AdminRevokeAppCardVariables {
  appCardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminMakeAccountTrial
// ====================================================

export interface AdminMakeAccountTrial_adminMakeAccountTrial_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminMakeAccountTrial_adminMakeAccountTrial_pricing_pricing | null;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_shopify;
  database: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_database;
  index: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters_index;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data_catalogCounters;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus_data;
}

export interface AdminMakeAccountTrial_adminMakeAccountTrial {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminMakeAccountTrial_adminMakeAccountTrial_pricing;
  domain: string | null;
  partner: AdminMakeAccountTrial_adminMakeAccountTrial_partner | null;
  monitoringStatus: AdminMakeAccountTrial_adminMakeAccountTrial_monitoringStatus | null;
}

export interface AdminMakeAccountTrial {
  adminMakeAccountTrial: AdminMakeAccountTrial_adminMakeAccountTrial;
}

export interface AdminMakeAccountTrialVariables {
  accountId: string;
  input: AdminMakeTrialInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateSurveyIndustry
// ====================================================

export interface AdminUpdateSurveyIndustry_adminUpdateSurveyIndustry {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

export interface AdminUpdateSurveyIndustry {
  adminUpdateSurveyIndustry: AdminUpdateSurveyIndustry_adminUpdateSurveyIndustry;
}

export interface AdminUpdateSurveyIndustryVariables {
  industryId?: string | null;
  input: AdminAddSurveyIndustryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminSurveyIndustryList
// ====================================================

export interface AdminSurveyIndustryList_surveyIndustryList {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

export interface AdminSurveyIndustryList {
  surveyIndustryList: AdminSurveyIndustryList_surveyIndustryList[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetSurveyIndustry
// ====================================================

export interface AdminGetSurveyIndustry_surveyIndustry {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

export interface AdminGetSurveyIndustry {
  surveyIndustry: AdminGetSurveyIndustry_surveyIndustry;
}

export interface AdminGetSurveyIndustryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminDeleteSurveyIndustry
// ====================================================

export interface AdminDeleteSurveyIndustry {
  adminDeleteSurveyIndustry: boolean;
}

export interface AdminDeleteSurveyIndustryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminRemovePricing
// ====================================================

export interface AdminRemovePricing {
  adminRemovePricing: boolean;
}

export interface AdminRemovePricingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PricingCapabilities
// ====================================================

export interface PricingCapabilities {
  adminPricingCapabilities: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdatePricingAccount
// ====================================================

export interface AdminUpdatePricingAccount {
  adminUpdatePricingAccounts: boolean;
}

export interface AdminUpdatePricingAccountVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminAccountSetPricing
// ====================================================

export interface AdminAccountSetPricing_adminAccountSetPricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminAccountSetPricing_adminAccountSetPricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminAccountSetPricing_adminAccountSetPricing_pricing | null;
}

export interface AdminAccountSetPricing {
  adminAccountSetPricing: AdminAccountSetPricing_adminAccountSetPricing;
}

export interface AdminAccountSetPricingVariables {
  account: string;
  pricingId: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountApp
// ====================================================

export interface AdminAccountApp_adminAccountApp_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
}

export interface AdminAccountApp_adminAccountApp_settings {
  __typename: "AppSettings";
  banner: AdminAccountApp_adminAccountApp_settings_banner | null;
}

export interface AdminAccountApp_adminAccountApp {
  __typename: "App";
  id: string;
  name: string;
  bannerImpressions: number;
  bannerMobileImpressions: number;
  bannerClicks: number;
  settings: AdminAccountApp_adminAccountApp_settings | null;
}

export interface AdminAccountApp {
  adminAccountApp: AdminAccountApp_adminAccountApp;
}

export interface AdminAccountAppVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminForceSync
// ====================================================

export interface AdminForceSync_adminShopRestartSync {
  __typename: "SyncState";
  id: string;
  status: SyncStatus;
}

export interface AdminForceSync {
  adminShopRestartSync: AdminForceSync_adminShopRestartSync;
}

export interface AdminForceSyncVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountSyncState
// ====================================================

export interface AdminAccountSyncState_adminAccountSyncState {
  __typename: "SyncState";
  id: string;
  status: SyncStatus;
}

export interface AdminAccountSyncState {
  adminAccountSyncState: AdminAccountSyncState_adminAccountSyncState;
}

export interface AdminAccountSyncStateVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminShopifyWebhookTopics
// ====================================================

export interface AdminShopifyWebhookTopics {
  adminShopifyWebhookTopics: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountWebhooks
// ====================================================

export interface AdminAccountWebhooks_adminAccountWebhooks {
  __typename: "AccountWebhook";
  id: string;
  topic: string;
}

export interface AdminAccountWebhooks {
  adminAccountWebhooks: AdminAccountWebhooks_adminAccountWebhooks[];
}

export interface AdminAccountWebhooksVariables {
  accountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminAccountWebhookCreate
// ====================================================

export interface AdminAccountWebhookCreate_adminAccountWebhookCreate {
  __typename: "AccountWebhook";
  id: string;
  topic: string;
}

export interface AdminAccountWebhookCreate {
  adminAccountWebhookCreate: AdminAccountWebhookCreate_adminAccountWebhookCreate[];
}

export interface AdminAccountWebhookCreateVariables {
  accountId: string;
  topic: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminAccountWebhookDelete
// ====================================================

export interface AdminAccountWebhookDelete {
  adminAccountWebhookDelete: boolean;
}

export interface AdminAccountWebhookDeleteVariables {
  accountId: string;
  topic: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAppStat
// ====================================================

export interface AdminAppStat_adminAppStat {
  __typename: "AccountStatistic";
  visits: number;
  orders: number;
  installs: number;
  ordersSum: number;
}

export interface AdminAppStat {
  adminAppStat: AdminAppStat_adminAppStat;
}

export interface AdminAppStatVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminAccountsStatistics
// ====================================================

export interface AdminAccountsStatistics_adminAccountsStatistics_ordersSum {
  __typename: "AccountOrdersSum";
  currencyCode: string;
  sum: number;
}

export interface AdminAccountsStatistics_adminAccountsStatistics {
  __typename: "AccountsStatistics";
  accounts: number;
  accountsWithBanner: number;
  installs: number;
  visits: number;
  bannerClicks: number;
  bannerImpressions: number;
  bannerMobileImpressions: number;
  clickThroughRate: number;
  ordersCount: number;
  ordersSum: AdminAccountsStatistics_adminAccountsStatistics_ordersSum[];
}

export interface AdminAccountsStatistics {
  adminAccountsStatistics: AdminAccountsStatistics_adminAccountsStatistics;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminPartnersList
// ====================================================

export interface AdminPartnersList_adminListPartner_edges_node {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminPartnersList_adminListPartner_edges {
  __typename: "PartnerConnectionEdge";
  node: AdminPartnersList_adminListPartner_edges_node;
  cursor: string;
}

export interface AdminPartnersList_adminListPartner_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminPartnersList_adminListPartner {
  __typename: "PartnerConnection";
  edges: AdminPartnersList_adminListPartner_edges[];
  pageInfo: AdminPartnersList_adminListPartner_pageInfo;
}

export interface AdminPartnersList {
  adminListPartner: AdminPartnersList_adminListPartner;
}

export interface AdminPartnersListVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdatePartner
// ====================================================

export interface AdminUpdatePartner_adminUpdatePartner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminUpdatePartner {
  adminUpdatePartner: AdminUpdatePartner_adminUpdatePartner;
}

export interface AdminUpdatePartnerVariables {
  input: PartnerInput;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminRemovePartner
// ====================================================

export interface AdminRemovePartner {
  adminRemovePartner: boolean;
}

export interface AdminRemovePartnerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminGetPartner
// ====================================================

export interface AdminGetPartner_adminPartner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminGetPartner {
  adminPartner: AdminGetPartner_adminPartner;
}

export interface AdminGetPartnerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminPartnerStores
// ====================================================

export interface AdminPartnerStores_adminPartnerStores_edges_node_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminPartnerStores_adminPartnerStores_edges_node_pricing_pricing | null;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_shopify;
  database: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_database;
  index: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters_index;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data_catalogCounters;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus_data;
}

export interface AdminPartnerStores_adminPartnerStores_edges_node {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminPartnerStores_adminPartnerStores_edges_node_pricing;
  domain: string | null;
  partner: AdminPartnerStores_adminPartnerStores_edges_node_partner | null;
  monitoringStatus: AdminPartnerStores_adminPartnerStores_edges_node_monitoringStatus | null;
}

export interface AdminPartnerStores_adminPartnerStores_edges {
  __typename: "AccountEdge";
  node: AdminPartnerStores_adminPartnerStores_edges_node;
  cursor: string;
}

export interface AdminPartnerStores_adminPartnerStores_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

export interface AdminPartnerStores_adminPartnerStores {
  __typename: "AccountConnection";
  edges: AdminPartnerStores_adminPartnerStores_edges[];
  pageInfo: AdminPartnerStores_adminPartnerStores_pageInfo;
}

export interface AdminPartnerStores {
  adminPartnerStores: AdminPartnerStores_adminPartnerStores;
}

export interface AdminPartnerStoresVariables {
  id: string;
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  isTest?: boolean | null;
  status?: AccountStatus | null;
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppProfiles
// ====================================================

export interface AppProfiles_listMixpanelAppProfiles_results_properties {
  __typename: "MixpanelProfileProps";
  country_code: string | null;
  city: string | null;
}

export interface AppProfiles_listMixpanelAppProfiles_results {
  __typename: "MixpanelProfile";
  id: string | null;
  properties: AppProfiles_listMixpanelAppProfiles_results_properties | null;
}

export interface AppProfiles_listMixpanelAppProfiles {
  __typename: "MixpanelProfilesResponse";
  page: number | null;
  page_size: number | null;
  session_id: string | null;
  status: string | null;
  total: number | null;
  results: (AppProfiles_listMixpanelAppProfiles_results | null)[];
}

export interface AppProfiles {
  listMixpanelAppProfiles: AppProfiles_listMixpanelAppProfiles;
}

export interface AppProfilesVariables {
  page?: number | null;
  sessionId?: string | null;
  where?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AdminUpdateSmartBannerAccounts
// ====================================================

export interface AdminUpdateSmartBannerAccounts {
  adminUpdateSmartBannerAccounts: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllApps
// ====================================================

export interface AllApps_apps_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AllApps_apps_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AllApps_apps_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AllApps_apps_draftConfig_design_colors_light;
  dark: AllApps_apps_draftConfig_design_colors_dark;
}

export interface AllApps_apps_draftConfig_design {
  __typename: "AppDesign";
  colors: AllApps_apps_draftConfig_design_colors;
}

export interface AllApps_apps_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AllApps_apps_draftConfig_design;
}

export interface AllApps_apps_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface AllApps_apps_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: AllApps_apps_settings_banner_params | null;
}

export interface AllApps_apps_settings {
  __typename: "AppSettings";
  banner: AllApps_apps_settings_banner | null;
  logo: string | null;
}

export interface AllApps_apps_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface AllApps_apps_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface AllApps_apps {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: AllApps_apps_draftConfig;
  settings: AllApps_apps_settings | null;
  freeTrafficActive: boolean;
  card: AllApps_apps_card;
  publishedCard: AllApps_apps_publishedCard | null;
}

export interface AllApps {
  apps: AllApps_apps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: App
// ====================================================

export interface App_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface App_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface App_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: App_app_draftConfig_design_colors_light;
  dark: App_app_draftConfig_design_colors_dark;
}

export interface App_app_draftConfig_design {
  __typename: "AppDesign";
  colors: App_app_draftConfig_design_colors;
}

export interface App_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: App_app_draftConfig_design;
}

export interface App_app_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface App_app_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: App_app_settings_banner_params | null;
}

export interface App_app_settings {
  __typename: "AppSettings";
  banner: App_app_settings_banner | null;
  logo: string | null;
}

export interface App_app_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface App_app_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface App_app {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: App_app_draftConfig;
  settings: App_app_settings | null;
  freeTrafficActive: boolean;
  card: App_app_card;
  publishedCard: App_app_publishedCard | null;
}

export interface App {
  app: App_app;
}

export interface AppVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePreviewSession
// ====================================================

export interface CreatePreviewSession_createPreviewSession {
  __typename: "PreviewEvent";
  id: string;
  location: string;
}

export interface CreatePreviewSession {
  createPreviewSession: CreatePreviewSession_createPreviewSession;
}

export interface CreatePreviewSessionVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClosePreviewSession
// ====================================================

export interface ClosePreviewSession {
  closePreviewSession: boolean;
}

export interface ClosePreviewSessionVariables {
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PreviewToken
// ====================================================

export interface PreviewToken_previewToken {
  __typename: "PreviewToken";
  previewToken: string;
}

export interface PreviewToken {
  previewToken: PreviewToken_previewToken;
}

export interface PreviewTokenVariables {
  appId: string;
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppConfig
// ====================================================

export interface AppConfig_app_settings {
  __typename: "AppSettings";
  logo: string | null;
}

export interface AppConfig_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface AppConfig_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppConfig_app_draftConfig_design_colors_light;
}

export interface AppConfig_app_draftConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: AppConfig_app_draftConfig_design_colors;
}

export interface AppConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AppConfig_app_draftConfig_design;
  icon: string | null;
  splash: string | null;
}

export interface AppConfig_app_publishedConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface AppConfig_app_publishedConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppConfig_app_publishedConfig_design_colors_light;
}

export interface AppConfig_app_publishedConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: AppConfig_app_publishedConfig_design_colors;
}

export interface AppConfig_app_publishedConfig {
  __typename: "AppConfig";
  id: string;
  design: AppConfig_app_publishedConfig_design;
  icon: string | null;
  splash: string | null;
}

export interface AppConfig_app {
  __typename: "App";
  id: string;
  settings: AppConfig_app_settings | null;
  draftConfig: AppConfig_app_draftConfig;
  publishedConfig: AppConfig_app_publishedConfig | null;
}

export interface AppConfig {
  app: AppConfig_app;
}

export interface AppConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppEmulatorConfig
// ====================================================

export interface AppEmulatorConfig_app_draftConfig_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppEmulatorConfig_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppEmulatorConfig_app_draftConfig_design_colors_light;
  dark: AppEmulatorConfig_app_draftConfig_design_colors_dark;
}

export interface AppEmulatorConfig_app_draftConfig_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface AppEmulatorConfig_app_draftConfig_design {
  __typename: "AppDesign";
  colors: AppEmulatorConfig_app_draftConfig_design_colors;
  values: AppEmulatorConfig_app_draftConfig_design_values;
}

export interface AppEmulatorConfig_app_draftConfig_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppEmulatorConfig_app_draftConfig_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: AppEmulatorConfig_app_draftConfig_tabs_icon | null;
}

export interface AppEmulatorConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  connection: AppEmulatorConfig_app_draftConfig_connection;
  design: AppEmulatorConfig_app_draftConfig_design;
  tabs: AppEmulatorConfig_app_draftConfig_tabs[];
}

export interface AppEmulatorConfig_app {
  __typename: "App";
  id: string;
  draftConfig: AppEmulatorConfig_app_draftConfig;
}

export interface AppEmulatorConfig {
  app: AppEmulatorConfig_app;
}

export interface AppEmulatorConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppTabsConfig
// ====================================================

export interface AppTabsConfig_app_draftConfig_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppTabsConfig_app_draftConfig_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: AppTabsConfig_app_draftConfig_tabsDesigner_icon | null;
}

export interface AppTabsConfig_app_draftConfig_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface AppTabsConfig_app_draftConfig_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: AppTabsConfig_app_draftConfig_moreTabsDesigner_icon | null;
}

export interface AppTabsConfig_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  tabsDesigner: AppTabsConfig_app_draftConfig_tabsDesigner[];
  moreTabsDesigner: AppTabsConfig_app_draftConfig_moreTabsDesigner[];
}

export interface AppTabsConfig_app {
  __typename: "App";
  id: string;
  draftConfig: AppTabsConfig_app_draftConfig;
}

export interface AppTabsConfig {
  app: AppTabsConfig_app;
}

export interface AppTabsConfigVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateApp
// ====================================================

export interface UpdateApp_appUpdate_settings {
  __typename: "AppSettings";
  logo: string | null;
}

export interface UpdateApp_appUpdate_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface UpdateApp_appUpdate_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: UpdateApp_appUpdate_draftConfig_design_colors_light;
}

export interface UpdateApp_appUpdate_draftConfig_design {
  __typename: "AppDesign";
  designerData: string;
  colors: UpdateApp_appUpdate_draftConfig_design_colors;
}

export interface UpdateApp_appUpdate_draftConfig_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface UpdateApp_appUpdate_draftConfig_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: UpdateApp_appUpdate_draftConfig_tabsDesigner_icon | null;
}

export interface UpdateApp_appUpdate_draftConfig_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface UpdateApp_appUpdate_draftConfig_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: UpdateApp_appUpdate_draftConfig_moreTabsDesigner_icon | null;
}

export interface UpdateApp_appUpdate_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: UpdateApp_appUpdate_draftConfig_design;
  icon: string | null;
  splash: string | null;
  tabsDesigner: UpdateApp_appUpdate_draftConfig_tabsDesigner[];
  moreTabsDesigner: UpdateApp_appUpdate_draftConfig_moreTabsDesigner[];
}

export interface UpdateApp_appUpdate {
  __typename: "App";
  id: string;
  settings: UpdateApp_appUpdate_settings | null;
  draftConfig: UpdateApp_appUpdate_draftConfig;
}

export interface UpdateApp {
  appUpdate: UpdateApp_appUpdate;
}

export interface UpdateAppVariables {
  id: string;
  config: AppConfigurationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApp
// ====================================================

export interface CreateApp_appCreate_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface CreateApp_appCreate_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface CreateApp_appCreate_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: CreateApp_appCreate_draftConfig_design_colors_light;
  dark: CreateApp_appCreate_draftConfig_design_colors_dark;
}

export interface CreateApp_appCreate_draftConfig_design {
  __typename: "AppDesign";
  colors: CreateApp_appCreate_draftConfig_design_colors;
}

export interface CreateApp_appCreate_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: CreateApp_appCreate_draftConfig_design;
}

export interface CreateApp_appCreate_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface CreateApp_appCreate_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: CreateApp_appCreate_settings_banner_params | null;
}

export interface CreateApp_appCreate_settings {
  __typename: "AppSettings";
  banner: CreateApp_appCreate_settings_banner | null;
  logo: string | null;
}

export interface CreateApp_appCreate_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface CreateApp_appCreate_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface CreateApp_appCreate {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: CreateApp_appCreate_draftConfig;
  settings: CreateApp_appCreate_settings | null;
  freeTrafficActive: boolean;
  card: CreateApp_appCreate_card;
  publishedCard: CreateApp_appCreate_publishedCard | null;
}

export interface CreateApp {
  appCreate: CreateApp_appCreate;
}

export interface CreateAppVariables {
  name: string;
  repeatKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RenameApp
// ====================================================

export interface RenameApp_appRename_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface RenameApp_appRename_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface RenameApp_appRename_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: RenameApp_appRename_draftConfig_design_colors_light;
  dark: RenameApp_appRename_draftConfig_design_colors_dark;
}

export interface RenameApp_appRename_draftConfig_design {
  __typename: "AppDesign";
  colors: RenameApp_appRename_draftConfig_design_colors;
}

export interface RenameApp_appRename_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: RenameApp_appRename_draftConfig_design;
}

export interface RenameApp_appRename_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface RenameApp_appRename_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: RenameApp_appRename_settings_banner_params | null;
}

export interface RenameApp_appRename_settings {
  __typename: "AppSettings";
  banner: RenameApp_appRename_settings_banner | null;
  logo: string | null;
}

export interface RenameApp_appRename_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface RenameApp_appRename_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface RenameApp_appRename {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: RenameApp_appRename_draftConfig;
  settings: RenameApp_appRename_settings | null;
  freeTrafficActive: boolean;
  card: RenameApp_appRename_card;
  publishedCard: RenameApp_appRename_publishedCard | null;
}

export interface RenameApp {
  appRename: RenameApp_appRename;
}

export interface RenameAppVariables {
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveApp
// ====================================================

export interface ArchiveApp_appArchive_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ArchiveApp_appArchive_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ArchiveApp_appArchive_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: ArchiveApp_appArchive_draftConfig_design_colors_light;
  dark: ArchiveApp_appArchive_draftConfig_design_colors_dark;
}

export interface ArchiveApp_appArchive_draftConfig_design {
  __typename: "AppDesign";
  colors: ArchiveApp_appArchive_draftConfig_design_colors;
}

export interface ArchiveApp_appArchive_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: ArchiveApp_appArchive_draftConfig_design;
}

export interface ArchiveApp_appArchive_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface ArchiveApp_appArchive_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: ArchiveApp_appArchive_settings_banner_params | null;
}

export interface ArchiveApp_appArchive_settings {
  __typename: "AppSettings";
  banner: ArchiveApp_appArchive_settings_banner | null;
  logo: string | null;
}

export interface ArchiveApp_appArchive_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface ArchiveApp_appArchive_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface ArchiveApp_appArchive {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: ArchiveApp_appArchive_draftConfig;
  settings: ArchiveApp_appArchive_settings | null;
  freeTrafficActive: boolean;
  card: ArchiveApp_appArchive_card;
  publishedCard: ArchiveApp_appArchive_publishedCard | null;
}

export interface ArchiveApp {
  appArchive: ArchiveApp_appArchive;
}

export interface ArchiveAppVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePreviewSessionUrl
// ====================================================

export interface UpdatePreviewSessionUrl_updatePreviewSessionUrl {
  __typename: "PreviewEvent";
  id: string;
  location: string;
}

export interface UpdatePreviewSessionUrl {
  updatePreviewSessionUrl: UpdatePreviewSessionUrl_updatePreviewSessionUrl;
}

export interface UpdatePreviewSessionUrlVariables {
  appId: string;
  sessionId: string;
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppIcon
// ====================================================

export interface UpdateAppIcon_updateAppIcon {
  __typename: "App";
  id: string;
}

export interface UpdateAppIcon {
  updateAppIcon: UpdateAppIcon_updateAppIcon;
}

export interface UpdateAppIconVariables {
  id: string;
  url?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppSplash
// ====================================================

export interface UpdateAppSplash_updateAppSplash {
  __typename: "App";
  id: string;
}

export interface UpdateAppSplash {
  updateAppSplash: UpdateAppSplash_updateAppSplash;
}

export interface UpdateAppSplashVariables {
  id: string;
  url?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareMall
// ====================================================

export interface ShareMall {
  shareMall: boolean;
}

export interface ShareMallVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppCard
// ====================================================

export interface GetAppCard_app_card_icon {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_splash {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_background {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_card_app {
  __typename: "App";
  id: string;
}

export interface GetAppCard_app_card {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: GetAppCard_app_card_icon | null;
  splash: GetAppCard_app_card_splash | null;
  background: GetAppCard_app_card_background | null;
  createdAt: string;
  app: GetAppCard_app_card_app;
}

export interface GetAppCard_app_publishedCard_icon {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_splash {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_background {
  __typename: "Image";
  src: string;
}

export interface GetAppCard_app_publishedCard_app {
  __typename: "App";
  id: string;
}

export interface GetAppCard_app_publishedCard {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: GetAppCard_app_publishedCard_icon | null;
  splash: GetAppCard_app_publishedCard_splash | null;
  background: GetAppCard_app_publishedCard_background | null;
  createdAt: string;
  app: GetAppCard_app_publishedCard_app;
}

export interface GetAppCard_app {
  __typename: "App";
  id: string;
  card: GetAppCard_app_card;
  publishedCard: GetAppCard_app_publishedCard | null;
}

export interface GetAppCard {
  app: GetAppCard_app;
}

export interface GetAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppCard
// ====================================================

export interface UpdateAppCard_updateAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_background {
  __typename: "Image";
  src: string;
}

export interface UpdateAppCard_updateAppCard_app {
  __typename: "App";
  id: string;
}

export interface UpdateAppCard_updateAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: UpdateAppCard_updateAppCard_icon | null;
  splash: UpdateAppCard_updateAppCard_splash | null;
  background: UpdateAppCard_updateAppCard_background | null;
  createdAt: string;
  app: UpdateAppCard_updateAppCard_app;
}

export interface UpdateAppCard {
  updateAppCard: UpdateAppCard_updateAppCard;
}

export interface UpdateAppCardVariables {
  appId: string;
  input: AppCardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitAppCard
// ====================================================

export interface SubmitAppCard_submitAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_background {
  __typename: "Image";
  src: string;
}

export interface SubmitAppCard_submitAppCard_app {
  __typename: "App";
  id: string;
}

export interface SubmitAppCard_submitAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: SubmitAppCard_submitAppCard_icon | null;
  splash: SubmitAppCard_submitAppCard_splash | null;
  background: SubmitAppCard_submitAppCard_background | null;
  createdAt: string;
  app: SubmitAppCard_submitAppCard_app;
}

export interface SubmitAppCard {
  submitAppCard: SubmitAppCard_submitAppCard;
}

export interface SubmitAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DraftAppCard
// ====================================================

export interface DraftAppCard_draftAppCard_icon {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_splash {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_background {
  __typename: "Image";
  src: string;
}

export interface DraftAppCard_draftAppCard_app {
  __typename: "App";
  id: string;
}

export interface DraftAppCard_draftAppCard {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: DraftAppCard_draftAppCard_icon | null;
  splash: DraftAppCard_draftAppCard_splash | null;
  background: DraftAppCard_draftAppCard_background | null;
  createdAt: string;
  app: DraftAppCard_draftAppCard_app;
}

export interface DraftAppCard {
  draftAppCard: DraftAppCard_draftAppCard;
}

export interface DraftAppCardVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncCatalog
// ====================================================

export interface SyncCatalog_shopRestartSync {
  __typename: "SyncState";
  id: string;
}

export interface SyncCatalog {
  shopRestartSync: SyncCatalog_shopRestartSync;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EnableBanner
// ====================================================

export interface EnableBanner_enableBanner_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface EnableBanner_enableBanner_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface EnableBanner_enableBanner_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: EnableBanner_enableBanner_draftConfig_design_colors_light;
  dark: EnableBanner_enableBanner_draftConfig_design_colors_dark;
}

export interface EnableBanner_enableBanner_draftConfig_design {
  __typename: "AppDesign";
  colors: EnableBanner_enableBanner_draftConfig_design_colors;
}

export interface EnableBanner_enableBanner_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: EnableBanner_enableBanner_draftConfig_design;
}

export interface EnableBanner_enableBanner_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface EnableBanner_enableBanner_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: EnableBanner_enableBanner_settings_banner_params | null;
}

export interface EnableBanner_enableBanner_settings {
  __typename: "AppSettings";
  banner: EnableBanner_enableBanner_settings_banner | null;
  logo: string | null;
}

export interface EnableBanner_enableBanner_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface EnableBanner_enableBanner_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface EnableBanner_enableBanner {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: EnableBanner_enableBanner_draftConfig;
  settings: EnableBanner_enableBanner_settings | null;
  freeTrafficActive: boolean;
  card: EnableBanner_enableBanner_card;
  publishedCard: EnableBanner_enableBanner_publishedCard | null;
}

export interface EnableBanner {
  enableBanner: EnableBanner_enableBanner;
}

export interface EnableBannerVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DisableBanner
// ====================================================

export interface DisableBanner_disableBanner_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface DisableBanner_disableBanner_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface DisableBanner_disableBanner_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: DisableBanner_disableBanner_draftConfig_design_colors_light;
  dark: DisableBanner_disableBanner_draftConfig_design_colors_dark;
}

export interface DisableBanner_disableBanner_draftConfig_design {
  __typename: "AppDesign";
  colors: DisableBanner_disableBanner_draftConfig_design_colors;
}

export interface DisableBanner_disableBanner_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: DisableBanner_disableBanner_draftConfig_design;
}

export interface DisableBanner_disableBanner_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface DisableBanner_disableBanner_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: DisableBanner_disableBanner_settings_banner_params | null;
}

export interface DisableBanner_disableBanner_settings {
  __typename: "AppSettings";
  banner: DisableBanner_disableBanner_settings_banner | null;
  logo: string | null;
}

export interface DisableBanner_disableBanner_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface DisableBanner_disableBanner_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface DisableBanner_disableBanner {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: DisableBanner_disableBanner_draftConfig;
  settings: DisableBanner_disableBanner_settings | null;
  freeTrafficActive: boolean;
  card: DisableBanner_disableBanner_card;
  publishedCard: DisableBanner_disableBanner_publishedCard | null;
}

export interface DisableBanner {
  disableBanner: DisableBanner_disableBanner;
}

export interface DisableBannerVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBannerParams
// ====================================================

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: UpdateBannerParams_editBannerParams_draftConfig_design_colors_light;
  dark: UpdateBannerParams_editBannerParams_draftConfig_design_colors_dark;
}

export interface UpdateBannerParams_editBannerParams_draftConfig_design {
  __typename: "AppDesign";
  colors: UpdateBannerParams_editBannerParams_draftConfig_design_colors;
}

export interface UpdateBannerParams_editBannerParams_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: UpdateBannerParams_editBannerParams_draftConfig_design;
}

export interface UpdateBannerParams_editBannerParams_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface UpdateBannerParams_editBannerParams_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: UpdateBannerParams_editBannerParams_settings_banner_params | null;
}

export interface UpdateBannerParams_editBannerParams_settings {
  __typename: "AppSettings";
  banner: UpdateBannerParams_editBannerParams_settings_banner | null;
  logo: string | null;
}

export interface UpdateBannerParams_editBannerParams_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface UpdateBannerParams_editBannerParams_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface UpdateBannerParams_editBannerParams {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: UpdateBannerParams_editBannerParams_draftConfig;
  settings: UpdateBannerParams_editBannerParams_settings | null;
  freeTrafficActive: boolean;
  card: UpdateBannerParams_editBannerParams_card;
  publishedCard: UpdateBannerParams_editBannerParams_publishedCard | null;
}

export interface UpdateBannerParams {
  editBannerParams: UpdateBannerParams_editBannerParams;
}

export interface UpdateBannerParamsVariables {
  appId: string;
  input: AppBannerInputParams;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAppSettings
// ====================================================

export interface GetAppSettings_app_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface GetAppSettings_app_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface GetAppSettings_app_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: GetAppSettings_app_draftConfig_design_colors_light;
  dark: GetAppSettings_app_draftConfig_design_colors_dark;
}

export interface GetAppSettings_app_draftConfig_design {
  __typename: "AppDesign";
  colors: GetAppSettings_app_draftConfig_design_colors;
}

export interface GetAppSettings_app_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: GetAppSettings_app_draftConfig_design;
}

export interface GetAppSettings_app_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface GetAppSettings_app_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: GetAppSettings_app_settings_banner_params | null;
}

export interface GetAppSettings_app_settings {
  __typename: "AppSettings";
  banner: GetAppSettings_app_settings_banner | null;
  logo: string | null;
}

export interface GetAppSettings_app_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface GetAppSettings_app_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface GetAppSettings_app {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: GetAppSettings_app_draftConfig;
  settings: GetAppSettings_app_settings | null;
  freeTrafficActive: boolean;
  card: GetAppSettings_app_card;
  publishedCard: GetAppSettings_app_publishedCard | null;
}

export interface GetAppSettings {
  app: GetAppSettings_app;
}

export interface GetAppSettingsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppStat
// ====================================================

export interface AppStat_appStat {
  __typename: "AccountStatistic";
  visits: number;
  orders: number;
  installs: number;
  ordersSum: number;
}

export interface AppStat {
  appStat: AppStat_appStat;
}

export interface AppStatVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSiteScreenshot
// ====================================================

export interface GetSiteScreenshot {
  siteScreenshot: string;
}

export interface GetSiteScreenshotVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateSharLink
// ====================================================

export interface GenerateSharLink {
  generateShareLink: string;
}

export interface GenerateSharLinkVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: WatchAppEmulatorConfig
// ====================================================

export interface WatchAppEmulatorConfig_appConfig_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface WatchAppEmulatorConfig_appConfig_design_colors {
  __typename: "AppDesignThemes";
  light: WatchAppEmulatorConfig_appConfig_design_colors_light;
  dark: WatchAppEmulatorConfig_appConfig_design_colors_dark;
}

export interface WatchAppEmulatorConfig_appConfig_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface WatchAppEmulatorConfig_appConfig_design {
  __typename: "AppDesign";
  colors: WatchAppEmulatorConfig_appConfig_design_colors;
  values: WatchAppEmulatorConfig_appConfig_design_values;
}

export interface WatchAppEmulatorConfig_appConfig_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface WatchAppEmulatorConfig_appConfig_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: WatchAppEmulatorConfig_appConfig_tabs_icon | null;
}

export interface WatchAppEmulatorConfig_appConfig {
  __typename: "AppConfig";
  id: string;
  connection: WatchAppEmulatorConfig_appConfig_connection;
  design: WatchAppEmulatorConfig_appConfig_design;
  tabs: WatchAppEmulatorConfig_appConfig_tabs[];
}

export interface WatchAppEmulatorConfig {
  appConfig: WatchAppEmulatorConfig_appConfig;
}

export interface WatchAppEmulatorConfigVariables {
  appId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetFreeTraffic
// ====================================================

export interface SetFreeTraffic_appSetFreeTraffic {
  __typename: "App";
  id: string;
}

export interface SetFreeTraffic {
  appSetFreeTraffic: SetFreeTraffic_appSetFreeTraffic;
}

export interface SetFreeTrafficVariables {
  id: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppVersionsPage
// ====================================================

export interface AppVersionsPage_appVersions_edges_node_builds_buildInfo {
  __typename: "AppBuildInfo";
  id: string;
  status: string | null;
  statusText: string | null;
  percentageComplete: number | null;
}

export interface AppVersionsPage_appVersions_edges_node_builds {
  __typename: "AppBuild";
  id: string;
  state: string;
  buildInfo: AppVersionsPage_appVersions_edges_node_builds_buildInfo | null;
}

export interface AppVersionsPage_appVersions_edges_node {
  __typename: "AppVersion";
  id: string;
  platform: AppPlatforms;
  status: AppVersionStatuses;
  version: string;
  number: number;
  createdAt: string;
  builds: AppVersionsPage_appVersions_edges_node_builds[];
}

export interface AppVersionsPage_appVersions_edges {
  __typename: "AppVersionEdge";
  node: AppVersionsPage_appVersions_edges_node;
}

export interface AppVersionsPage_appVersions_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface AppVersionsPage_appVersions {
  __typename: "AppVersionConnection";
  edges: AppVersionsPage_appVersions_edges[];
  pageInfo: AppVersionsPage_appVersions_pageInfo;
}

export interface AppVersionsPage {
  appVersions: AppVersionsPage_appVersions | null;
}

export interface AppVersionsPageVariables {
  id: string;
  first?: number | null;
  after?: string | null;
  platform?: AppPlatforms | null;
  status?: AppVersionStatuses | null;
  sort?: AppVersionsSorts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateNewAppVersion
// ====================================================

export interface CreateNewAppVersion_createNewAppVersion {
  __typename: "AppVersion";
  id: string;
}

export interface CreateNewAppVersion {
  createNewAppVersion: CreateNewAppVersion_createNewAppVersion;
}

export interface CreateNewAppVersionVariables {
  id: string;
  input: NewAppVersionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MyShop
// ====================================================

export interface MyShop_shop_properties {
  __typename: "StoreProperties";
  name: string;
  email: string;
  phone: string | null;
}

export interface MyShop_shop {
  __typename: "Shop";
  id: string;
  domain: string;
  name: string;
  properties: MyShop_shop_properties;
}

export interface MyShop_syncState {
  __typename: "SyncState";
  id: string;
  status: SyncStatus;
}

export interface MyShop {
  shop: MyShop_shop;
  syncState: MyShop_syncState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Pages
// ====================================================

export interface Pages_pages {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

export interface Pages {
  pages: Pages_pages[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Page
// ====================================================

export interface Page_page {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

export interface Page {
  page: Page_page;
}

export interface PageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BlurHash
// ====================================================

export interface BlurHash_getBlurHash {
  __typename: "BlurHashImage";
  src: string;
  blurhash: string;
}

export interface BlurHash {
  getBlurHash: BlurHash_getBlurHash;
}

export interface BlurHashVariables {
  url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePage
// ====================================================

export interface CreatePage_pageCreate {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

export interface CreatePage {
  pageCreate: CreatePage_pageCreate;
}

export interface CreatePageVariables {
  input: ContentPageCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePage
// ====================================================

export interface UpdatePage_pageUpdate {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

export interface UpdatePage {
  pageUpdate: UpdatePage_pageUpdate;
}

export interface UpdatePageVariables {
  id: string;
  input: ContentPageUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishPage
// ====================================================

export interface PublishPage_pagePublish {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

export interface PublishPage {
  pagePublish: PublishPage_pagePublish;
}

export interface PublishPageVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListPricing
// ====================================================

export interface ListPricing_listPricing_edges_node {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

export interface ListPricing_listPricing_edges {
  __typename: "PricingEdge";
  node: ListPricing_listPricing_edges_node;
  cursor: string;
}

export interface ListPricing_listPricing_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListPricing_listPricing {
  __typename: "PricingConnection";
  edges: ListPricing_listPricing_edges[];
  pageInfo: ListPricing_listPricing_pageInfo;
}

export interface ListPricing {
  listPricing: ListPricing_listPricing;
}

export interface ListPricingVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DefaultPricing
// ====================================================

export interface DefaultPricing_defaultPricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

export interface DefaultPricing {
  defaultPricing: DefaultPricing_defaultPricing;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubscribeToPricing
// ====================================================

export interface SubscribeToPricing {
  subscribeToPricing: string | null;
}

export interface SubscribeToPricingVariables {
  id: string;
  pricingId: string;
  returnUrl?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmBilling
// ====================================================

export interface ConfirmBilling_confirmBilling_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface ConfirmBilling_confirmBilling_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: ConfirmBilling_confirmBilling_pricing_pricing | null;
}

export interface ConfirmBilling_confirmBilling_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface ConfirmBilling_confirmBilling {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: ConfirmBilling_confirmBilling_pricing;
  domain: string | null;
  partner: ConfirmBilling_confirmBilling_partner | null;
}

export interface ConfirmBilling {
  confirmBilling: ConfirmBilling_confirmBilling;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Pricing
// ====================================================

export interface Pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

export interface Pricing {
  pricing: Pricing_pricing;
}

export interface PricingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Product
// ====================================================

export interface Product_products_images {
  __typename: "Image";
  id: string;
  src: string;
}

export interface Product_products {
  __typename: "Product";
  id: string;
  title: string;
  images: (Product_products_images | null)[] | null;
}

export interface Product {
  products: Product_products[];
}

export interface ProductVariables {
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchListProducts
// ====================================================

export interface SearchListProducts_listProducts_edges_node_images {
  __typename: "Image";
  src: string;
  id: string;
  blurhash: string | null;
}

export interface SearchListProducts_listProducts_edges_node_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  formatted_price: string | null;
}

export interface SearchListProducts_listProducts_edges_node {
  __typename: "Product";
  id: string;
  title: string;
  images: (SearchListProducts_listProducts_edges_node_images | null)[] | null;
  variants: (SearchListProducts_listProducts_edges_node_variants | null)[] | null;
}

export interface SearchListProducts_listProducts_edges {
  __typename: "ProductEdge";
  node: SearchListProducts_listProducts_edges_node;
}

export interface SearchListProducts_listProducts {
  __typename: "ProductConnection";
  edges: SearchListProducts_listProducts_edges[];
}

export interface SearchListProducts {
  listProducts: SearchListProducts_listProducts;
}

export interface SearchListProductsVariables {
  query?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListProducts
// ====================================================

export interface ListProducts_listProducts_edges_node_images {
  __typename: "Image";
  src: string;
  id: string;
}

export interface ListProducts_listProducts_edges_node_variants {
  __typename: "ProductVariant";
  id: string;
  price: string;
  formatted_price: string | null;
}

export interface ListProducts_listProducts_edges_node {
  __typename: "Product";
  id: string;
  title: string;
  images: (ListProducts_listProducts_edges_node_images | null)[] | null;
  variants: (ListProducts_listProducts_edges_node_variants | null)[] | null;
}

export interface ListProducts_listProducts_edges {
  __typename: "ProductEdge";
  node: ListProducts_listProducts_edges_node;
}

export interface ListProducts_listProducts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  endCursor: string | null;
}

export interface ListProducts_listProducts {
  __typename: "ProductConnection";
  edges: ListProducts_listProducts_edges[];
  pageInfo: ListProducts_listProducts_pageInfo;
}

export interface ListProducts {
  listProducts: ListProducts_listProducts;
}

export interface ListProductsVariables {
  first?: number | null;
  after?: string | null;
  last?: number | null;
  before?: string | null;
  query?: string | null;
  appId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Settings
// ====================================================

export interface Settings_storeConnections_appStore {
  __typename: "AppStoreConnection";
  id: string;
  teamId: string;
  issuerId: string;
  keyId: string;
  key: string;
}

export interface Settings_storeConnections_googlePlay {
  __typename: "GooglePlayConnection";
  id: string;
  authType: GooglePlayConnectAuthType;
  credentials: string;
  active: boolean;
}

export interface Settings_storeConnections {
  __typename: "StoreConnections";
  id: string;
  appStore: Settings_storeConnections_appStore | null;
  googlePlay: Settings_storeConnections_googlePlay | null;
}

export interface Settings {
  storeConnections: Settings_storeConnections;
  checkAppStoreConnection: boolean;
  checkGooglePlayConnection: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveAppStore
// ====================================================

export interface SaveAppStore_connectAppStore_appStore {
  __typename: "AppStoreConnection";
  id: string;
  teamId: string;
  issuerId: string;
  keyId: string;
}

export interface SaveAppStore_connectAppStore {
  __typename: "StoreConnections";
  id: string;
  appStore: SaveAppStore_connectAppStore_appStore | null;
}

export interface SaveAppStore {
  connectAppStore: SaveAppStore_connectAppStore;
}

export interface SaveAppStoreVariables {
  input: AppStoreConnectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveGooglePlay
// ====================================================

export interface SaveGooglePlay_connectGooglePlay_googlePlay {
  __typename: "GooglePlayConnection";
  id: string;
  authType: GooglePlayConnectAuthType;
  credentials: string;
  active: boolean;
}

export interface SaveGooglePlay_connectGooglePlay {
  __typename: "GooglePlayConnectionWrapper";
  id: string;
  googlePlay: SaveGooglePlay_connectGooglePlay_googlePlay | null;
}

export interface SaveGooglePlay {
  connectGooglePlay: SaveGooglePlay_connectGooglePlay;
}

export interface SaveGooglePlayVariables {
  input: GooglePlayConnectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collections
// ====================================================

export interface Collections_collections_image {
  __typename: "Image";
  src: string;
  blurhash: string | null;
}

export interface Collections_collections {
  __typename: "Collection";
  id: string;
  title: string;
  image: Collections_collections_image | null;
}

export interface Collections {
  collections: (Collections_collections | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PricingBaseFragment
// ====================================================

export interface PricingBaseFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountPricingFragment
// ====================================================

export interface AccountPricingFragment_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AccountPricingFragment {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AccountPricingFragment_pricing | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullAccount
// ====================================================

export interface FullAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface FullAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: FullAccount_pricing_pricing | null;
}

export interface FullAccount_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface FullAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: FullAccount_pricing;
  domain: string | null;
  partner: FullAccount_partner | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminPricingFragment
// ====================================================

export interface AdminPricingFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminAccountMonitoring
// ====================================================

export interface AdminAccountMonitoring_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccountMonitoring_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccountMonitoring_data_catalogCounters_shopify;
  database: AdminAccountMonitoring_data_catalogCounters_database;
  index: AdminAccountMonitoring_data_catalogCounters_index;
}

export interface AdminAccountMonitoring_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccountMonitoring_data_catalogCounters;
}

export interface AdminAccountMonitoring {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccountMonitoring_data;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminAccount
// ====================================================

export interface AdminAccount_pricing_pricing {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  trialDays: number;
  price: number;
  status: PricingStatus;
  hidden: boolean | null;
}

export interface AdminAccount_pricing {
  __typename: "AccountPricing";
  id: string;
  status: AccountPricingStatus;
  trialEndAt: string | null;
  chargeId: string | null;
  createdAt: string;
  updatedAt: string;
  hasAccess: boolean;
  pricing: AdminAccount_pricing_pricing | null;
}

export interface AdminAccount_partner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_shopify {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_database {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters_index {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

export interface AdminAccount_monitoringStatus_data_catalogCounters {
  __typename: "AccountMonitoringCatalogCounters";
  shopify: AdminAccount_monitoringStatus_data_catalogCounters_shopify;
  database: AdminAccount_monitoringStatus_data_catalogCounters_database;
  index: AdminAccount_monitoringStatus_data_catalogCounters_index;
}

export interface AdminAccount_monitoringStatus_data {
  __typename: "AccountMonitoringData";
  catalogCounters: AdminAccount_monitoringStatus_data_catalogCounters;
}

export interface AdminAccount_monitoringStatus {
  __typename: "AccountMonitoring";
  id: string;
  status: AccountMonitoringStatus;
  message: string | null;
  createdAt: string;
  data: AdminAccount_monitoringStatus_data;
}

export interface AdminAccount {
  __typename: "Account";
  id: string;
  name: string;
  status: AccountStatus;
  createdAt: string;
  updatedAt: string;
  screenshot: string | null;
  survey: string | null;
  pricing: AdminAccount_pricing;
  domain: string | null;
  partner: AdminAccount_partner | null;
  monitoringStatus: AdminAccount_monitoringStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminSurveyIndustry
// ====================================================

export interface AdminSurveyIndustry {
  __typename: "SurveyIndustry";
  id: string;
  title: string;
  sort: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminPartner
// ====================================================

export interface AdminPartner {
  __typename: "Partner";
  id: string;
  title: string;
  slug: string;
  payoutPercent: number;
  email: string;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccountMonitoringCounterFragment
// ====================================================

export interface AccountMonitoringCounterFragment {
  __typename: "AccountMonitoringCatalogCounter";
  collections: number;
  products: number;
  productsPrice: number;
  variants: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppFragment
// ====================================================

export interface AppFragment_draftConfig_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppFragment_draftConfig_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface AppFragment_draftConfig_design_colors {
  __typename: "AppDesignThemes";
  light: AppFragment_draftConfig_design_colors_light;
  dark: AppFragment_draftConfig_design_colors_dark;
}

export interface AppFragment_draftConfig_design {
  __typename: "AppDesign";
  colors: AppFragment_draftConfig_design_colors;
}

export interface AppFragment_draftConfig {
  __typename: "AppConfig";
  id: string;
  design: AppFragment_draftConfig_design;
}

export interface AppFragment_settings_banner_params {
  __typename: "AppBannerSettingsParams";
  buttonText: string;
  text: string;
  title: string;
  icon: string;
}

export interface AppFragment_settings_banner {
  __typename: "AppBannerSettings";
  scriptTagId: string | null;
  params: AppFragment_settings_banner_params | null;
}

export interface AppFragment_settings {
  __typename: "AppSettings";
  banner: AppFragment_settings_banner | null;
  logo: string | null;
}

export interface AppFragment_card {
  __typename: "AppCard";
  title: string;
  id: string;
  description: string;
}

export interface AppFragment_publishedCard {
  __typename: "AppCardPublished";
  title: string;
  id: string;
  description: string;
}

export interface AppFragment {
  __typename: "App";
  id: string;
  name: string;
  active: boolean;
  draftConfig: AppFragment_draftConfig;
  settings: AppFragment_settings | null;
  freeTrafficActive: boolean;
  card: AppFragment_card;
  publishedCard: AppFragment_publishedCard | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThemeColors
// ====================================================

export interface ThemeColors {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ThemesColors
// ====================================================

export interface ThemesColors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ThemesColors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface ThemesColors {
  __typename: "AppDesignThemes";
  light: ThemesColors_light;
  dark: ThemesColors_dark;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: Config
// ====================================================

export interface Config_connection {
  __typename: "AppConnection";
  token: string;
  endpoint: string;
}

export interface Config_design_colors_light {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface Config_design_colors_dark {
  __typename: "AppDesignColors";
  primary: string;
  accent: string;
  background: string;
  secondary: string;
  separator: string;
  primaryText: string;
  accentText: string;
  backgroundText: string;
  secondaryText: string;
  priceText: string;
  priceOldText: string;
  priceBackground: string;
  iconTint: string;
  accentIconTint: string;
  bottomNavigationIcon: string;
  bottomNavigationInactiveIcon: string;
  bottomNavigationText: string;
  bottomNavigationInactiveText: string;
  bottomNavigationAccent: string;
  bottomNavigationBackground: string;
  toolBarText: string;
  toolBarIcon: string;
  toolBarAccent: string;
  toolBarBackground: string;
  toCartBackground: string;
  toCartText: string;
  toCartIconTint: string;
}

export interface Config_design_colors {
  __typename: "AppDesignThemes";
  light: Config_design_colors_light;
  dark: Config_design_colors_dark;
}

export interface Config_design_values {
  __typename: "AppDesignValues";
  toolBarTextSize: number | null;
  toolBarTitleTextSize: number | null;
  bottomNavigationTextSize: number | null;
  buttonTextSize: number | null;
  headLineTextSize: number | null;
  subtitleTextSize: number | null;
  bodyTextSize: number | null;
}

export interface Config_design {
  __typename: "AppDesign";
  colors: Config_design_colors;
  values: Config_design_values;
}

export interface Config_tabs_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface Config_tabs {
  __typename: "AppTab";
  id: string;
  title: string;
  url: string | null;
  icon: Config_tabs_icon | null;
}

export interface Config {
  __typename: "AppConfig";
  id: string;
  connection: Config_connection;
  design: Config_design;
  tabs: Config_tabs[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConfigFragment
// ====================================================

export interface ConfigFragment_design_colors_light {
  __typename: "AppDesignColors";
  accent: string;
}

export interface ConfigFragment_design_colors {
  __typename: "AppDesignThemes";
  light: ConfigFragment_design_colors_light;
}

export interface ConfigFragment_design {
  __typename: "AppDesign";
  designerData: string;
  colors: ConfigFragment_design_colors;
}

export interface ConfigFragment {
  __typename: "AppConfig";
  id: string;
  design: ConfigFragment_design;
  icon: string | null;
  splash: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IconFragment
// ====================================================

export interface IconFragment {
  __typename: "Icon";
  collection: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TabsDesignerFragment
// ====================================================

export interface TabsDesignerFragment_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsDesignerFragment {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsDesignerFragment_icon | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TabsConfigFragment
// ====================================================

export interface TabsConfigFragment_tabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsConfigFragment_tabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsConfigFragment_tabsDesigner_icon | null;
}

export interface TabsConfigFragment_moreTabsDesigner_icon {
  __typename: "Icon";
  collection: string;
  name: string;
}

export interface TabsConfigFragment_moreTabsDesigner {
  __typename: "AppDesignerTab";
  key: string;
  title: string;
  default: boolean;
  contentType: ContentReferenceType;
  contentKey: string | null;
  contentUrl: string | null;
  icon: TabsConfigFragment_moreTabsDesigner_icon | null;
}

export interface TabsConfigFragment {
  __typename: "AppConfig";
  id: string;
  tabsDesigner: TabsConfigFragment_tabsDesigner[];
  moreTabsDesigner: TabsConfigFragment_moreTabsDesigner[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ImageFragment
// ====================================================

export interface ImageFragment {
  __typename: "Image";
  src: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardContentFragment
// ====================================================

export interface AppCardContentFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardContentFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardContentFragment {
  __typename: "AppCardPublished" | "AppCard";
  title: string;
  description: string;
  icon: AppCardContentFragment_icon | null;
  splash: AppCardContentFragment_splash | null;
  background: AppCardContentFragment_background | null;
  createdAt: string;
  app: AppCardContentFragment_app;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardFragment
// ====================================================

export interface AppCardFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardFragment {
  __typename: "AppCard";
  id: string;
  status: AppCardStatus;
  updatedAt: string | null;
  title: string;
  description: string;
  icon: AppCardFragment_icon | null;
  splash: AppCardFragment_splash | null;
  background: AppCardFragment_background | null;
  createdAt: string;
  app: AppCardFragment_app;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppCardPublishedFragment
// ====================================================

export interface AppCardPublishedFragment_icon {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_splash {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_background {
  __typename: "Image";
  src: string;
}

export interface AppCardPublishedFragment_app {
  __typename: "App";
  id: string;
}

export interface AppCardPublishedFragment {
  __typename: "AppCardPublished";
  id: string;
  status: AppCardPublishedStatus;
  publishedAt: string;
  title: string;
  description: string;
  icon: AppCardPublishedFragment_icon | null;
  splash: AppCardPublishedFragment_splash | null;
  background: AppCardPublishedFragment_background | null;
  createdAt: string;
  app: AppCardPublishedFragment_app;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FullPage
// ====================================================

export interface FullPage {
  __typename: "PageEditor";
  id: string;
  name: string;
  draftData: string;
  publishedData: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PricingFragment
// ====================================================

export interface PricingFragment {
  __typename: "Pricing";
  id: string;
  title: string;
  description: string;
  price: number;
  trialDays: number;
  status: PricingStatus;
  isDefault: boolean;
  accounts: number | null;
  capabilities: string | null;
  hidden: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductDescr
// ====================================================

export interface ProductDescr_images {
  __typename: "Image";
  id: string;
  src: string;
}

export interface ProductDescr {
  __typename: "Product";
  id: string;
  title: string;
  images: (ProductDescr_images | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ProductVariantDescr
// ====================================================

export interface ProductVariantDescr {
  __typename: "ProductVariant";
  id: string;
  price: string;
  formatted_price: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CollectionDescr
// ====================================================

export interface CollectionDescr_image {
  __typename: "Image";
  src: string;
  blurhash: string | null;
}

export interface CollectionDescr {
  __typename: "Collection";
  id: string;
  title: string;
  image: CollectionDescr_image | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountMonitoringStatus {
  CREATED = "CREATED",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export enum AccountPricingStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  CREATED = "CREATED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  TRIAL = "TRIAL",
}

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  CREATED = "CREATED",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
  ON_REVIEW = "ON_REVIEW",
}

export enum AdminUserRole {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}

export enum AppCardPublishedStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AppCardStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
  ON_REVIEW = "ON_REVIEW",
}

export enum AppPlatforms {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum AppVersionStatuses {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
}

export enum AppVersionsSorts {
  DATE_CREATED_ASC = "DATE_CREATED_ASC",
  DATE_CREATED_DESC = "DATE_CREATED_DESC",
  VERSION_NUMBER_ASC = "VERSION_NUMBER_ASC",
  VERSION_NUMBER_DESC = "VERSION_NUMBER_DESC",
}

export enum ContentPageBlockType {
  HTML = "HTML",
  REACT = "REACT",
}

export enum ContentPageType {
  BLOCK = "BLOCK",
  HTML = "HTML",
  URL = "URL",
}

export enum ContentReferenceType {
  ALL_COLLECTIONS = "ALL_COLLECTIONS",
  CART = "CART",
  COLLECTION = "COLLECTION",
  COLLECTIONS = "COLLECTIONS",
  CONTENT = "CONTENT",
  EMPTY = "EMPTY",
  FAVORITES = "FAVORITES",
  FEED = "FEED",
  PRODUCTS_BY_COLLECTIONS = "PRODUCTS_BY_COLLECTIONS",
  SHOP_ORDERS = "SHOP_ORDERS",
}

export enum GooglePlayConnectAuthType {
  SERVICE_ACCOUNT = "SERVICE_ACCOUNT",
}

export enum PricingStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum SyncStatus {
  IN_PROGRES = "IN_PROGRES",
  PENDING = "PENDING",
  READY = "READY",
}

export interface AccountCreateInput {
  industries: string[];
  sellingStatus: string;
  currentMonthlySales: string;
  teamSize?: string | null;
  forClient: boolean;
}

export interface AdminAddSurveyIndustryInput {
  title: string;
  sort?: number | null;
}

export interface AdminMakeTrialInput {
  timestamp: string;
  hours: string;
  minutes: string;
}

export interface AppBannerInputParams {
  buttonText: string;
  buttonColor?: string | null;
  buttonTextColor?: string | null;
  buttonTextFontWeight?: string | null;
  text: string;
  textColor?: string | null;
  textFontWeight?: string | null;
  title: string;
  titleColor?: string | null;
  titleFontWeight?: string | null;
  icon: string;
  backgroundColor?: string | null;
  stickToBottom?: boolean | null;
  hideIcon?: boolean | null;
  fullScreen?: boolean | null;
}

export interface AppCardInput {
  title: string;
  description: string;
  icon?: string | null;
  splash?: string | null;
  background?: string | null;
  industries: string[];
}

export interface AppConfigurationInput {
  design?: AppDesignInput | null;
  tabs?: AppTabInput[] | null;
  moreTabs?: AppTabInput[] | null;
  logo?: string | null;
}

export interface AppDesignColorInput {
  primary?: string | null;
  primaryText?: string | null;
  accent?: string | null;
  accentText?: string | null;
  background?: string | null;
  backgroundText?: string | null;
  secondary?: string | null;
  secondaryText?: string | null;
  separator?: string | null;
  priceText?: string | null;
  priceOldText?: string | null;
  priceBackground?: string | null;
  iconTint?: string | null;
  accentIconTint?: string | null;
  bottomNavigationIcon?: string | null;
  bottomNavigationInactiveIcon?: string | null;
  bottomNavigationText?: string | null;
  bottomNavigationInactiveText?: string | null;
  bottomNavigationAccent?: string | null;
  bottomNavigationBackground?: string | null;
  toolBarText?: string | null;
  toolBarIcon?: string | null;
  toolBarAccent?: string | null;
  toolBarBackground?: string | null;
  toCartBackground?: string | null;
  toCartText?: string | null;
  toCartIconTint?: string | null;
}

export interface AppDesignInput {
  designerData: string;
  colors?: AppThemesColorsInput | null;
  values?: AppDesignValuesInput | null;
}

export interface AppDesignValuesInput {
  toolBarTextSize?: number | null;
  toolBarTitleTextSize?: number | null;
  bottomNavigationTextSize?: number | null;
  buttonTextSize?: number | null;
  headLineTextSize?: number | null;
  subtitleTextSize?: number | null;
  bodyTextSize?: number | null;
}

export interface AppStoreConnectInput {
  teamId: string;
  issuerId: string;
  keyId: string;
  key: string;
}

export interface AppTabInput {
  key: string;
  title: string;
  icon?: IconInput | null;
  contentType: ContentReferenceType;
  contentUrl?: string | null;
  contentKey?: string | null;
  default: boolean;
}

export interface AppThemesColorsInput {
  light?: AppDesignColorInput | null;
  dark?: AppDesignColorInput | null;
}

export interface ContentPageBlockInput {
  type: ContentPageBlockType;
  body: string;
  key: string;
}

export interface ContentPageBody {
  designerData: string;
  type: ContentPageType;
  blocks?: ContentPageBlockInput[] | null;
  url?: string | null;
  html?: string | null;
}

export interface ContentPageCreateInput {
  name: string;
  body: ContentPageBody;
}

export interface ContentPageUpdateInput {
  name?: string | null;
  body?: ContentPageBody | null;
}

export interface GooglePlayConnectInput {
  authType: GooglePlayConnectAuthType;
  credentials: string;
}

export interface IconInput {
  collection: string;
  name: string;
}

export interface NewAppVersionInput {
  platform: AppPlatforms;
  version?: string | null;
  buildPreview: boolean;
  buildRelease: boolean;
  buildAppetize: boolean;
  buildMall: boolean;
}

export interface PartnerInput {
  title: string;
  email: string;
  slug: string;
  payoutPercent: number;
  notes?: string | null;
}

export interface PricingInput {
  title: string;
  description: string;
  trialDays: number;
  price: number;
  isDefault?: boolean | null;
  capabilities?: string | null;
  hidden?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

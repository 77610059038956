import { Card, Layout, Page } from "@shopify/polaris"
import React from "react"
import { useRouteMatch } from "react-router";
import { useClient } from "../../api/useClient";

export const AdminPartnerPayout = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch<{ id: string }>();
    const { id } = match.params;

    // If status === pending && total > 0 show button: 
    // {
    //     content: 'Set as paid',
    //     onAction: setAsPaid
    // }

    return (
        <Page title="Partner payout">
            <Layout>
                <Card>
                    <Card.Section>

                    </Card.Section>
                </Card>
            </Layout>
        </Page>
    )
})
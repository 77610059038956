import { Button, Card, Layout, Page, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { useClient } from "../api/useClient";

export const AdminActions = React.memo(() => {
    const client = useClient();
    const [updateResult, setUpdateResult] = useState('');
    const [loading, setLoading] = useState(false);

    const onUpdateBanners = useCallback(
        () => {
            setLoading(true);
            client
                .mutateAdminUpdateSmartBannerAccounts()
                .then((result) => {
                    setUpdateResult(result.adminUpdateSmartBannerAccounts ? 'Up to date' : 'Failed');
                })
                .catch((error) => {
                    console.log(error);
                    setUpdateResult('Failed');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [client, setUpdateResult],
    );

    return (
        <Page title="Actions">
            <Layout>
                <Layout.Section>
                    <Card title={'General'}>
                        <Card.Section>
                            <Stack vertical>
                                <Stack alignment="center">
                                    <TextStyle>
                                        {'Get all accounts and update smart banner with the latest code'}
                                    </TextStyle>
                                    <Button loading={loading} onClick={onUpdateBanners}>
                                        {'Update'}
                                    </Button>
                                </Stack>
                                <Stack>
                                    <TextStyle>
                                        {'Result: '}
                                    </TextStyle>
                                    <TextStyle>
                                        {updateResult ? updateResult : 'no result'}
                                    </TextStyle>
                                </Stack>
                            </Stack>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
});
import { Badge, Card, Heading, Pagination, ResourceItem, ResourceList, Scrollable, Select, Stack, TextStyle } from "@shopify/polaris";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { AdminAccountMonitoringList_adminAccountMonitoringEvents } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { CleanSpaceX } from "./types";

type AdminAccountMonitoringEventResult = CleanSpaceX<AdminAccountMonitoringList_adminAccountMonitoringEvents>;

export const AccountMonitoringEvents = React.memo((
    { id }: { id: string }
) => {
    const client = useClient();

    const [pageSize, setPageSize] = useState<string>('15');
    const [results, setResults] = useState<AdminAccountMonitoringEventResult>();
    const [loading, setLoading] = useState<boolean>(false);

    const loadNextPage = useCallback(() => {
        const queryData = { accountId: id, first: parseInt(pageSize), after: results?.pageInfo.endCursor };
        console.log('Load next page', queryData)
        setLoading(true);
        client.queryAdminAccountMonitoringList(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminAccountMonitoringEvents);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [pageSize, results]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            console.log('No previous page', results);
            throw new Error("No previous page");
        }
        const queryData = { accountId: id, last: parseInt(pageSize), before: results.pageInfo.startCursor };
        console.log('Load next page', queryData);
        setLoading(true);
        client.queryAdminAccountMonitoringList(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminAccountMonitoringEvents);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [pageSize, results]);

    useEffect(() => {
        loadNextPage();
    }, [pageSize]);

    return (
        <Card
            title={<Heading>Monitoring</Heading>}
        >
            <Card.Section>
                <Scrollable style={{ height: "400px" }} shadow focusable>
                    <ResourceList
                        items={results?.edges || []}
                        loading={loading}
                        filterControl={(
                            <Stack distribution={"leading"}>
                                <Stack.Item>
                                    <Select
                                        label={"Page size"}
                                        labelInline
                                        value={pageSize}
                                        options={[
                                            { label: '5', value: '5' },
                                            { label: '10', value: '10' },
                                            { label: '15', value: '15' },
                                            { label: '30', value: '30' }
                                        ]}
                                        onChange={(value) => {
                                            setResults(undefined);
                                            setPageSize(value);
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>
                        )}
                        renderItem={(item) => {
                            const node = item.node;
                            return (<ResourceItem
                                id={node.id}
                                onClick={() => { }}
                            >
                                <h3>
                                    <TextStyle variation={"strong"}>{node.status}</TextStyle>
                                </h3>
                                <View>
                                    <Stack>
                                        {node.message && <Stack.Item>
                                            {node.message}
                                        </Stack.Item>}
                                        <Stack.Item>
                                            <Badge
                                                status={"info"}>{moment(parseInt(node.createdAt)).format('lll')}</Badge>
                                        </Stack.Item>
                                    </Stack>
                                </View>
                            </ResourceItem>);
                        }}
                    />
                </Scrollable>
            </Card.Section>
            {
                (results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) &&
                <Card.Section>
                    <Stack distribution={"center"}>
                        <Pagination
                            hasPrevious={results?.pageInfo.hasPreviousPage}
                            onPrevious={() => {
                                loadPrevPage()
                            }}
                            hasNext={results?.pageInfo.hasNextPage}
                            onNext={() => {
                                loadNextPage()
                            }}
                        />
                    </Stack>
                </Card.Section>
            }
        </Card>
    );
});
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import { Frame, Layout, Navigation, Page, ThemeProvider, TopBar } from '@shopify/polaris';
import { useClient } from "../api/useClient";
import { Switch, useHistory, useLocation } from "react-router";
import { Route } from "../routing/routing";
import { AdminAppCardComponent } from "./AdminAppCardComponent";
import { useAdminAuthContext } from "../auth/AdminAuthLoader";
import { AdminBillingComponent } from "./AdminBillingComponent";
import { AdminAccountsComponent } from "./AdminAccountsComponent";
import { AccountEditComponent } from "./AccountEditComponent";
import { AdminAppCardEditComponent } from "./AdminAppCardEditComponent";
import { AdminSurveyIndustryList } from "./AdminSurveyIndustryList";
import { AdminEditSurveyIndustry } from "./AdminEditSurveyIndustry";
import { EditPricing } from "./billing/EditPricing";
import { Suspense } from "../components/Suspense";
import { AccountsStatistics } from "../components/AccountsStatistics";
import { AdminPartners } from "./partners/AdminPartners";
import { AdminPartner } from "./partners/AdminPartner";
import { AdminPartnerNew } from "./partners/AdminPartnerNew";
import { AdminPartnerPayout } from "./partners/AdminPartnerPayout";
import { AdminPartnerEdit } from "./partners/AdminPartnerEdit";
import { AppStatistics } from "../components/AppStatistics";
import { SkeletonSuspense, SkeletonType } from "../components/Skeleton/SkeletonSuspense";
import { AdminActions } from "./AdminActions";

export type ColorScheme = 'light' | 'dark' | 'inverse';
export const THEME_LOCALSTORAGE_KEY = 'cartmateadmin.theme';
export const AdminAppComponent = React.memo(() => {
    const history = useHistory();

    const client = useClient();
    const authProvider = useAdminAuthContext();

    const [theme, setTheme] = useState<ColorScheme>(localStorage.getItem(THEME_LOCALSTORAGE_KEY) as ColorScheme || 'light');

    const adminUser = client.useAdminUser().adminUser;
    const [isUserMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const [isNavigationOpen, setIsNavigationOpen] = useState(false);
    const toggleIsUserMenuOpen = useCallback(
        () => setUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
        []);
    const handleSwitchTheme = useCallback(() => {
        const newTheme = theme === 'dark' ? 'light' : 'dark';
        setTheme((theme) => {
            const newTheme: ColorScheme = theme === 'dark' ? 'light' : 'dark';
            localStorage.setItem(THEME_LOCALSTORAGE_KEY, newTheme);
            return newTheme;
        });
    }, []);

    console.log('History', history);
    const userMenuMarkup = (
        <TopBar.UserMenu
            actions={[
                { items: [{ content: 'Switch theme', onAction: handleSwitchTheme }] },
                { items: [{ content: "Logout", onAction: () => { } }] },
            ]}
            name={adminUser.email}
            initials={adminUser.email[0]}
            open={isUserMenuOpen}
            onToggle={toggleIsUserMenuOpen} />
    );

    const handleNavigationToggle = useCallback(() => {
        console.log('toggle navigation visibility');
        setIsNavigationOpen(!isNavigationOpen);
    }, [isNavigationOpen, setIsNavigationOpen]);

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
            onNavigationToggle={handleNavigationToggle}
        />
    );

    const location = useLocation();

    return (
        <ThemeProvider theme={{ colorScheme: theme }}>
            <Frame
                showMobileNavigation={isNavigationOpen}
                topBar={topBarMarkup}
                navigation={(
                    <Navigation location={location.pathname}>
                        <Navigation.Section items={[
                            { label: 'Dashboard', url: '/dashboard' },
                            { label: 'App cards', url: '/cards' },
                            { label: 'Pricing Plans', url: '/billing' },
                            { label: 'Accounts', url: '/accounts' },
                            { label: 'Survey Industries', url: '/survey/industry' },
                            { label: 'Partners', url: '/partners' },
                            { label: 'Actions', url: '/actions' },
                        ]} />
                    </Navigation>)
                }
                onNavigationDismiss={handleNavigationToggle}
            >
                <Switch>
                    <Route path={"/cards/:cardId/edit"} component={AdminAppCardEditComponent} />
                    <Route path={"/cards"} component={AdminAppCardComponent} />
                    <Route path={"/billing/pricing/:pricingId/edit"} component={EditPricing} />
                    <Route path={"/billing/pricing/add"} component={EditPricing} />
                    <Route path={"/billing"} component={AdminBillingComponent} />
                    <Route path={"/accounts/:accountId/edit"} component={AccountEditComponent} />
                    <Route path={"/accounts"} component={AdminAccountsComponent} />
                    <Route path={"/survey/industry/:industryId/edit"} component={AdminEditSurveyIndustry} />
                    <Route path={"/survey/industry/new"} component={AdminEditSurveyIndustry} />
                    <Route path={"/survey/industry"} component={AdminSurveyIndustryList} />
                    <Route path={"/partners"} component={AdminPartners} />
                    <Route path={"/partner/:id/view"} component={AdminPartner} />
                    <Route path={"/partner/new"} component={AdminPartnerNew} />
                    <Route path={"/partner/:partnerId/edit"} component={AdminPartnerEdit} />
                    <Route path={"/partner_payouts/:id"} component={AdminPartnerPayout} />
                    <Route path={"/actions"} component={AdminActions} />
                    <View>
                        <Page title={"Dashboard"}>
                            <Layout>
                                <Layout.Section>
                                    <SkeletonSuspense type={SkeletonType.Card} style={{ marginTop: 20 }}>
                                        <AccountsStatistics />
                                    </SkeletonSuspense>
                                </Layout.Section>
                                {/* 
                                    TODO: Mb sync data from mixpanel to db
                                    reason: Mixpanel query limit 60 per hour
                                 */}
                                {/* <Layout.Section>
                                    <SkeletonSuspense type={SkeletonType.Card} style={{ marginTop: 20 }}>
                                        <AppStatistics />
                                    </SkeletonSuspense>
                                </Layout.Section> */}
                            </Layout>
                        </Page>
                    </View>
                </Switch>
            </Frame>
        </ThemeProvider>
    );
});

export default AdminAppComponent;
import { Badge, Card, Modal, Pagination, ResourceItem, ResourceList, Select, Stack, TextStyle } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { useRouteMatch } from "react-router";
import { ListPricing_listPricing, ListPricing_listPricing_edges_node, PricingStatus } from "../api/spacex.types";
import { useClient } from "../api/useClient";
import { useConformContext } from "./modals/useConform";
import { CleanSpaceX } from "./types";

type PricingListResult = CleanSpaceX<ListPricing_listPricing>;

export const PricingList = React.memo(() => {
    const client = useClient();
    const match = useRouteMatch();
    const [pageSize, setPageSize] = useState<string>('10');
    const [results, setResults] = useState<PricingListResult>();
    const [loading, setLoading] = useState<boolean>(false);

    let nextQueryData = {
        first: parseInt(pageSize),
        after: results?.pageInfo.endCursor,
    };

    const loadNextPage = useCallback(() => {
        console.log('Load next page', nextQueryData);
        setLoading(true);
        client.queryAdminListPricing(nextQueryData)
            .then((result) => {
                console.log('Nex page loaded', result);
                setResults(result.adminListPricing);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [client, nextQueryData]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            throw new Error("No previous page");
        }
        const queryData = {
            last: parseInt(pageSize),
            before: results.pageInfo.startCursor,
        };
        console.log('Load prev page', queryData);
        setLoading(true);

        client.queryAdminListPricing(queryData)
            .then((result) => {
                console.log('Next page loaded', result);
                setResults(result.adminListPricing);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [pageSize, results]);

    const [modal, setConformOpen] = useConformContext('Are you sure you want to delete the pricing?')


    const handleRemovePricing = useCallback((entity: ListPricing_listPricing_edges_node) => {
        setConformOpen({
            onConform: () => {
                return new Promise((resolve, reject) => {
                    setLoading(true);
                    client.mutateAdminRemovePricing({ id: entity.id })
                        .then((result) => {
                            client.refetchAdminListPricing(nextQueryData).then((result) => {
                                setResults(result.adminListPricing);
                            }).catch(console.warn).finally(() => { setLoading(false) });
                            resolve();
                        })
                        .catch((e) => {
                            console.warn(e);
                            setLoading(false);
                            reject();
                        });
                });

            }
        })
    }, [])


    useEffect(() => {
        loadNextPage();
    }, [pageSize]);

    return (
        <Card>
            <ResourceList
                items={results?.edges || []}
                loading={loading}
                filterControl={(
                    <Stack distribution={"leading"}>
                        <Stack.Item fill>
                            <Stack distribution={"leading"}>

                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Select
                                label={"Page size"}
                                labelInline
                                value={pageSize}
                                options={[
                                    { label: '5', value: '5' },
                                    { label: '10', value: '10' },
                                    { label: '30', value: '30' }
                                ]}
                                onChange={(value) => {
                                    setResults(undefined);
                                    setPageSize(value);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                )}
                renderItem={(item) => {
                    const node = item.node;
                    let statusBadge;
                    if (node.status === PricingStatus.ACTIVE) {
                        statusBadge = (<Badge status={"success"}>Active</Badge>);
                    } else if (node.status === PricingStatus.INACTIVE) {
                        statusBadge = (<Badge status={"critical"}>Inactive</Badge>);
                    }
                    return (
                        <ResourceItem
                            id={node.id}
                            onClick={(it) => {
                                console.log('item clicked', it);
                            }}
                            url={`${match.url}/pricing/${node.id}/edit`}
                            shortcutActions={[{
                                content: 'Remove',
                                onAction: () => { handleRemovePricing(node) }
                            }]}
                        >
                            <h3>
                                <TextStyle>{node.title}</TextStyle>
                            </h3>
                            <View>
                                <Stack>
                                    <Stack.Item>{statusBadge}</Stack.Item>
                                    <Stack.Item>{`Accounts: ${node.accounts}`}</Stack.Item>
                                </Stack>
                            </View>
                        </ResourceItem>
                    );
                }} />

            {(results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) && <Card.Section>
                <Stack distribution={"center"}>
                    <Pagination
                        hasPrevious={results?.pageInfo.hasPreviousPage}
                        onPrevious={() => {
                            loadPrevPage()
                        }}
                        hasNext={results?.pageInfo.hasNextPage}
                        onNext={() => {
                            loadNextPage()
                        }}
                    />
                </Stack>
            </Card.Section>}
            {modal}
        </Card>
    );
});
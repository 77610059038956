
export class CartmateException extends Error {
}

export class CartmateApiException extends CartmateException {
    constructor(public code:number, public endpoint?:string, message?: string) {
        super(message);
    }
}

export class CartmateApi500Exception extends CartmateApiException {
    constructor(endpoint?:string, message: string = 'Something went wrong') {
        super(500, endpoint, message);
    }
}

export class CartmateApiAuthException extends CartmateApiException {
    constructor(message: string = 'Unauthorized') {
        super(401, '/shopify/auth', message);
    }
}

export class CartmateClientException extends CartmateException {
}

export class CartmateNotImpElementException extends CartmateClientException {
    constructor(public elementType:string) {
        super();
    }
}
/* tslint:disable */
/* eslint-disable */
import * as Types from './spacex.types';
import { SpaceXClientParameters, GraphqlActiveSubscription, QueryParameters, MutationParameters, SubscriptionParameters, GraphqlSubscriptionHandler, BaseSpaceXClient, SpaceQueryWatchParameters } from '@openland/spacex';

export class ShopClient extends BaseSpaceXClient {
    constructor(params: SpaceXClientParameters) {
        super(params);
    }
    withParameters(params: Partial<SpaceXClientParameters>) {
        return new ShopClient({ ... params, engine: this.engine, globalCache: this.globalCache});
    }
    queryAccountWrapper(params?: QueryParameters): Promise<Types.AccountWrapper> {
        return this.query('AccountWrapper', undefined, params);
    }
    queryAdminListPricing(variables: Types.AdminListPricingVariables, params?: QueryParameters): Promise<Types.AdminListPricing> {
        return this.query('AdminListPricing', variables, params);
    }
    queryAdminAppCard(variables: Types.AdminAppCardVariables, params?: QueryParameters): Promise<Types.AdminAppCard> {
        return this.query('AdminAppCard', variables, params);
    }
    queryAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: QueryParameters): Promise<Types.AdminAccountMonitoringList> {
        return this.query('AdminAccountMonitoringList', variables, params);
    }
    queryAdminUser(params?: QueryParameters): Promise<Types.AdminUser> {
        return this.query('AdminUser', undefined, params);
    }
    queryAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: QueryParameters): Promise<Types.AdminAccountsList> {
        return this.query('AdminAccountsList', variables, params);
    }
    queryAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: QueryParameters): Promise<Types.AdminGetAccount> {
        return this.query('AdminGetAccount', variables, params);
    }
    queryAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: QueryParameters): Promise<Types.AdminGetAppCard> {
        return this.query('AdminGetAppCard', variables, params);
    }
    queryAdminSurveyIndustryList(params?: QueryParameters): Promise<Types.AdminSurveyIndustryList> {
        return this.query('AdminSurveyIndustryList', undefined, params);
    }
    queryAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, params?: QueryParameters): Promise<Types.AdminGetSurveyIndustry> {
        return this.query('AdminGetSurveyIndustry', variables, params);
    }
    queryPricingCapabilities(params?: QueryParameters): Promise<Types.PricingCapabilities> {
        return this.query('PricingCapabilities', undefined, params);
    }
    queryAdminAccountApp(variables: Types.AdminAccountAppVariables, params?: QueryParameters): Promise<Types.AdminAccountApp> {
        return this.query('AdminAccountApp', variables, params);
    }
    queryAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, params?: QueryParameters): Promise<Types.AdminAccountSyncState> {
        return this.query('AdminAccountSyncState', variables, params);
    }
    queryAdminShopifyWebhookTopics(params?: QueryParameters): Promise<Types.AdminShopifyWebhookTopics> {
        return this.query('AdminShopifyWebhookTopics', undefined, params);
    }
    queryAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, params?: QueryParameters): Promise<Types.AdminAccountWebhooks> {
        return this.query('AdminAccountWebhooks', variables, params);
    }
    queryAdminAppStat(variables: Types.AdminAppStatVariables, params?: QueryParameters): Promise<Types.AdminAppStat> {
        return this.query('AdminAppStat', variables, params);
    }
    queryAdminAccountsStatistics(params?: QueryParameters): Promise<Types.AdminAccountsStatistics> {
        return this.query('AdminAccountsStatistics', undefined, params);
    }
    queryAdminPartnersList(variables: Types.AdminPartnersListVariables, params?: QueryParameters): Promise<Types.AdminPartnersList> {
        return this.query('AdminPartnersList', variables, params);
    }
    queryAdminGetPartner(variables: Types.AdminGetPartnerVariables, params?: QueryParameters): Promise<Types.AdminGetPartner> {
        return this.query('AdminGetPartner', variables, params);
    }
    queryAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, params?: QueryParameters): Promise<Types.AdminPartnerStores> {
        return this.query('AdminPartnerStores', variables, params);
    }
    queryAppProfiles(variables: Types.AppProfilesVariables, params?: QueryParameters): Promise<Types.AppProfiles> {
        return this.query('AppProfiles', variables, params);
    }
    queryAllApps(params?: QueryParameters): Promise<Types.AllApps> {
        return this.query('AllApps', undefined, params);
    }
    queryApp(variables: Types.AppVariables, params?: QueryParameters): Promise<Types.App> {
        return this.query('App', variables, params);
    }
    queryPreviewToken(variables: Types.PreviewTokenVariables, params?: QueryParameters): Promise<Types.PreviewToken> {
        return this.query('PreviewToken', variables, params);
    }
    queryAppConfig(variables: Types.AppConfigVariables, params?: QueryParameters): Promise<Types.AppConfig> {
        return this.query('AppConfig', variables, params);
    }
    queryAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: QueryParameters): Promise<Types.AppEmulatorConfig> {
        return this.query('AppEmulatorConfig', variables, params);
    }
    queryAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: QueryParameters): Promise<Types.AppTabsConfig> {
        return this.query('AppTabsConfig', variables, params);
    }
    queryGetAppCard(variables: Types.GetAppCardVariables, params?: QueryParameters): Promise<Types.GetAppCard> {
        return this.query('GetAppCard', variables, params);
    }
    queryEnableBanner(variables: Types.EnableBannerVariables, params?: QueryParameters): Promise<Types.EnableBanner> {
        return this.query('EnableBanner', variables, params);
    }
    queryDisableBanner(variables: Types.DisableBannerVariables, params?: QueryParameters): Promise<Types.DisableBanner> {
        return this.query('DisableBanner', variables, params);
    }
    queryGetAppSettings(variables: Types.GetAppSettingsVariables, params?: QueryParameters): Promise<Types.GetAppSettings> {
        return this.query('GetAppSettings', variables, params);
    }
    queryAppStat(variables: Types.AppStatVariables, params?: QueryParameters): Promise<Types.AppStat> {
        return this.query('AppStat', variables, params);
    }
    queryGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: QueryParameters): Promise<Types.GetSiteScreenshot> {
        return this.query('GetSiteScreenshot', variables, params);
    }
    queryGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: QueryParameters): Promise<Types.GenerateSharLink> {
        return this.query('GenerateSharLink', variables, params);
    }
    queryAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: QueryParameters): Promise<Types.AppVersionsPage> {
        return this.query('AppVersionsPage', variables, params);
    }
    queryMyShop(params?: QueryParameters): Promise<Types.MyShop> {
        return this.query('MyShop', undefined, params);
    }
    queryPages(params?: QueryParameters): Promise<Types.Pages> {
        return this.query('Pages', undefined, params);
    }
    queryPage(variables: Types.PageVariables, params?: QueryParameters): Promise<Types.Page> {
        return this.query('Page', variables, params);
    }
    queryBlurHash(variables: Types.BlurHashVariables, params?: QueryParameters): Promise<Types.BlurHash> {
        return this.query('BlurHash', variables, params);
    }
    queryListPricing(variables: Types.ListPricingVariables, params?: QueryParameters): Promise<Types.ListPricing> {
        return this.query('ListPricing', variables, params);
    }
    queryDefaultPricing(params?: QueryParameters): Promise<Types.DefaultPricing> {
        return this.query('DefaultPricing', undefined, params);
    }
    queryPricing(variables: Types.PricingVariables, params?: QueryParameters): Promise<Types.Pricing> {
        return this.query('Pricing', variables, params);
    }
    queryProduct(variables: Types.ProductVariables, params?: QueryParameters): Promise<Types.Product> {
        return this.query('Product', variables, params);
    }
    querySearchListProducts(variables: Types.SearchListProductsVariables, params?: QueryParameters): Promise<Types.SearchListProducts> {
        return this.query('SearchListProducts', variables, params);
    }
    queryListProducts(variables: Types.ListProductsVariables, params?: QueryParameters): Promise<Types.ListProducts> {
        return this.query('ListProducts', variables, params);
    }
    querySettings(params?: QueryParameters): Promise<Types.Settings> {
        return this.query('Settings', undefined, params);
    }
    queryCollections(params?: QueryParameters): Promise<Types.Collections> {
        return this.query('Collections', undefined, params);
    }
    refetchAccountWrapper(params?: QueryParameters): Promise<Types.AccountWrapper> {
        return this.refetch('AccountWrapper', undefined, params);
    }
    refetchAdminListPricing(variables: Types.AdminListPricingVariables, params?: QueryParameters): Promise<Types.AdminListPricing> {
        return this.refetch('AdminListPricing', variables, params);
    }
    refetchAdminAppCard(variables: Types.AdminAppCardVariables, params?: QueryParameters): Promise<Types.AdminAppCard> {
        return this.refetch('AdminAppCard', variables, params);
    }
    refetchAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: QueryParameters): Promise<Types.AdminAccountMonitoringList> {
        return this.refetch('AdminAccountMonitoringList', variables, params);
    }
    refetchAdminUser(params?: QueryParameters): Promise<Types.AdminUser> {
        return this.refetch('AdminUser', undefined, params);
    }
    refetchAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: QueryParameters): Promise<Types.AdminAccountsList> {
        return this.refetch('AdminAccountsList', variables, params);
    }
    refetchAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: QueryParameters): Promise<Types.AdminGetAccount> {
        return this.refetch('AdminGetAccount', variables, params);
    }
    refetchAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: QueryParameters): Promise<Types.AdminGetAppCard> {
        return this.refetch('AdminGetAppCard', variables, params);
    }
    refetchAdminSurveyIndustryList(params?: QueryParameters): Promise<Types.AdminSurveyIndustryList> {
        return this.refetch('AdminSurveyIndustryList', undefined, params);
    }
    refetchAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, params?: QueryParameters): Promise<Types.AdminGetSurveyIndustry> {
        return this.refetch('AdminGetSurveyIndustry', variables, params);
    }
    refetchPricingCapabilities(params?: QueryParameters): Promise<Types.PricingCapabilities> {
        return this.refetch('PricingCapabilities', undefined, params);
    }
    refetchAdminAccountApp(variables: Types.AdminAccountAppVariables, params?: QueryParameters): Promise<Types.AdminAccountApp> {
        return this.refetch('AdminAccountApp', variables, params);
    }
    refetchAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, params?: QueryParameters): Promise<Types.AdminAccountSyncState> {
        return this.refetch('AdminAccountSyncState', variables, params);
    }
    refetchAdminShopifyWebhookTopics(params?: QueryParameters): Promise<Types.AdminShopifyWebhookTopics> {
        return this.refetch('AdminShopifyWebhookTopics', undefined, params);
    }
    refetchAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, params?: QueryParameters): Promise<Types.AdminAccountWebhooks> {
        return this.refetch('AdminAccountWebhooks', variables, params);
    }
    refetchAdminAppStat(variables: Types.AdminAppStatVariables, params?: QueryParameters): Promise<Types.AdminAppStat> {
        return this.refetch('AdminAppStat', variables, params);
    }
    refetchAdminAccountsStatistics(params?: QueryParameters): Promise<Types.AdminAccountsStatistics> {
        return this.refetch('AdminAccountsStatistics', undefined, params);
    }
    refetchAdminPartnersList(variables: Types.AdminPartnersListVariables, params?: QueryParameters): Promise<Types.AdminPartnersList> {
        return this.refetch('AdminPartnersList', variables, params);
    }
    refetchAdminGetPartner(variables: Types.AdminGetPartnerVariables, params?: QueryParameters): Promise<Types.AdminGetPartner> {
        return this.refetch('AdminGetPartner', variables, params);
    }
    refetchAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, params?: QueryParameters): Promise<Types.AdminPartnerStores> {
        return this.refetch('AdminPartnerStores', variables, params);
    }
    refetchAppProfiles(variables: Types.AppProfilesVariables, params?: QueryParameters): Promise<Types.AppProfiles> {
        return this.refetch('AppProfiles', variables, params);
    }
    refetchAllApps(params?: QueryParameters): Promise<Types.AllApps> {
        return this.refetch('AllApps', undefined, params);
    }
    refetchApp(variables: Types.AppVariables, params?: QueryParameters): Promise<Types.App> {
        return this.refetch('App', variables, params);
    }
    refetchPreviewToken(variables: Types.PreviewTokenVariables, params?: QueryParameters): Promise<Types.PreviewToken> {
        return this.refetch('PreviewToken', variables, params);
    }
    refetchAppConfig(variables: Types.AppConfigVariables, params?: QueryParameters): Promise<Types.AppConfig> {
        return this.refetch('AppConfig', variables, params);
    }
    refetchAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: QueryParameters): Promise<Types.AppEmulatorConfig> {
        return this.refetch('AppEmulatorConfig', variables, params);
    }
    refetchAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: QueryParameters): Promise<Types.AppTabsConfig> {
        return this.refetch('AppTabsConfig', variables, params);
    }
    refetchGetAppCard(variables: Types.GetAppCardVariables, params?: QueryParameters): Promise<Types.GetAppCard> {
        return this.refetch('GetAppCard', variables, params);
    }
    refetchEnableBanner(variables: Types.EnableBannerVariables, params?: QueryParameters): Promise<Types.EnableBanner> {
        return this.refetch('EnableBanner', variables, params);
    }
    refetchDisableBanner(variables: Types.DisableBannerVariables, params?: QueryParameters): Promise<Types.DisableBanner> {
        return this.refetch('DisableBanner', variables, params);
    }
    refetchGetAppSettings(variables: Types.GetAppSettingsVariables, params?: QueryParameters): Promise<Types.GetAppSettings> {
        return this.refetch('GetAppSettings', variables, params);
    }
    refetchAppStat(variables: Types.AppStatVariables, params?: QueryParameters): Promise<Types.AppStat> {
        return this.refetch('AppStat', variables, params);
    }
    refetchGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: QueryParameters): Promise<Types.GetSiteScreenshot> {
        return this.refetch('GetSiteScreenshot', variables, params);
    }
    refetchGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: QueryParameters): Promise<Types.GenerateSharLink> {
        return this.refetch('GenerateSharLink', variables, params);
    }
    refetchAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: QueryParameters): Promise<Types.AppVersionsPage> {
        return this.refetch('AppVersionsPage', variables, params);
    }
    refetchMyShop(params?: QueryParameters): Promise<Types.MyShop> {
        return this.refetch('MyShop', undefined, params);
    }
    refetchPages(params?: QueryParameters): Promise<Types.Pages> {
        return this.refetch('Pages', undefined, params);
    }
    refetchPage(variables: Types.PageVariables, params?: QueryParameters): Promise<Types.Page> {
        return this.refetch('Page', variables, params);
    }
    refetchBlurHash(variables: Types.BlurHashVariables, params?: QueryParameters): Promise<Types.BlurHash> {
        return this.refetch('BlurHash', variables, params);
    }
    refetchListPricing(variables: Types.ListPricingVariables, params?: QueryParameters): Promise<Types.ListPricing> {
        return this.refetch('ListPricing', variables, params);
    }
    refetchDefaultPricing(params?: QueryParameters): Promise<Types.DefaultPricing> {
        return this.refetch('DefaultPricing', undefined, params);
    }
    refetchPricing(variables: Types.PricingVariables, params?: QueryParameters): Promise<Types.Pricing> {
        return this.refetch('Pricing', variables, params);
    }
    refetchProduct(variables: Types.ProductVariables, params?: QueryParameters): Promise<Types.Product> {
        return this.refetch('Product', variables, params);
    }
    refetchSearchListProducts(variables: Types.SearchListProductsVariables, params?: QueryParameters): Promise<Types.SearchListProducts> {
        return this.refetch('SearchListProducts', variables, params);
    }
    refetchListProducts(variables: Types.ListProductsVariables, params?: QueryParameters): Promise<Types.ListProducts> {
        return this.refetch('ListProducts', variables, params);
    }
    refetchSettings(params?: QueryParameters): Promise<Types.Settings> {
        return this.refetch('Settings', undefined, params);
    }
    refetchCollections(params?: QueryParameters): Promise<Types.Collections> {
        return this.refetch('Collections', undefined, params);
    }
    updateAccountWrapper(updater: (data: Types.AccountWrapper) => Types.AccountWrapper | null): Promise<boolean> {
        return this.updateQuery(updater, 'AccountWrapper', undefined);
    }
    updateAdminListPricing(variables: Types.AdminListPricingVariables, updater: (data: Types.AdminListPricing) => Types.AdminListPricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminListPricing', variables);
    }
    updateAdminAppCard(variables: Types.AdminAppCardVariables, updater: (data: Types.AdminAppCard) => Types.AdminAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAppCard', variables);
    }
    updateAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, updater: (data: Types.AdminAccountMonitoringList) => Types.AdminAccountMonitoringList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountMonitoringList', variables);
    }
    updateAdminUser(updater: (data: Types.AdminUser) => Types.AdminUser | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminUser', undefined);
    }
    updateAdminAccountsList(variables: Types.AdminAccountsListVariables, updater: (data: Types.AdminAccountsList) => Types.AdminAccountsList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountsList', variables);
    }
    updateAdminGetAccount(variables: Types.AdminGetAccountVariables, updater: (data: Types.AdminGetAccount) => Types.AdminGetAccount | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetAccount', variables);
    }
    updateAdminGetAppCard(variables: Types.AdminGetAppCardVariables, updater: (data: Types.AdminGetAppCard) => Types.AdminGetAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetAppCard', variables);
    }
    updateAdminSurveyIndustryList(updater: (data: Types.AdminSurveyIndustryList) => Types.AdminSurveyIndustryList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminSurveyIndustryList', undefined);
    }
    updateAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, updater: (data: Types.AdminGetSurveyIndustry) => Types.AdminGetSurveyIndustry | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetSurveyIndustry', variables);
    }
    updatePricingCapabilities(updater: (data: Types.PricingCapabilities) => Types.PricingCapabilities | null): Promise<boolean> {
        return this.updateQuery(updater, 'PricingCapabilities', undefined);
    }
    updateAdminAccountApp(variables: Types.AdminAccountAppVariables, updater: (data: Types.AdminAccountApp) => Types.AdminAccountApp | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountApp', variables);
    }
    updateAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, updater: (data: Types.AdminAccountSyncState) => Types.AdminAccountSyncState | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountSyncState', variables);
    }
    updateAdminShopifyWebhookTopics(updater: (data: Types.AdminShopifyWebhookTopics) => Types.AdminShopifyWebhookTopics | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminShopifyWebhookTopics', undefined);
    }
    updateAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, updater: (data: Types.AdminAccountWebhooks) => Types.AdminAccountWebhooks | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountWebhooks', variables);
    }
    updateAdminAppStat(variables: Types.AdminAppStatVariables, updater: (data: Types.AdminAppStat) => Types.AdminAppStat | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAppStat', variables);
    }
    updateAdminAccountsStatistics(updater: (data: Types.AdminAccountsStatistics) => Types.AdminAccountsStatistics | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminAccountsStatistics', undefined);
    }
    updateAdminPartnersList(variables: Types.AdminPartnersListVariables, updater: (data: Types.AdminPartnersList) => Types.AdminPartnersList | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminPartnersList', variables);
    }
    updateAdminGetPartner(variables: Types.AdminGetPartnerVariables, updater: (data: Types.AdminGetPartner) => Types.AdminGetPartner | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminGetPartner', variables);
    }
    updateAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, updater: (data: Types.AdminPartnerStores) => Types.AdminPartnerStores | null): Promise<boolean> {
        return this.updateQuery(updater, 'AdminPartnerStores', variables);
    }
    updateAppProfiles(variables: Types.AppProfilesVariables, updater: (data: Types.AppProfiles) => Types.AppProfiles | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppProfiles', variables);
    }
    updateAllApps(updater: (data: Types.AllApps) => Types.AllApps | null): Promise<boolean> {
        return this.updateQuery(updater, 'AllApps', undefined);
    }
    updateApp(variables: Types.AppVariables, updater: (data: Types.App) => Types.App | null): Promise<boolean> {
        return this.updateQuery(updater, 'App', variables);
    }
    updatePreviewToken(variables: Types.PreviewTokenVariables, updater: (data: Types.PreviewToken) => Types.PreviewToken | null): Promise<boolean> {
        return this.updateQuery(updater, 'PreviewToken', variables);
    }
    updateAppConfig(variables: Types.AppConfigVariables, updater: (data: Types.AppConfig) => Types.AppConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppConfig', variables);
    }
    updateAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, updater: (data: Types.AppEmulatorConfig) => Types.AppEmulatorConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppEmulatorConfig', variables);
    }
    updateAppTabsConfig(variables: Types.AppTabsConfigVariables, updater: (data: Types.AppTabsConfig) => Types.AppTabsConfig | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppTabsConfig', variables);
    }
    updateGetAppCard(variables: Types.GetAppCardVariables, updater: (data: Types.GetAppCard) => Types.GetAppCard | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetAppCard', variables);
    }
    updateEnableBanner(variables: Types.EnableBannerVariables, updater: (data: Types.EnableBanner) => Types.EnableBanner | null): Promise<boolean> {
        return this.updateQuery(updater, 'EnableBanner', variables);
    }
    updateDisableBanner(variables: Types.DisableBannerVariables, updater: (data: Types.DisableBanner) => Types.DisableBanner | null): Promise<boolean> {
        return this.updateQuery(updater, 'DisableBanner', variables);
    }
    updateGetAppSettings(variables: Types.GetAppSettingsVariables, updater: (data: Types.GetAppSettings) => Types.GetAppSettings | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetAppSettings', variables);
    }
    updateAppStat(variables: Types.AppStatVariables, updater: (data: Types.AppStat) => Types.AppStat | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppStat', variables);
    }
    updateGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, updater: (data: Types.GetSiteScreenshot) => Types.GetSiteScreenshot | null): Promise<boolean> {
        return this.updateQuery(updater, 'GetSiteScreenshot', variables);
    }
    updateGenerateSharLink(variables: Types.GenerateSharLinkVariables, updater: (data: Types.GenerateSharLink) => Types.GenerateSharLink | null): Promise<boolean> {
        return this.updateQuery(updater, 'GenerateSharLink', variables);
    }
    updateAppVersionsPage(variables: Types.AppVersionsPageVariables, updater: (data: Types.AppVersionsPage) => Types.AppVersionsPage | null): Promise<boolean> {
        return this.updateQuery(updater, 'AppVersionsPage', variables);
    }
    updateMyShop(updater: (data: Types.MyShop) => Types.MyShop | null): Promise<boolean> {
        return this.updateQuery(updater, 'MyShop', undefined);
    }
    updatePages(updater: (data: Types.Pages) => Types.Pages | null): Promise<boolean> {
        return this.updateQuery(updater, 'Pages', undefined);
    }
    updatePage(variables: Types.PageVariables, updater: (data: Types.Page) => Types.Page | null): Promise<boolean> {
        return this.updateQuery(updater, 'Page', variables);
    }
    updateBlurHash(variables: Types.BlurHashVariables, updater: (data: Types.BlurHash) => Types.BlurHash | null): Promise<boolean> {
        return this.updateQuery(updater, 'BlurHash', variables);
    }
    updateListPricing(variables: Types.ListPricingVariables, updater: (data: Types.ListPricing) => Types.ListPricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListPricing', variables);
    }
    updateDefaultPricing(updater: (data: Types.DefaultPricing) => Types.DefaultPricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'DefaultPricing', undefined);
    }
    updatePricing(variables: Types.PricingVariables, updater: (data: Types.Pricing) => Types.Pricing | null): Promise<boolean> {
        return this.updateQuery(updater, 'Pricing', variables);
    }
    updateProduct(variables: Types.ProductVariables, updater: (data: Types.Product) => Types.Product | null): Promise<boolean> {
        return this.updateQuery(updater, 'Product', variables);
    }
    updateSearchListProducts(variables: Types.SearchListProductsVariables, updater: (data: Types.SearchListProducts) => Types.SearchListProducts | null): Promise<boolean> {
        return this.updateQuery(updater, 'SearchListProducts', variables);
    }
    updateListProducts(variables: Types.ListProductsVariables, updater: (data: Types.ListProducts) => Types.ListProducts | null): Promise<boolean> {
        return this.updateQuery(updater, 'ListProducts', variables);
    }
    updateSettings(updater: (data: Types.Settings) => Types.Settings | null): Promise<boolean> {
        return this.updateQuery(updater, 'Settings', undefined);
    }
    updateCollections(updater: (data: Types.Collections) => Types.Collections | null): Promise<boolean> {
        return this.updateQuery(updater, 'Collections', undefined);
    }
    useAccountWrapper(params: SpaceQueryWatchParameters & { suspense: false }): Types.AccountWrapper | null;
    useAccountWrapper(params?: SpaceQueryWatchParameters): Types.AccountWrapper;
    useAccountWrapper(params?: SpaceQueryWatchParameters): Types.AccountWrapper | null {;
        return this.useQuery('AccountWrapper', undefined, params);
    }
    useAdminListPricing(variables: Types.AdminListPricingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminListPricing | null;
    useAdminListPricing(variables: Types.AdminListPricingVariables, params?: SpaceQueryWatchParameters): Types.AdminListPricing;
    useAdminListPricing(variables: Types.AdminListPricingVariables, params?: SpaceQueryWatchParameters): Types.AdminListPricing | null {;
        return this.useQuery('AdminListPricing', variables, params);
    }
    useAdminAppCard(variables: Types.AdminAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAppCard | null;
    useAdminAppCard(variables: Types.AdminAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminAppCard;
    useAdminAppCard(variables: Types.AdminAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminAppCard | null {;
        return this.useQuery('AdminAppCard', variables, params);
    }
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountMonitoringList | null;
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountMonitoringList;
    useAdminAccountMonitoringList(variables: Types.AdminAccountMonitoringListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountMonitoringList | null {;
        return this.useQuery('AdminAccountMonitoringList', variables, params);
    }
    useAdminUser(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminUser | null;
    useAdminUser(params?: SpaceQueryWatchParameters): Types.AdminUser;
    useAdminUser(params?: SpaceQueryWatchParameters): Types.AdminUser | null {;
        return this.useQuery('AdminUser', undefined, params);
    }
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountsList | null;
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountsList;
    useAdminAccountsList(variables: Types.AdminAccountsListVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountsList | null {;
        return this.useQuery('AdminAccountsList', variables, params);
    }
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetAccount | null;
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAccount;
    useAdminGetAccount(variables: Types.AdminGetAccountVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAccount | null {;
        return this.useQuery('AdminGetAccount', variables, params);
    }
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetAppCard | null;
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAppCard;
    useAdminGetAppCard(variables: Types.AdminGetAppCardVariables, params?: SpaceQueryWatchParameters): Types.AdminGetAppCard | null {;
        return this.useQuery('AdminGetAppCard', variables, params);
    }
    useAdminSurveyIndustryList(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminSurveyIndustryList | null;
    useAdminSurveyIndustryList(params?: SpaceQueryWatchParameters): Types.AdminSurveyIndustryList;
    useAdminSurveyIndustryList(params?: SpaceQueryWatchParameters): Types.AdminSurveyIndustryList | null {;
        return this.useQuery('AdminSurveyIndustryList', undefined, params);
    }
    useAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetSurveyIndustry | null;
    useAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, params?: SpaceQueryWatchParameters): Types.AdminGetSurveyIndustry;
    useAdminGetSurveyIndustry(variables: Types.AdminGetSurveyIndustryVariables, params?: SpaceQueryWatchParameters): Types.AdminGetSurveyIndustry | null {;
        return this.useQuery('AdminGetSurveyIndustry', variables, params);
    }
    usePricingCapabilities(params: SpaceQueryWatchParameters & { suspense: false }): Types.PricingCapabilities | null;
    usePricingCapabilities(params?: SpaceQueryWatchParameters): Types.PricingCapabilities;
    usePricingCapabilities(params?: SpaceQueryWatchParameters): Types.PricingCapabilities | null {;
        return this.useQuery('PricingCapabilities', undefined, params);
    }
    useAdminAccountApp(variables: Types.AdminAccountAppVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountApp | null;
    useAdminAccountApp(variables: Types.AdminAccountAppVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountApp;
    useAdminAccountApp(variables: Types.AdminAccountAppVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountApp | null {;
        return this.useQuery('AdminAccountApp', variables, params);
    }
    useAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountSyncState | null;
    useAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountSyncState;
    useAdminAccountSyncState(variables: Types.AdminAccountSyncStateVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountSyncState | null {;
        return this.useQuery('AdminAccountSyncState', variables, params);
    }
    useAdminShopifyWebhookTopics(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminShopifyWebhookTopics | null;
    useAdminShopifyWebhookTopics(params?: SpaceQueryWatchParameters): Types.AdminShopifyWebhookTopics;
    useAdminShopifyWebhookTopics(params?: SpaceQueryWatchParameters): Types.AdminShopifyWebhookTopics | null {;
        return this.useQuery('AdminShopifyWebhookTopics', undefined, params);
    }
    useAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountWebhooks | null;
    useAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountWebhooks;
    useAdminAccountWebhooks(variables: Types.AdminAccountWebhooksVariables, params?: SpaceQueryWatchParameters): Types.AdminAccountWebhooks | null {;
        return this.useQuery('AdminAccountWebhooks', variables, params);
    }
    useAdminAppStat(variables: Types.AdminAppStatVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAppStat | null;
    useAdminAppStat(variables: Types.AdminAppStatVariables, params?: SpaceQueryWatchParameters): Types.AdminAppStat;
    useAdminAppStat(variables: Types.AdminAppStatVariables, params?: SpaceQueryWatchParameters): Types.AdminAppStat | null {;
        return this.useQuery('AdminAppStat', variables, params);
    }
    useAdminAccountsStatistics(params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminAccountsStatistics | null;
    useAdminAccountsStatistics(params?: SpaceQueryWatchParameters): Types.AdminAccountsStatistics;
    useAdminAccountsStatistics(params?: SpaceQueryWatchParameters): Types.AdminAccountsStatistics | null {;
        return this.useQuery('AdminAccountsStatistics', undefined, params);
    }
    useAdminPartnersList(variables: Types.AdminPartnersListVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminPartnersList | null;
    useAdminPartnersList(variables: Types.AdminPartnersListVariables, params?: SpaceQueryWatchParameters): Types.AdminPartnersList;
    useAdminPartnersList(variables: Types.AdminPartnersListVariables, params?: SpaceQueryWatchParameters): Types.AdminPartnersList | null {;
        return this.useQuery('AdminPartnersList', variables, params);
    }
    useAdminGetPartner(variables: Types.AdminGetPartnerVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminGetPartner | null;
    useAdminGetPartner(variables: Types.AdminGetPartnerVariables, params?: SpaceQueryWatchParameters): Types.AdminGetPartner;
    useAdminGetPartner(variables: Types.AdminGetPartnerVariables, params?: SpaceQueryWatchParameters): Types.AdminGetPartner | null {;
        return this.useQuery('AdminGetPartner', variables, params);
    }
    useAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AdminPartnerStores | null;
    useAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, params?: SpaceQueryWatchParameters): Types.AdminPartnerStores;
    useAdminPartnerStores(variables: Types.AdminPartnerStoresVariables, params?: SpaceQueryWatchParameters): Types.AdminPartnerStores | null {;
        return this.useQuery('AdminPartnerStores', variables, params);
    }
    useAppProfiles(variables: Types.AppProfilesVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppProfiles | null;
    useAppProfiles(variables: Types.AppProfilesVariables, params?: SpaceQueryWatchParameters): Types.AppProfiles;
    useAppProfiles(variables: Types.AppProfilesVariables, params?: SpaceQueryWatchParameters): Types.AppProfiles | null {;
        return this.useQuery('AppProfiles', variables, params);
    }
    useAllApps(params: SpaceQueryWatchParameters & { suspense: false }): Types.AllApps | null;
    useAllApps(params?: SpaceQueryWatchParameters): Types.AllApps;
    useAllApps(params?: SpaceQueryWatchParameters): Types.AllApps | null {;
        return this.useQuery('AllApps', undefined, params);
    }
    useApp(variables: Types.AppVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.App | null;
    useApp(variables: Types.AppVariables, params?: SpaceQueryWatchParameters): Types.App;
    useApp(variables: Types.AppVariables, params?: SpaceQueryWatchParameters): Types.App | null {;
        return this.useQuery('App', variables, params);
    }
    usePreviewToken(variables: Types.PreviewTokenVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.PreviewToken | null;
    usePreviewToken(variables: Types.PreviewTokenVariables, params?: SpaceQueryWatchParameters): Types.PreviewToken;
    usePreviewToken(variables: Types.PreviewTokenVariables, params?: SpaceQueryWatchParameters): Types.PreviewToken | null {;
        return this.useQuery('PreviewToken', variables, params);
    }
    useAppConfig(variables: Types.AppConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppConfig | null;
    useAppConfig(variables: Types.AppConfigVariables, params?: SpaceQueryWatchParameters): Types.AppConfig;
    useAppConfig(variables: Types.AppConfigVariables, params?: SpaceQueryWatchParameters): Types.AppConfig | null {;
        return this.useQuery('AppConfig', variables, params);
    }
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppEmulatorConfig | null;
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: SpaceQueryWatchParameters): Types.AppEmulatorConfig;
    useAppEmulatorConfig(variables: Types.AppEmulatorConfigVariables, params?: SpaceQueryWatchParameters): Types.AppEmulatorConfig | null {;
        return this.useQuery('AppEmulatorConfig', variables, params);
    }
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppTabsConfig | null;
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: SpaceQueryWatchParameters): Types.AppTabsConfig;
    useAppTabsConfig(variables: Types.AppTabsConfigVariables, params?: SpaceQueryWatchParameters): Types.AppTabsConfig | null {;
        return this.useQuery('AppTabsConfig', variables, params);
    }
    useGetAppCard(variables: Types.GetAppCardVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetAppCard | null;
    useGetAppCard(variables: Types.GetAppCardVariables, params?: SpaceQueryWatchParameters): Types.GetAppCard;
    useGetAppCard(variables: Types.GetAppCardVariables, params?: SpaceQueryWatchParameters): Types.GetAppCard | null {;
        return this.useQuery('GetAppCard', variables, params);
    }
    useEnableBanner(variables: Types.EnableBannerVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.EnableBanner | null;
    useEnableBanner(variables: Types.EnableBannerVariables, params?: SpaceQueryWatchParameters): Types.EnableBanner;
    useEnableBanner(variables: Types.EnableBannerVariables, params?: SpaceQueryWatchParameters): Types.EnableBanner | null {;
        return this.useQuery('EnableBanner', variables, params);
    }
    useDisableBanner(variables: Types.DisableBannerVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.DisableBanner | null;
    useDisableBanner(variables: Types.DisableBannerVariables, params?: SpaceQueryWatchParameters): Types.DisableBanner;
    useDisableBanner(variables: Types.DisableBannerVariables, params?: SpaceQueryWatchParameters): Types.DisableBanner | null {;
        return this.useQuery('DisableBanner', variables, params);
    }
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetAppSettings | null;
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params?: SpaceQueryWatchParameters): Types.GetAppSettings;
    useGetAppSettings(variables: Types.GetAppSettingsVariables, params?: SpaceQueryWatchParameters): Types.GetAppSettings | null {;
        return this.useQuery('GetAppSettings', variables, params);
    }
    useAppStat(variables: Types.AppStatVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppStat | null;
    useAppStat(variables: Types.AppStatVariables, params?: SpaceQueryWatchParameters): Types.AppStat;
    useAppStat(variables: Types.AppStatVariables, params?: SpaceQueryWatchParameters): Types.AppStat | null {;
        return this.useQuery('AppStat', variables, params);
    }
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GetSiteScreenshot | null;
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: SpaceQueryWatchParameters): Types.GetSiteScreenshot;
    useGetSiteScreenshot(variables: Types.GetSiteScreenshotVariables, params?: SpaceQueryWatchParameters): Types.GetSiteScreenshot | null {;
        return this.useQuery('GetSiteScreenshot', variables, params);
    }
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.GenerateSharLink | null;
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: SpaceQueryWatchParameters): Types.GenerateSharLink;
    useGenerateSharLink(variables: Types.GenerateSharLinkVariables, params?: SpaceQueryWatchParameters): Types.GenerateSharLink | null {;
        return this.useQuery('GenerateSharLink', variables, params);
    }
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.AppVersionsPage | null;
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: SpaceQueryWatchParameters): Types.AppVersionsPage;
    useAppVersionsPage(variables: Types.AppVersionsPageVariables, params?: SpaceQueryWatchParameters): Types.AppVersionsPage | null {;
        return this.useQuery('AppVersionsPage', variables, params);
    }
    useMyShop(params: SpaceQueryWatchParameters & { suspense: false }): Types.MyShop | null;
    useMyShop(params?: SpaceQueryWatchParameters): Types.MyShop;
    useMyShop(params?: SpaceQueryWatchParameters): Types.MyShop | null {;
        return this.useQuery('MyShop', undefined, params);
    }
    usePages(params: SpaceQueryWatchParameters & { suspense: false }): Types.Pages | null;
    usePages(params?: SpaceQueryWatchParameters): Types.Pages;
    usePages(params?: SpaceQueryWatchParameters): Types.Pages | null {;
        return this.useQuery('Pages', undefined, params);
    }
    usePage(variables: Types.PageVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Page | null;
    usePage(variables: Types.PageVariables, params?: SpaceQueryWatchParameters): Types.Page;
    usePage(variables: Types.PageVariables, params?: SpaceQueryWatchParameters): Types.Page | null {;
        return this.useQuery('Page', variables, params);
    }
    useBlurHash(variables: Types.BlurHashVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.BlurHash | null;
    useBlurHash(variables: Types.BlurHashVariables, params?: SpaceQueryWatchParameters): Types.BlurHash;
    useBlurHash(variables: Types.BlurHashVariables, params?: SpaceQueryWatchParameters): Types.BlurHash | null {;
        return this.useQuery('BlurHash', variables, params);
    }
    useListPricing(variables: Types.ListPricingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListPricing | null;
    useListPricing(variables: Types.ListPricingVariables, params?: SpaceQueryWatchParameters): Types.ListPricing;
    useListPricing(variables: Types.ListPricingVariables, params?: SpaceQueryWatchParameters): Types.ListPricing | null {;
        return this.useQuery('ListPricing', variables, params);
    }
    useDefaultPricing(params: SpaceQueryWatchParameters & { suspense: false }): Types.DefaultPricing | null;
    useDefaultPricing(params?: SpaceQueryWatchParameters): Types.DefaultPricing;
    useDefaultPricing(params?: SpaceQueryWatchParameters): Types.DefaultPricing | null {;
        return this.useQuery('DefaultPricing', undefined, params);
    }
    usePricing(variables: Types.PricingVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Pricing | null;
    usePricing(variables: Types.PricingVariables, params?: SpaceQueryWatchParameters): Types.Pricing;
    usePricing(variables: Types.PricingVariables, params?: SpaceQueryWatchParameters): Types.Pricing | null {;
        return this.useQuery('Pricing', variables, params);
    }
    useProduct(variables: Types.ProductVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.Product | null;
    useProduct(variables: Types.ProductVariables, params?: SpaceQueryWatchParameters): Types.Product;
    useProduct(variables: Types.ProductVariables, params?: SpaceQueryWatchParameters): Types.Product | null {;
        return this.useQuery('Product', variables, params);
    }
    useSearchListProducts(variables: Types.SearchListProductsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.SearchListProducts | null;
    useSearchListProducts(variables: Types.SearchListProductsVariables, params?: SpaceQueryWatchParameters): Types.SearchListProducts;
    useSearchListProducts(variables: Types.SearchListProductsVariables, params?: SpaceQueryWatchParameters): Types.SearchListProducts | null {;
        return this.useQuery('SearchListProducts', variables, params);
    }
    useListProducts(variables: Types.ListProductsVariables, params: SpaceQueryWatchParameters & { suspense: false }): Types.ListProducts | null;
    useListProducts(variables: Types.ListProductsVariables, params?: SpaceQueryWatchParameters): Types.ListProducts;
    useListProducts(variables: Types.ListProductsVariables, params?: SpaceQueryWatchParameters): Types.ListProducts | null {;
        return this.useQuery('ListProducts', variables, params);
    }
    useSettings(params: SpaceQueryWatchParameters & { suspense: false }): Types.Settings | null;
    useSettings(params?: SpaceQueryWatchParameters): Types.Settings;
    useSettings(params?: SpaceQueryWatchParameters): Types.Settings | null {;
        return this.useQuery('Settings', undefined, params);
    }
    useCollections(params: SpaceQueryWatchParameters & { suspense: false }): Types.Collections | null;
    useCollections(params?: SpaceQueryWatchParameters): Types.Collections;
    useCollections(params?: SpaceQueryWatchParameters): Types.Collections | null {;
        return this.useQuery('Collections', undefined, params);
    }
    mutateCreateAccount(variables: Types.CreateAccountVariables, params?: MutationParameters): Promise<Types.CreateAccount> {
        return this.mutate('CreateAccount', variables, params)
    }
    mutateAccountReset(variables: Types.AccountResetVariables, params?: MutationParameters): Promise<Types.AccountReset> {
        return this.mutate('AccountReset', variables, params)
    }
    mutateSetupAccount(variables: Types.SetupAccountVariables, params?: MutationParameters): Promise<Types.SetupAccount> {
        return this.mutate('SetupAccount', variables, params)
    }
    mutateAdminUpdatePricing(variables: Types.AdminUpdatePricingVariables, params?: MutationParameters): Promise<Types.AdminUpdatePricing> {
        return this.mutate('AdminUpdatePricing', variables, params)
    }
    mutateAdminGenerateAccountToken(variables: Types.AdminGenerateAccountTokenVariables, params?: MutationParameters): Promise<Types.AdminGenerateAccountToken> {
        return this.mutate('AdminGenerateAccountToken', variables, params)
    }
    mutateAdminPublishAppCard(variables: Types.AdminPublishAppCardVariables, params?: MutationParameters): Promise<Types.AdminPublishAppCard> {
        return this.mutate('AdminPublishAppCard', variables, params)
    }
    mutateAdminRevokeAppCard(variables: Types.AdminRevokeAppCardVariables, params?: MutationParameters): Promise<Types.AdminRevokeAppCard> {
        return this.mutate('AdminRevokeAppCard', variables, params)
    }
    mutateAdminMakeAccountTrial(variables: Types.AdminMakeAccountTrialVariables, params?: MutationParameters): Promise<Types.AdminMakeAccountTrial> {
        return this.mutate('AdminMakeAccountTrial', variables, params)
    }
    mutateAdminUpdateSurveyIndustry(variables: Types.AdminUpdateSurveyIndustryVariables, params?: MutationParameters): Promise<Types.AdminUpdateSurveyIndustry> {
        return this.mutate('AdminUpdateSurveyIndustry', variables, params)
    }
    mutateAdminDeleteSurveyIndustry(variables: Types.AdminDeleteSurveyIndustryVariables, params?: MutationParameters): Promise<Types.AdminDeleteSurveyIndustry> {
        return this.mutate('AdminDeleteSurveyIndustry', variables, params)
    }
    mutateAdminRemovePricing(variables: Types.AdminRemovePricingVariables, params?: MutationParameters): Promise<Types.AdminRemovePricing> {
        return this.mutate('AdminRemovePricing', variables, params)
    }
    mutateAdminUpdatePricingAccount(variables: Types.AdminUpdatePricingAccountVariables, params?: MutationParameters): Promise<Types.AdminUpdatePricingAccount> {
        return this.mutate('AdminUpdatePricingAccount', variables, params)
    }
    mutateAdminAccountSetPricing(variables: Types.AdminAccountSetPricingVariables, params?: MutationParameters): Promise<Types.AdminAccountSetPricing> {
        return this.mutate('AdminAccountSetPricing', variables, params)
    }
    mutateAdminForceSync(variables: Types.AdminForceSyncVariables, params?: MutationParameters): Promise<Types.AdminForceSync> {
        return this.mutate('AdminForceSync', variables, params)
    }
    mutateAdminAccountWebhookCreate(variables: Types.AdminAccountWebhookCreateVariables, params?: MutationParameters): Promise<Types.AdminAccountWebhookCreate> {
        return this.mutate('AdminAccountWebhookCreate', variables, params)
    }
    mutateAdminAccountWebhookDelete(variables: Types.AdminAccountWebhookDeleteVariables, params?: MutationParameters): Promise<Types.AdminAccountWebhookDelete> {
        return this.mutate('AdminAccountWebhookDelete', variables, params)
    }
    mutateAdminUpdatePartner(variables: Types.AdminUpdatePartnerVariables, params?: MutationParameters): Promise<Types.AdminUpdatePartner> {
        return this.mutate('AdminUpdatePartner', variables, params)
    }
    mutateAdminRemovePartner(variables: Types.AdminRemovePartnerVariables, params?: MutationParameters): Promise<Types.AdminRemovePartner> {
        return this.mutate('AdminRemovePartner', variables, params)
    }
    mutateAdminUpdateSmartBannerAccounts(params?: MutationParameters): Promise<Types.AdminUpdateSmartBannerAccounts> {
        return this.mutate('AdminUpdateSmartBannerAccounts', undefined, params)
    }
    mutateCreatePreviewSession(variables: Types.CreatePreviewSessionVariables, params?: MutationParameters): Promise<Types.CreatePreviewSession> {
        return this.mutate('CreatePreviewSession', variables, params)
    }
    mutateClosePreviewSession(variables: Types.ClosePreviewSessionVariables, params?: MutationParameters): Promise<Types.ClosePreviewSession> {
        return this.mutate('ClosePreviewSession', variables, params)
    }
    mutateUpdateApp(variables: Types.UpdateAppVariables, params?: MutationParameters): Promise<Types.UpdateApp> {
        return this.mutate('UpdateApp', variables, params)
    }
    mutateCreateApp(variables: Types.CreateAppVariables, params?: MutationParameters): Promise<Types.CreateApp> {
        return this.mutate('CreateApp', variables, params)
    }
    mutateRenameApp(variables: Types.RenameAppVariables, params?: MutationParameters): Promise<Types.RenameApp> {
        return this.mutate('RenameApp', variables, params)
    }
    mutateArchiveApp(variables: Types.ArchiveAppVariables, params?: MutationParameters): Promise<Types.ArchiveApp> {
        return this.mutate('ArchiveApp', variables, params)
    }
    mutateUpdatePreviewSessionUrl(variables: Types.UpdatePreviewSessionUrlVariables, params?: MutationParameters): Promise<Types.UpdatePreviewSessionUrl> {
        return this.mutate('UpdatePreviewSessionUrl', variables, params)
    }
    mutateUpdateAppIcon(variables: Types.UpdateAppIconVariables, params?: MutationParameters): Promise<Types.UpdateAppIcon> {
        return this.mutate('UpdateAppIcon', variables, params)
    }
    mutateUpdateAppSplash(variables: Types.UpdateAppSplashVariables, params?: MutationParameters): Promise<Types.UpdateAppSplash> {
        return this.mutate('UpdateAppSplash', variables, params)
    }
    mutateShareMall(variables: Types.ShareMallVariables, params?: MutationParameters): Promise<Types.ShareMall> {
        return this.mutate('ShareMall', variables, params)
    }
    mutateUpdateAppCard(variables: Types.UpdateAppCardVariables, params?: MutationParameters): Promise<Types.UpdateAppCard> {
        return this.mutate('UpdateAppCard', variables, params)
    }
    mutateSubmitAppCard(variables: Types.SubmitAppCardVariables, params?: MutationParameters): Promise<Types.SubmitAppCard> {
        return this.mutate('SubmitAppCard', variables, params)
    }
    mutateDraftAppCard(variables: Types.DraftAppCardVariables, params?: MutationParameters): Promise<Types.DraftAppCard> {
        return this.mutate('DraftAppCard', variables, params)
    }
    mutateSyncCatalog(params?: MutationParameters): Promise<Types.SyncCatalog> {
        return this.mutate('SyncCatalog', undefined, params)
    }
    mutateUpdateBannerParams(variables: Types.UpdateBannerParamsVariables, params?: MutationParameters): Promise<Types.UpdateBannerParams> {
        return this.mutate('UpdateBannerParams', variables, params)
    }
    mutateSetFreeTraffic(variables: Types.SetFreeTrafficVariables, params?: MutationParameters): Promise<Types.SetFreeTraffic> {
        return this.mutate('SetFreeTraffic', variables, params)
    }
    mutateCreateNewAppVersion(variables: Types.CreateNewAppVersionVariables, params?: MutationParameters): Promise<Types.CreateNewAppVersion> {
        return this.mutate('CreateNewAppVersion', variables, params)
    }
    mutateCreatePage(variables: Types.CreatePageVariables, params?: MutationParameters): Promise<Types.CreatePage> {
        return this.mutate('CreatePage', variables, params)
    }
    mutateUpdatePage(variables: Types.UpdatePageVariables, params?: MutationParameters): Promise<Types.UpdatePage> {
        return this.mutate('UpdatePage', variables, params)
    }
    mutatePublishPage(variables: Types.PublishPageVariables, params?: MutationParameters): Promise<Types.PublishPage> {
        return this.mutate('PublishPage', variables, params)
    }
    mutateSubscribeToPricing(variables: Types.SubscribeToPricingVariables, params?: MutationParameters): Promise<Types.SubscribeToPricing> {
        return this.mutate('SubscribeToPricing', variables, params)
    }
    mutateConfirmBilling(params?: MutationParameters): Promise<Types.ConfirmBilling> {
        return this.mutate('ConfirmBilling', undefined, params)
    }
    mutateSaveAppStore(variables: Types.SaveAppStoreVariables, params?: MutationParameters): Promise<Types.SaveAppStore> {
        return this.mutate('SaveAppStore', variables, params)
    }
    mutateSaveGooglePlay(variables: Types.SaveGooglePlayVariables, params?: MutationParameters): Promise<Types.SaveGooglePlay> {
        return this.mutate('SaveGooglePlay', variables, params)
    }
    subscribeWatchAppEmulatorConfig(variables: Types.WatchAppEmulatorConfigVariables, handler: GraphqlSubscriptionHandler<Types.WatchAppEmulatorConfig>, params?: SubscriptionParameters): GraphqlActiveSubscription<Types.WatchAppEmulatorConfig> {
        return this.subscribe(handler, 'WatchAppEmulatorConfig', variables, params);
    }
}

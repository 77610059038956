import React, {useCallback, useState} from "react";
import { Form, TextField, Card } from '@shopify/polaris';
import {View} from "react-native";
import {getEndpoint} from "../api/getEndpoint";


export const AdminRegisterComponent = React.memo(() => {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [passwordConfirm, setPasswordConfirm] = useState<string | undefined>(undefined);

    const registerRequest = useCallback(()=>{
        (async ()=>{
            const request = {email, password, passwordConfirm};
            if (password !== passwordConfirm) {
                throw new Error("Password invalid");
            }
            const answer: {token?: string | null} = await fetch(getEndpoint() + '/shopify/register/admin', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            }).then(res => res.json());
            console.log('Session token auth', answer);
            return answer;
        })();
    }, [email, password, passwordConfirm]);
    return (<View style={{
            flex: 1,
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <Card primaryFooterAction={{content: "Register", onAction: registerRequest, disabled: password !== passwordConfirm}}>
            <Card.Section title={"Login form"}>
                <Form onSubmit={()=>{registerRequest()}}>
                    <TextField type={"email"} value={email}
                               onChange={(value)=>setEmail(value)}
                               label={"Email"} 
                               autoComplete={'off'} />
                    <TextField type={"password"} value={password}
                               onChange={(value)=>setPassword(value)}
                               label={"Password"}
                               autoComplete={'off'} />
                    <TextField type={"password"} value={passwordConfirm}
                               onChange={(value)=>setPasswordConfirm(value)}
                               label={"Password confirm"}
                               error={passwordConfirm !== passwordConfirm ? "Password invalid" : false}
                               autoComplete={'off'} />
                </Form>
            </Card.Section>
        </Card>
        </View>);
});
import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import {
    Page,
    Layout,
    Pagination,
    Card,
    ResourceList,
    ResourceItem,
    TextStyle,
    Stack,
    Select,
    Avatar,
    Badge,
    TextField,
    Checkbox
} from '@shopify/polaris';
import { useClient } from "../api/useClient";
import {
    AccountStatus,
    AdminAccountsListVariables,
    AdminAccountsList_adminListAccount,
    AdminAccountsList_adminListAccount_edges,
    AdminAccountsList_adminListAccount_edges_node
} from "../api/spacex.types";
import moment from "moment";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { getAccountMonitoringBadge, getAccountPricingStatusBadge } from "../utils/helpers";
import { CleanSpaceX } from "../components/types";
import { USER_TOKEN_KEY } from "../auth/utils";
import { debounce } from "ts-debounce";
import { ShopClient } from "../api/spacex";

type AdminAccountsListResult = CleanSpaceX<AdminAccountsList_adminListAccount>;
type AdminAccount = CleanSpaceX<AdminAccountsList_adminListAccount_edges_node>;
type AccountFilters = {
    isTest?: boolean,
    status?: AccountStatus,
    query?: string,
    smartBanner?: boolean,
    pageSize: number
};

export const AdminAccountsComponent = React.memo(() => {
    const client = useClient();

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const searchStatus = query.get('status');
    const initialStatus = AccountStatus[searchStatus?.toUpperCase() as keyof typeof AccountStatus];
    const searchSmartBanner = query.get('smartBanner');
    const initialSmartBanner = Boolean(searchSmartBanner);

    console.log('location, query, searchStatus', location, query, searchStatus);

    const [results, setResults] = useState<AdminAccountsListResult>();
    const [smartBannerResults, setSmartBannersResults] = useState<AdminAccountsList_adminListAccount_edges[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const [accountFilters, setAccountFilters] = useState<AccountFilters>({
        pageSize: initialSmartBanner ? 30 : 10,
        isTest: undefined,
        status: initialStatus,
        smartBanner: initialSmartBanner
    });

    const history = useHistory<{ from?: Location }>();
    const match = useRouteMatch();

    const loadNextPage = useCallback(() => {
        const queryData: AdminAccountsListVariables = {
            first: accountFilters.pageSize,
            after: results?.pageInfo.endCursor,
            isTest: accountFilters.isTest,
            status: accountFilters.status,
            query: accountFilters.query
        };
        console.log('Load next page', queryData)
        setLoading(true);
        client.queryAdminAccountsList(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminListAccount);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [results, accountFilters]);

    const loadPrevPage = useCallback(() => {
        if (!results?.pageInfo.hasPreviousPage) {
            console.log('No previous page', results);
            throw new Error("No previous page");
        }
        const queryData: AdminAccountsListVariables = {
            last: accountFilters.pageSize,
            before: results.pageInfo.startCursor,
            isTest: accountFilters.isTest,
            status: accountFilters.status,
            query: accountFilters.query
        };
        console.log('Load next page', queryData);
        setLoading(true);
        client.queryAdminAccountsList(queryData).then((result) => {
            console.log('Nex page loaded', result);
            setResults(result.adminListAccount);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        });
    }, [results, accountFilters]);

    const handleLoginAsUser = useCallback((account: AdminAccount) => {
        console.log('Login as user', account);
        setLoading(true);
        client.mutateAdminGenerateAccountToken({ accountId: account.id })
            .then((result) => {
                setLoading(false);
                console.log('User token', result.adminGenerateAccountToken);
                const newWindow = window.open(
                    `https://app.cartmate.com?shop=${account.domain}&token=${result.adminGenerateAccountToken}`,
                    '_blank',
                    'noopener,noreferrer'
                );
                if (newWindow) newWindow.opener = null;
                // history.push({pathname: '/apps'});
            })
            .catch((e) => {
                setLoading(false);
            });
    }, []);

    const handleFilterChange = useCallback((field: keyof AccountFilters, newValue: any) => {
        setResults(undefined);
        setAccountFilters((prevState) => {
            const newState = { ...prevState, ...{ [field]: newValue } };
            console.log('New state', newState);
            return newState;
        });
    }, [setAccountFilters]);

    const reloadPage = useCallback(
        debounce((lp: () => void) => {
            console.log('reloadPage');
            return lp();
        }, 300)
        , []);

    useEffect(() => {
        reloadPage(loadNextPage);
    }, [accountFilters, reloadPage]);

    useEffect(() => {
        async function getSmartBannerAccs(client: ShopClient, res?: AdminAccountsListResult): Promise<AdminAccountsList_adminListAccount_edges[] | undefined> {
            if (!res) {
                return undefined;
            }
            setLoading(true);

            let filtered: AdminAccountsList_adminListAccount_edges[] = [];
            for (const edge of res.edges) {
                try {
                    const app = await client.queryAdminAccountApp({ accountId: edge.node.id });

                    if (app.adminAccountApp.settings?.banner?.scriptTagId) {
                        filtered.push(edge);
                    }
                } catch (error) {
                    console.log('App error', error);

                }
            }
            setSmartBannersResults(filtered);
            setLoading(false);
        }
        if (accountFilters.smartBanner) {
            getSmartBannerAccs(client, results);
        } else {
            setSmartBannersResults(undefined);
        }
    }, [results, accountFilters, client]);

    console.log('Result', results);

    return (<Page title={"Accounts"}>
        <Layout>
            <Layout.Section>
                <Card title={'Accounts list'}>
                    <Card.Section>
                        <ResourceList
                            items={
                                smartBannerResults && smartBannerResults.length > 0
                                    ? smartBannerResults
                                    : results?.edges || []
                            }
                            loading={loading}
                            filterControl={(
                                <Stack distribution={"leading"}>
                                    <Stack.Item>
                                        <TextField
                                            label={"Query"}
                                            labelHidden
                                            placeholder={"Query"}
                                            value={accountFilters.query}
                                            onChange={(value) => {
                                                return handleFilterChange('query', value);
                                            }}
                                            autoComplete={'off'}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Select
                                            label={"Status"}
                                            labelInline
                                            value={accountFilters.status}
                                            options={[
                                                { label: 'All', value: 'all' },
                                                { label: 'Active', value: AccountStatus.ACTIVE },
                                                { label: 'Inactive', value: AccountStatus.INACTIVE },
                                                { label: 'Deleted', value: AccountStatus.DELETED },
                                                { label: 'Created', value: AccountStatus.CREATED },
                                                { label: 'Blocked', value: AccountStatus.BLOCKED },
                                                { label: 'On review', value: AccountStatus.ON_REVIEW },
                                            ]}
                                            onChange={(value) => {
                                                return handleFilterChange('status', value === 'all' ? undefined : value);
                                            }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Select
                                            label={"Is test"}
                                            labelInline
                                            value={typeof accountFilters.isTest === 'undefined' ? 'all' : (accountFilters.isTest ? 'test' : 'real')}
                                            options={[
                                                { label: "All", value: 'all' },
                                                { label: "Real", value: 'real' },
                                                { label: "Test", value: 'test' }
                                            ]}
                                            onChange={(value) => {
                                                return handleFilterChange('isTest', value === 'all' ? undefined : (value === 'real' ? false : true))
                                            }}

                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Select
                                            label={"Page size"}
                                            labelInline
                                            value={String(accountFilters.pageSize)}
                                            options={[
                                                { label: '5', value: '5' },
                                                { label: '10', value: '10' },
                                                { label: '30', value: '30' }
                                            ]}
                                            onChange={(value) => {
                                                return handleFilterChange('pageSize', parseInt(value));
                                            }}
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Checkbox
                                            label="Smart banner turned on"
                                            checked={accountFilters.smartBanner}
                                            onChange={
                                                (value) => {
                                                    return handleFilterChange('smartBanner', value);
                                                }
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            )}
                            renderItem={(item) => {
                                const node = item.node;

                                let pricingBadge = getAccountPricingStatusBadge(node, false);
                                let monitoringBadge = getAccountMonitoringBadge(node);

                                return (<ResourceItem
                                    id={node.id}
                                    media={(<Avatar customer size={"medium"} name={node.name} />)}
                                    url={`${match.url}/${node.id}/edit`}
                                    shortcutActions={[
                                        { content: 'Log in', onAction: () => handleLoginAsUser(node) }
                                    ]}
                                >
                                    <h3>
                                        <TextStyle variation={"strong"}>{node.name}</TextStyle>
                                    </h3>
                                    <View>
                                        <Stack>
                                            <Stack.Item>
                                                {pricingBadge}
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Badge
                                                    status={"info"}>{"Register:" + moment(parseInt(node.createdAt)).format('ll')}</Badge>
                                            </Stack.Item>
                                            <Stack.Item>
                                                {monitoringBadge}
                                            </Stack.Item>
                                        </Stack>
                                    </View>
                                </ResourceItem>);
                            }}
                        />
                    </Card.Section>
                    {
                        (results?.pageInfo.hasNextPage || results?.pageInfo.hasPreviousPage) &&
                        <Card.Section>
                            <Stack distribution={"center"}>
                                <Pagination
                                    hasPrevious={results?.pageInfo.hasPreviousPage}
                                    onPrevious={() => {
                                        loadPrevPage()
                                    }}
                                    hasNext={results?.pageInfo.hasNextPage}
                                    onNext={() => {
                                        loadNextPage()
                                    }}
                                />
                            </Stack>
                        </Card.Section>
                    }
                </Card>
            </Layout.Section>
        </Layout>
    </Page>);
});
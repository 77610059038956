import { Card, Checkbox, Form, FormLayout, Layout, Page, Select, Stack, TextField, Toast } from "@shopify/polaris";
import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { AdminUpdatePricingVariables, PricingStatus, Pricing_pricing } from "../../api/spacex.types";
import { useClient } from "../../api/useClient";
import { Suspense } from "../../components/Suspense";
import { usePricingCapabilities } from "../../utils/usePricingCapabilities";
import { PricingCapabilities } from "./PricingCapabilities";
import { AccountCapability } from "./types";

export const EditPricing = React.memo(() => {
    const match = useRouteMatch<{ pricingId?: string }>();
    const { pricingId } = match.params;
    const history = useHistory();
    const client = useClient();
    const [capabilities, emptyCapabilities] = usePricingCapabilities();
    const [pricing, setPricing] = useState<Pricing_pricing | undefined>();
    const [selectedCaps, setSelectedCaps] = useState<AccountCapability>(emptyCapabilities);

    const [title, setTitle] = useState<string>(pricing?.title || '');
    const [description, setDescription] = useState<string>(pricing?.description || '');
    const [price, setPrice] = useState<string>(String(pricing?.price || 0));
    const [trialDays, setTrialDays] = useState<string>(String(pricing?.trialDays || 0));
    const [isDefault, setIsDefault] = useState<boolean>(pricing?.isDefault || false);
    const [hidden, setIsHidden] = useState<boolean>(pricing?.hidden || false);
    const [status, setStatus] = useState<PricingStatus>(pricing?.status || PricingStatus.ACTIVE);
    const [loading, setLoading] = useState<boolean>(false);
    const handleSubmit = useCallback(() => {
        if (!title || !description || !price || !trialDays || !status || !selectedCaps) {
            throw new Error("Invalid data");
        }
        const r = {
            title, description,
            price: parseFloat(price),
            trialDays: parseInt(trialDays),
            isDefault,
            capabilities: JSON.stringify(selectedCaps),
            hidden
        };
        console.log('Submit result', r)
        setLoading(true);
        let params: AdminUpdatePricingVariables = { input: r };
        if (pricingId) {
            params.id = pricingId;
        }
        client.mutateAdminUpdatePricing(params)
            .then((result) => {
                console.log('Pricing saved', result);
                if (!pricingId) {
                    history.push({ pathname: `/billing/pricing/${result.adminUpdatePricing.id}/edit` });
                    setPricing(result.adminUpdatePricing);
                }
                toggleSavedToast();
            })
            .catch(console.warn)
            .finally(() => {
                setLoading(false)
            });
    }, [title, description, price, trialDays, status, isDefault, pricingId, selectedCaps]);
    useEffect(() => {
        if (pricingId) {
            setLoading(true);
            client.queryPricing({ id: pricingId })
                .then((result) => {
                    let p = result.pricing;
                    setPricing(p);
                    setTitle(p.title);
                    setDescription(p.description);
                    setPrice(String(p.price));
                    setTrialDays(String(p.trialDays));
                    setIsDefault(p.isDefault);
                    setStatus(p.status);
                    setIsHidden(p.hidden || false);
                    setSelectedCaps(p.capabilities ? JSON.parse(p.capabilities) : emptyCapabilities);
                })
                .catch(console.warn).finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    const [savedToastActive, setSavedToastActive] = useState(false);

    const toggleSavedToast = useCallback(() => setSavedToastActive((active) => !active), []);
    const toastMarkup = savedToastActive ? (
        <Toast content="Saved!" onDismiss={toggleSavedToast} />
    ) : null;

    const handleUpdateAccounts = useCallback(() => {

        console.log('Start update');
        if (pricingId) {
            client.mutateAdminUpdatePricingAccount({ id: pricingId }).then((result) => { console.log('Accounts updated', result) })
        }
    }, [pricingId]);

    return (
        <Suspense>
            <Page
                title={pricing ? `Edit pricing: ${pricing.title}` : 'Add new pricing'}
                primaryAction={{ content: 'Save', onAction: handleSubmit }}
                breadcrumbs={[{ content: 'Pricing range', url: '/billing' }]}
                secondaryActions={[
                    { content: "Update accounts", onAction: handleUpdateAccounts }
                ]}
                key={`pricing-${pricingId || 'add'}`}
            >
                <Layout>
                    <Layout.Section secondary>
                        <Form onSubmit={() => { handleSubmit() }} preventDefault>
                            <Card sectioned>

                                <FormLayout>
                                    <Stack distribution={"fill"} alignment={"center"}>
                                        <TextField
                                            label={"Title"}
                                            value={title}
                                            onChange={(value) => setTitle(value)}
                                            autoComplete={'off'}
                                        />
                                        <TextField
                                            label={"Price"}
                                            prefix={"$"}
                                            value={price}
                                            onChange={(value) => setPrice(value)}
                                            autoComplete={'off'}
                                            type={"number"}
                                        />
                                        <TextField
                                            label={"Trial days"}
                                            value={trialDays}
                                            onChange={(value) => setTrialDays(value)}
                                            autoComplete={'off'}
                                            type={"number"}
                                        />
                                        <Select
                                            label={"Status"}
                                            options={[
                                                { label: 'Active', value: PricingStatus.ACTIVE },
                                                { label: 'Inactive', value: PricingStatus.INACTIVE }
                                            ]}
                                            value={status}
                                            onChange={(value: PricingStatus) => setStatus(value)}
                                        />
                                    </Stack>
                                    <TextField
                                        label={"Description"}
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                        autoComplete={'off'}
                                        multiline={4}
                                    />
                                    <Checkbox
                                        label={"Default pricing"}
                                        checked={isDefault}
                                        onChange={(value) => setIsDefault(value)}
                                    />
                                    <Checkbox
                                        label={"Hidden"}
                                        checked={hidden}
                                        onChange={(value) => setIsHidden(value)}
                                    />
                                </FormLayout>
                            </Card>
                            <PricingCapabilities
                                capabilities={capabilities}
                                selected={selectedCaps}
                                setSelected={setSelectedCaps}
                            />
                        </Form>
                        {toastMarkup}
                    </Layout.Section>
                </Layout>
            </Page>
        </Suspense>
    );
});